var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"material-form"},[_c('formizer',{ref:"formizer",attrs:{"model":"AttributeOption","undo":"/attributs_options","hide-footer":_vm.hideFooter,"hide-header":_vm.hideHeader,"id":_vm.id},on:{"modified":function($event){return _vm.$emit('modified', $event)},"draw-completed":_vm.drawCompleted,"submit":_vm.intSubmit},scopedSlots:_vm._u([{key:"header-toolbar",fn:function(ref){
var doc = ref.doc;
var method = ref.method;
return [_c('v-btn',{attrs:{"text":"","small":"","plain":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("chevron_left")]),_c('span',[_vm._v("Indietro")])],1),_c('h1',[_vm._v(_vm._s(method === 'post' ? 'Aggiungi opzione attributo' : doc.name))])]}},{key:"body",fn:function(ref){
var fields = ref.fields;
var rules = ref.rules;
var card = ref.card;
return [_c('v-card',_vm._b({},'v-card',card,false),[_c('div',{staticClass:"mb-8"},[_c('h2',[_vm._v("Foto")])]),_c('uploader',{staticClass:"uploader-card",model:{value:(fields.img.model),callback:function ($$v) {_vm.$set(fields.img, "model", $$v)},expression:"fields.img.model"}})],1),_c('v-card',_vm._b({},'v-card',card,false),[_c('div',{staticClass:"mb-8"},[_c('h2',[_vm._v("Informazioni base")])]),_c('div',[_c('bxs-select-resouce-field',{attrs:{"resource":"attributes","item-text":"name","required":"","outlined":"","disabled":"","label":"Attributo","form-title":"attributo","form-component":"form-attribute"},model:{value:(fields.attribute.model),callback:function ($$v) {_vm.$set(fields.attribute, "model", $$v)},expression:"fields.attribute.model"}}),_c('v-text-field',{attrs:{"label":"* Valore","required":"","dense":"","outlined":"","rules":[rules.required]},model:{value:(fields.value.model),callback:function ($$v) {_vm.$set(fields.value, "model", $$v)},expression:"fields.value.model"}}),_c('v-text-field',{attrs:{"label":"Icona","dense":"","outlined":""},model:{value:(fields.icon.model),callback:function ($$v) {_vm.$set(fields.icon, "model", $$v)},expression:"fields.icon.model"}}),_c('v-text-field',{attrs:{"label":"Colore 1","dense":"","outlined":""},model:{value:(fields.color_hex_1.model),callback:function ($$v) {_vm.$set(fields.color_hex_1, "model", $$v)},expression:"fields.color_hex_1.model"}}),_c('v-text-field',{attrs:{"label":"Colore 2","dense":"","outlined":""},model:{value:(fields.color_hex_1.model),callback:function ($$v) {_vm.$set(fields.color_hex_1, "model", $$v)},expression:"fields.color_hex_1.model"}})],1)]),_vm._t("default")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }