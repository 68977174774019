var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-autocomplete',{attrs:{"disabled":_vm.disabled,"item-text":_vm.itemText,"item-value":_vm.itemValue,"cache-items":false,"items":_vm.items,"return-object":false,"multiple":_vm.multiple,"label":_vm.required ? '* ' + _vm.label : _vm.label,"placeholder":_vm.placeholder,"menu-props":{
        closeOnClick: false,
        closeOnContentClick: false,
        disableKeys: true,
        openOnClick: false,
        maxHeight: 200,
        offsetY: true,
        offsetOverflow: true,
        transition: false,
        top: false,
        bottom: true
    },"hide-selected":false,"hide-no-data":false,"no-filter":true,"hide-details":_vm.hideDetails,"loading":_vm.loading,"allow-overflow":"","clearable":_vm.clearable,"filled":"","dense":"","rules":_vm.rules,"chips":_vm.multiple,"deletable-chips":_vm.multiple,"small-chips":_vm.smallChips},on:{"change":function($event){return _vm.$emit('change', $event)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
    var item = ref.item;
return [_c('span',{staticClass:"d-flex align-center",staticStyle:{"width":"100%"}},[_c('span',[_vm._v(_vm._s(_vm.dot.pick(_vm.itemText, item)))])])]}},(!_vm.hideAdd)?{key:"append-outer",fn:function(){return [_c('v-btn',{staticStyle:{"margin-top":"-8px"},attrs:{"depressed":""},on:{"click":function($event){return _vm.setForm()}}},[_c('v-icon',[_vm._v("add")])],1)]},proxy:true}:null],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),(!_vm.hideAdd)?_c('v-dialog',{attrs:{"persistent":"","right":"","scrollable":"","width":"768"},model:{value:(_vm.form.on),callback:function ($$v) {_vm.$set(_vm.form, "on", $$v)},expression:"form.on"}},[_c('v-card',[_c('v-card-title',[_c('v-toolbar',[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.dialogTitle))]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.form.on = null}}},[_c('v-icon',[_vm._v("close")])],1)],1)],1),_c('v-card-text',[_c('rapid-dep',{key:_vm.form.on,ref:"form",attrs:{"id":_vm.form.id,"type":_vm.type,"hide-header":"","hide-footer":""},on:{"submit":_vm.submit}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$refs.form.submit()}}},[_vm._v("Salva")])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }