var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"deps"},[_vm._m(0),_c('bxs-data-table',{ref:"table",attrs:{"model-name":"Dep","resource":"deps","id":"table-pages-view","to-create-item":_vm.$api.auth.is_super ? '/deps/new?type=page' : null,"to-update-item":"/deps","hide-default-delete-item":_vm.$api.auth.is_admin,"headers":_vm.headers,"static-filters":{
        type: '=|page'
    }},scopedSlots:_vm._u([{key:"item.is_available",fn:function(ref){
    var item = ref.item;
    var value = ref.value;
return [_c('v-switch',{staticClass:"d-inline ma-0 pa-0",attrs:{"input-value":value,"inset":"","dense":"","hide-details":"","single-line":""},on:{"change":function($event){return _vm.$api.deps.modify(item.id, { is_available: $event })}}})]}},{key:"item-actions",fn:function(ref){
    var item = ref.item;
return [_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v("edit")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Modifica")])],1)],1),_c('v-list-item',{attrs:{"href":item.permalink.it,"target":"_blank"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("language")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Preview")])],1)],1)]}}])})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-6"},[_c('h1',[_vm._v("Pagine")])])}]

export { render, staticRenderFns }