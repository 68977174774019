var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"metaquery-form"},[_c('formizer',{ref:"formizer",attrs:{"model":"MetaQuery","hide-footer":_vm.hideFooter,"hide-header":_vm.hideHeader,"id":_vm.id},on:{"modified":function($event){return _vm.$emit('modified', $event)},"draw-completed":_vm.drawCompleted,"submit":_vm.intSubmit},scopedSlots:_vm._u([{key:"header-toolbar",fn:function(ref){
var doc = ref.doc;
var method = ref.method;
return [_c('v-btn',{attrs:{"text":"","small":"","plain":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("chevron_left")]),_c('span',[_vm._v("Indietro")])],1),_c('h1',[_vm._v(_vm._s(method === 'post' ? 'Aggiungi metarule' : doc.name))])]}},{key:"body",fn:function(ref){
var fields = ref.fields;
var rules = ref.rules;
var card = ref.card;
return [_c('v-card',_vm._b({},'v-card',card,false),[_c('v-text-field',{attrs:{"label":"* Nome","required":"","outlined":"","dense":"","rules":[rules.required]},model:{value:(fields.name.model),callback:function ($$v) {_vm.$set(fields.name, "model", $$v)},expression:"fields.name.model"}}),_c('v-select',{attrs:{"items":_vm.$api.enums.metaquery.models,"label":"* Entità","required":"","dense":"","outlined":"","rules":[rules.required]},on:{"change":_vm.handleChangeResource},model:{value:(fields.model.model),callback:function ($$v) {_vm.$set(fields.model, "model", $$v)},expression:"fields.model.model"}})],1),_c('v-card',_vm._b({staticClass:"px-0"},'v-card',card,false),[_c('div',[_c('div',{staticStyle:{"padding":"0 2rem"}},[_c('div',{staticClass:"mb-4"},[_c('h2',[_vm._v("Refenze entità")])]),_c('div',[_c('v-checkbox',{attrs:{"hide-details":"","label":"Questa query ha referenze definite"},model:{value:(fields.refs.on.model),callback:function ($$v) {_vm.$set(fields.refs.on, "model", $$v)},expression:"fields.refs.on.model"}})],1)]),(fields.refs.on.model)?_c('v-divider',{staticClass:"my-4"}):_vm._e()],1),(fields.refs.on.model)?_c('div',{staticClass:"mt-4",staticStyle:{"padding":"0 2rem"}},[_c('bxs-select-resouce-field',{attrs:{"resource":_vm.items[fields.model.model].endpoint,"item-text":"name","outlined":"","multiple":"","item-populate":"parent","label":_vm.items[fields.model.model].text},model:{value:(fields.refs.value.model),callback:function ($$v) {_vm.$set(fields.refs.value, "model", $$v)},expression:"fields.refs.value.model"}})],1):_vm._e()]),_c('v-card',_vm._b({},'v-card',card,false),[_c('div',{staticClass:"mb-8"},[_c('h2',[_vm._v("Match stage")])]),_c('json-editor',{ref:"json_editor",model:{value:(fields.match.model),callback:function ($$v) {_vm.$set(fields.match, "model", $$v)},expression:"fields.match.model"}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }