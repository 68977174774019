<template>
    <div class="signin">
        <v-row align="center">
            <!--  -->
            <v-col
            cols="12"
            md="6"
            order="2"
            order-md="1"
            class="signin--col-l">
                <div class="signin--figure"></div>

                <v-img
                src="/img/hub.png"
                max-width="50%"
                class="mx-auto" />
            </v-col>

            <!--  -->
            <v-col
            cols="12"
            md="6"
            order="1"
            order-md="2"
            class="signin--col-r">
                <v-card
                flat
                max-width="360"
                class="mx-auto">
                    <div class="mb-12">
                        <v-img
                        src="/img/logo.jpg"
                        max-width="60"
                        class="mb-12" />
                    </div>

                    <v-card-text class="ma-0 pa-0">
                        <v-text-field
                        v-model="email"
                        :disabled="loading"
                        label="Email"
                        dense
                        outlined
                        required
                        hide-details
                        class="mb-3" />

                        <v-text-field
                        v-model="password"
                        :disabled="loading"
                        label="Password"
                        dense
                        outlined
                        hide-details
                        :append-icon="showPass ? 'visibility_off' : 'visibility'"
                        :type="showPass ? 'text' : 'password'"
                        @click:append="showPass = !showPass" />

                        <v-alert
                        v-if="error"
                        border="left"
                        text
                        dense
                        type="error"
                        class="ma-0 my-4">
                            <small>{{ error }}</small>
                        </v-alert>
                    </v-card-text>

                    <v-card-actions class="ma-0 pa-0 mt-4">
                        <v-btn
                        :disabled="!email || !password"
                        block
                        color="primary"
                        :loading="loading"
                        @click="signin">Accedi</v-btn>
                    </v-card-actions>

                    <div class="mt-8">
                        <v-btn
                        text
                        x-small
                        to="/signin/recovery">Password dimenticata?</v-btn>
                    </div>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: 'signin',
    data () {
        return {
            email: '',
            password: '',
            error: null,
            loading: false,
            showPass: false
        }
    },
    methods: {
        async signin () {
            this.loading = true
            this.error = null

            try {
                await this.$api.auth.signInWithEmailAndPassword(this.email, this.password)
                await this.$api.getEnums()
                this.$router.replace('/')
                this.$emit('signin')
            } catch (err) {
                this.error = err.message || err.name || err
                throw new Error(this.error)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang="scss">
.container-signin {
    margin: 0 !important;
    padding: 0 !important;
    max-width: 100% !important;
}
</style>

<style lang="scss" scoped>
.signin {
    padding: 0 !important;
    overflow: hidden;

    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (min-width: 992px) {
        height: 100vh;
    }

    h1 {
        font-size: 40px;
    }

    .row {
        width: 100%;
        position: relative !important;
        margin: 0 !important;
        padding: 0 !important;
        min-height: 100% !important;
        height: 100% !important;
    }

    &--col-l {
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 40px 0 !important;
        margin: 0 !important;
    }

    &--col-r {
        padding: 40px 0 !important;
        margin: 0 !important;
    }
}

.signin--figure {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    -webkit-clip-path: polygon(0 0,28% 0,100% 100%,0 100%);
    clip-path: polygon(0 0,28% 0,100% 100%,0 100%);
    // background-color: var(--v-primary-base);
    z-index: 0;
    // background-image: url('@/assets/pattern.svg');
    background-repeat: repeat;
}
</style>