<template>
    <div class="order-pdf-template-view document-pdf">
        <div
        v-if="loading"
        class="text-center">
            <loading />
        </div>

        <div v-else>
            <div
            v-if="error"
            class="text-center">
                <pre><code>{{ error }}</code></pre>
            </div>

            <div v-else>
                <!-- ------------------------------------------------------------------------------------------------------------------------------------------------------------------------
                    header
                ------------------------------------------------------------------------------------------------------------------------------------------------------------------------ -->
                <div class="document-pdf--header">
                    <!--  -->
                    <div
                    class="document-pdf--row"
                    style="margin-bottom: 2rem">
                        <!--  -->
                        <div class="document-pdf--row--col document-pdf-no-border">
                            <v-img
                            src="/img/logo.jpg"
                            max-width="1.25cm" />
                        </div>

                        <!--  -->
                        <div class="document-pdf--row--col document-pdf-no-border document-pdf-no-padding text-right">
                            <p class="mb-0">TforK</p>
                            <p class="mb-0">Via R. Mantero, 9/N</p>
                            <p class="mb-0">22070 - Grandate (CO)</p>
                            <p class="mb-0">info@tfork.it</p>
                        </div>
                    </div>

                    <!--  -->
                    <div class="document-pdf--row">
                        <!--  -->
                        <div class="document-pdf--row--col">
                            <div class="mb-2">
                                <div class="item-spacer">
                                    <h5>Stato</h5>

                                    <v-chip
                                    :color="getStatusColor(doc.status)"
                                    x-small>{{ doc.status | toHumanOrderStatus }}</v-chip>
                                </div>

                                <v-divider class="mt-1"></v-divider>
                            </div>

                            <div>
                                <div class="item-spacer">
                                    <p>Numero commessa</p>
                                    <p>{{ doc.number_id }}</p>
                                </div>

                                <div class="item-spacer">
                                    <p>Oggetto</p>
                                    <p>{{ doc.description || '' }}</p>
                                </div>

                                <div class="item-spacer">
                                    <p>Data apertura</p>
                                    <p>
                                        <span v-if="doc.open_date">{{ doc.open_date | toHumanDate('l') }}</span>
                                    </p>
                                </div>

                                <div class="item-spacer">
                                    <p>Data creazione</p>
                                    <p>{{ doc.created_at | toHumanDate('l') }}</p>
                                </div>
                            </div>
                        </div>

                        <div class="document-pdf--row--col">
                            <div class="mb-2">
                                <h5>Cliente</h5>
                                <v-divider class="mt-1"></v-divider>
                            </div>

                            <div>
                                <div class="item-spacer">
                                    <!-- <p>Denominazione</p> -->
                                    <p>{{ doc.customer.business_name || '' }}</p>
                                </div>

                                <div class="item-spacer">
                                    <p>P.IVA</p>
                                    <p>{{ doc.customer.vat_code || '' }}</p>
                                </div>

                                <div class="item-spacer">
                                    <p>Codice SDI</p>
                                    <p>{{ doc.customer.sdi || '' }}</p>
                                </div>

                                <div class="item-spacer">
                                    <!-- <p>PEC</p> -->
                                    <p>{{ doc.customer.pec || '' }}</p>
                                </div>

                                <div class="item-spacer">
                                    <p>Nr. di telefono</p>
                                    <p>{{ doc.customer.phone || '' }}</p>
                                </div>

                                <div class="item-spacer">
                                    <!-- <p>Email</p> -->
                                    <p>{{ doc.customer.email || '' }}</p>
                                </div>
                            </div>
                        </div>

                        <div class="document-pdf--row--col">
                            <div class="mb-2">
                                <h5>Sede legale</h5>
                                <v-divider class="mt-1"></v-divider>
                            </div>

                            <div>
                                <div class="item-spacer">
                                    <!-- <p>Indirizzo</p> -->
                                    <p>{{ doc.customer.billing_address.address || '' }}</p>
                                </div>

                                <div class="item-spacer">
                                    <!-- <p>CAP</p> -->
                                    <p>{{ doc.customer.billing_address.cap || '' }}</p>
                                </div>

                                <div class="item-spacer">
                                    <!-- <p>Città</p> -->
                                    <p>{{ doc.customer.billing_address.city || '' }}</p>
                                </div>

                                <div class="item-spacer">
                                    <!-- <p>Provincia</p> -->
                                    <p>{{ doc.customer.billing_address.province || '' }}</p>
                                </div>

                                <div class="item-spacer">
                                    <!-- <p>Note</p> -->
                                    <p>{{ doc.customer.billing_address.note || '' }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- ------------------------------------------------------------------------------------------------------------------------------------------------------------------------
                    body
                ------------------------------------------------------------------------------------------------------------------------------------------------------------------------ -->
                <div class="document-pdf--main">
                    <table>
                        <thead>
                            <tr>
                                <th>SKU</th>
                                <th>Nome</th>
                                <th>Taglia</th>
                                <th>Quantità</th>
                                <th>Prezzo netto unitario</th>
                                <th>Ricavo totale netto</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td colspan="3" class="text-uppercase">Totale pezzi</td>
                                <td>{{ numero_pezzi_totale }}</td>
                            </tr>

                            <tr
                            v-for="(row, i) in doc.rows"
                            :key="i">
                                <td>{{ row.product.sku }}</td>

                                <td>{{ row.product.name }}</td>

                                <td>{{ row.product.size }}</td>

                                <td>{{ row.quantity }}</td>

                                <td class="text-right">
                                    <span class="flex-between">
                                        <span>€</span>
                                        <span>{{ row.price_net | toHumanPricePDF }}</span>
                                    </span>
                                </td>

                                <td class="text-right">
                                    <span class="flex-between">
                                        <span>€</span>
                                        <span>{{ row.price_net_revenue | toHumanPricePDF }}</span>
                                    </span>
                                </td>
                            </tr>

                            <tr>
                                <td colspan="3" class="text-uppercase">Totale pezzi</td>
                                <td>{{ numero_pezzi_totale }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <!-- ------------------------------------------------------------------------------------------------------------------------------------------------------------------------
                    footer
                ------------------------------------------------------------------------------------------------------------------------------------------------------------------------ -->
                <div class="document-pdf--footer">
                    <div class="document-pdf--row">
                        <div class="document-pdf--row--col">
                            <div class="mb-2">
                                <h5>Spedizione</h5>
                                <v-divider class="mt-1"></v-divider>
                            </div>

                            <div>
                                <div class="item-spacer">
                                    <p>Costo</p>
                                    <p>{{ doc.delivery.gross | toHumanPriceN }}</p>
                                </div>

                                <div class="item-spacer">
                                    <p>Data consegna</p>
                                    <p>{{ doc.delivery.date | toHumanDate('l') }}</p>
                                </div>

                                <div class="item-spacer">
                                    <p>Note</p>
                                    <p>{{ doc.delivery.note }}</p>
                                </div>
                            </div>
                        </div>

                        <div class="document-pdf--row--col">
                            <div class="mb-2">
                                <h5>Pagamento</h5>
                                <v-divider class="mt-1"></v-divider>
                            </div>

                            <div>
                                <div class="item-spacer">
                                    <p>Sconto</p>
                                    <p>{{ doc.payment.discount }}%</p>
                                </div>

                                <div class="item-spacer">
                                    <p>Metodo</p>
                                    <p>{{ doc.payment.method | toHumanPaymentMethod }}</p>
                                </div>

                                <div class="item-spacer">
                                    <p>Note</p>
                                    <p>{{ doc.payment.note }}</p>
                                </div>
                            </div>
                        </div>

                        <div class="document-pdf--row--col">
                            <div class="mb-2">
                                <h5>Riepilogo</h5>
                                <v-divider class="mt-1"></v-divider>
                            </div>

                            <div>
                                <div class="item-spacer">
                                    <p>Sconto pagamento</p>
                                    <p>{{ ((doc.cash.total_net / 100) * doc.payment.discount) | toHumanPricePDF }}</p>
                                </div>

                                <div class="item-spacer">
                                    <p>Imponibile <span class="mute--text">(sconto incluso)</span></p>
                                    <p>{{ doc.cash.total_net | toHumanPricePDF }}</p>
                                </div>

                                <div class="item-spacer">
                                    <p>IVA 22%</p>
                                    <p>{{ ((doc.cash.total_gross - doc.delivery.gross) - doc.cash.total_net) | toHumanPricePDF }}</p>
                                </div>

                                <div class="item-spacer">
                                    <p>Costo spedizione</p>
                                    <p>{{ doc.delivery.gross | toHumanPricePDF }}</p>
                                </div>

                                <div class="item-spacer">
                                    <p><b>TOTALE</b> <span class="mute--text">(iva inclusa)</span></p>
                                    <p><b>{{ doc.cash.total_gross | toHumanPricePDF }}</b></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'order_pdf_template',
    data () {
        return {
            doc: null,
            error: null,
            loading: true
        }
    },
    computed: {
        numero_pezzi_totale () {
            if (!this.doc) return 0
            return this.doc.rows.reduce((a, b) => a + b.quantity, 0)
        }
    },
    mounted () {
        this.$nextTick(this.start)
    },
    methods: {
        async start () {
            this.loading = true
            this.error = null

            try {
                this.doc = await this.$api.get(`v1/orders/${this.$route.params.id}/pdf/data`, {
                    access_token: this.$route.query.access_token
                })
            } catch (err) {
                this.error = err
            } finally {
                this.loading = false
            }
        },
        getStatusColor (v) {
            if (v === 'draft') return 'info'
            else if (v === 'under_evaluation') return 'warning'
            else if (v === 'confirmed') return 'success'
            else if (v === 'canceled') return null
            return null
        }
    }
}
</script>

<style lang="scss" scoped>
.item-spacer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    > p {
        margin-bottom: 0.4em;
    }
}
</style>