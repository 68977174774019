var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"deps"},[_vm._m(0),_c('bxs-data-table',{directives:[{name:"show",rawName:"v-show",value:(_vm.view === 'table'),expression:"view === 'table'"}],ref:"table",attrs:{"model-name":"Dep","resource":"deps","id":"table-deps-view","to-create-item":"/deps/new?type=category","to-update-item":"/deps","headers":[
        { text: 'Nome', value: 'name.it' },
        { text: 'Livello', value: 'level' },
        { text: 'Path', value: 'path.it' },
        { text: 'Slug', value: 'slug.it' },
        { text: 'Ordine in menu', value: 'orders.menu' },
        { text: 'Nr. prodotti visibili', value: 'product_count' },
        { text: 'Disponibile', value: 'is_available' },
        { text: 'Visibile in menu', value: 'is_in_menu' }
    ],"sort-by":['name.it'],"sort-dir":[false],"static-filters":{
        type: '=|category'
    }},scopedSlots:_vm._u([{key:"item.orders.menu",fn:function(ref){
    var item = ref.item;
    var value = ref.value;
return [_c('qty-field',{staticStyle:{"max-width":"120px"},attrs:{"value":value,"hide-details":"","single-line":"","dense":"","solo":"","flat":"","outlined":false},on:{"change":function($event){return _vm.$api.deps.modify(item.id, { 'orders.menu': $event })}}})]}},{key:"item.is_available",fn:function(ref){
    var item = ref.item;
    var value = ref.value;
return [_c('v-switch',{staticClass:"d-inline ma-0 pa-0",attrs:{"input-value":value,"inset":"","dense":"","hide-details":"","single-line":""},on:{"change":function($event){return _vm.$api.deps.modify(item.id, { is_available: $event })}}})]}},{key:"item.is_in_menu",fn:function(ref){
    var item = ref.item;
    var value = ref.value;
return [_c('v-switch',{staticClass:"d-inline ma-0 pa-0",attrs:{"input-value":value,"inset":"","dense":"","hide-details":"","single-line":""},on:{"change":function($event){return _vm.$api.deps.modify(item.id, { is_in_menu: $event })}}})]}},{key:"item-actions",fn:function(ref){
    var item = ref.item;
return [_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v("edit")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Modifica")])],1)],1),_c('v-list-item',{attrs:{"href":item.permalink.it,"target":"_blank"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("language")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Preview")])],1)],1)]}}])}),(_vm.view === 'tree')?_c('bxs-data-lazy',{attrs:{"promises":function () { return _vm.$api.deps.list({ view: 'tree', sort: { name: 1 }, filters: { type: '=|category' } }); }},model:{value:(_vm.tree_items),callback:function ($$v) {_vm.tree_items=$$v},expression:"tree_items"}},[_c('div',[_c('v-toolbar',{attrs:{"flat":""}},[_c('bxs-btn',{attrs:{"tooltip":"Vista a tabella","text":""},on:{"click":function($event){_vm.view = 'table'}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("chevron_left")]),_c('span',[_vm._v("Indietro")])],1)],1),_c('bxs-tree-view',{model:{value:(_vm.tree_items),callback:function ($$v) {_vm.tree_items=$$v},expression:"tree_items"}})],1)]):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-6"},[_c('h1',[_vm._v("Categorie")])])}]

export { render, staticRenderFns }