<template>
    <div class="material-form">
        <formizer
        ref="formizer"
        model="Label"
        undo="/labels"
        :hide-footer="hideFooter"
        :hide-header="hideHeader"
        :id="id"
        @modified="$emit('modified', $event)"
        @submit="intSubmit">
            <template #header-toolbar="{ doc, method }">
                <v-btn
                text
                small
                plain
                @click="$router.go(-1)">
                    <v-icon left>chevron_left</v-icon>
                    <span>Indietro</span>
                </v-btn>

                <h1>{{ method === 'post' ? 'Aggiungi etichetta' : doc.name }}</h1>
            </template>

            <template #body="{ fields, rules, card }">
                <v-card v-bind="card">
                    <div class="mb-8">
                        <h2>Informazioni base</h2>
                    </div>

                    <div>
                        <v-text-field
                        v-model="fields.name.model"
                        label="* Nome"
                        required
                        dense
                        outlined
                        :rules="[rules.required]" />
                    </div>
                </v-card>

                <v-card v-bind="card">
                    <div class="mb-8">
                        <h2>Stile</h2>
                    </div>

                    <div>
                        <v-text-field
                        v-model="fields.styles.color.model"
                        label="Colore"
                        required
                        dense
                        outlined
                        :rules="[rules.required]" />
                    </div>

                    <v-row align="center">
                        <v-col cols="6">
                            <p class="mb-0">Outlined</p>
                        </v-col>
                        <v-col
                        cols="6"
                        class="text-right">
                            <v-switch
                            v-model="fields.styles.outlined.model"
                            inset
                            hide-details
                            single-line
                            class="d-inline-block ma-0 pa-0" />
                        </v-col>
                    </v-row>
                </v-card>

                <slot />
            </template>
        </formizer>
    </div>
</template>

<script>
export default {
    name: 'form_label',
    props: {
        id: {
            type: String,
            required: false,
            default: null
        },
        'attribute-id': {
            type: String,
            required: false,
            default: null
        },
        'hide-footer': {
            type: Boolean,
            required: false,
            default: false
        },
        'hide-header': {
            type: Boolean,
            required: false,
            default: false
        }
    },
    methods: {
        submit () {
            return this.$refs.formizer.submit()
        },
        async intSubmit (method, model, body, id, isUndo, undo) {
            this.$emit('submit', method, model, body, id, isUndo, undo)
        }
    }
}
</script>