import dot from 'dot-object'

const ts = {
    categories: 'Categoria/e',
    email: 'Email',
    business_name: 'Ragione sociale',
    vat_code: 'Partita IVA',
    'name.first': 'Nome',
    'name.last': 'Cognome',
    sdi: 'Codice SDI',
    phone: 'Numero di telefono',
    number_id: 'Numero ID',
    billing_address: {
        address: 'Indirizzo (sede legale)'
    },
    name: 'Nome',
    'name.it': 'Nome it',
    'name.en': 'Nome en',
    'scopes.reads': 'Permessi lettura',
    'scopes.writes': 'Permessi scrittura',
    created_at: 'Data creazione',
    false: 'No',
    id: 'Identificativo interno',
    img: 'Immagine',
    imgs: 'Immagini',
    role: 'Ruolo',
    roles: 'Ruoli',
    type: 'Tipologia',
    title: 'Titolo',
    true: 'Si',
    types: 'Tipo',
    updated_at: 'Data aggiornamento',
    products: 'Prodotti'
}

const t = (k) => {
    if (typeof k === 'object' || typeof k === 'number') return k

    const vk = String(k)
    return ts[vk] || dot.pick(vk, ts) || k
}

export default t