import Resource from './BaseResource'
import { parseJwt } from '../utils'

// super 1
// dev 2
// agency 3
// admin 4
// employee 5
// agent 5
// analist 6
// guest 7
// vip 8

class Auth extends Resource {
    constructor(config, tools, version, path) {
        super(config, tools, version, path)

        this.token = null
    }

    async getAccess() {
        const res = await this.tools.http.get(`${this.api}/access`)

        if (!res || (res && res.error)) {
            sessionStorage.removeItem(this.config.token_key)
            return res
        }

        this.token = res.token
        sessionStorage.setItem(this.config.token_key, this.token)

        return res
    }

    async refreshAccess() {
        const t = await this.tools.http.post(`${this.api}/access/refresh`)
        this.token = t
        this.tools.emitter.emit('vetify-token', t)
        return t
    }

    async revoke (id) {
        await this.tools.http.delete(`${this.api}/accesses/${id}`)
        // if (user_id === this.me.id) return location.reload()
    }

    async signup(body) {
        const token = await this.tools.http.post(`${this.api}/signup`, body)

        if (token) {
            console.log('auth signup token', token)
            this.token = token
            sessionStorage.setItem(this.config.token_key, this.token)
        }

        this.tools.emitter.emit('signup', token)
        return token
    }

    async signwait(body) {
        const res = await this.tools.http.post(`${this.api}/signwait`, body)
        return res
    }

    async signInWithEmailAndPassword(email, pass) {
        this.token = await this.tools.http.post(`${this.api}/signin`, {
            email: email,
            password: pass
        })

        sessionStorage.setItem(this.config.token_key, this.token)
        this.tools.emitter.emit('signin', this.token)

        return this.token
    }

    async signout(call = true) {
        if (call) await this.tools.http.delete(`${this.api}/signout`)

        this.token = null
        sessionStorage.removeItem(this.config.token_key, this.token)

        this.tools.emitter.emit('signout')

        return true
    }

    verifyEmail(email) {
        return this.tools.http.patch(`${this.api}/emails/${email}/verifications`)
    }

    async verifyToken(token) {
        const t = await this.tools.http.get(`${this.api}/verifications/${token || '_'}`)
        this.tools.emitter.emit('vetify-token', t)
        return t
    }

    // pwd
    passwordRecovery(email) {
        return this.tools.http.post(`${this.api}/password_recovery`, {
            email: email
        })
    }

    verifyPasswordRecovery(code) {
        return this.tools.http.get(`${this.api}/password_recovery/verifications`, {
            code: code
        })
    }

    passwordReset(code, pwd) {
        return this.tools.http.patch(`${this.api}/password_recovery`, {
            password: pwd,
            code: code
        })
    }

    get me() {
        if (!this.token) return null
        return parseJwt(this.token)
    }

    get is_super() {
        if (!this.me) return null
        return this.me.role === 'super' || this.me.level <= 1
    }

    get is_dev () {
        if (!this.me) return null
        return this.me.role === 'super' || this.me.level <= 2
    }

    get is_agency () {
        if (!this.me) return null
        return this.me.role === 'agency' || this.me.level <= 3
    }

    get is_admin() {
        if (!this.me) return null
        return this.me.role === 'admin' || this.me.level <= 4
    }

    get is_salesagent () {
        if (!this.me) return null
        return this.me.role === 'agent' || this.me.level <= 3
    }

    get access_token() {
        return this.token || null
    }

    setAccessToken(string) {
        this.token = string
        sessionStorage.setItem(this.config.token_key, string)
    }
}

export default Auth