var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"link-field"},[_c('v-dialog',{attrs:{"max-width":"400px","permanent":"","scrollable":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.$scopedSlots.activator)?_vm._t("activator",null,{"on":on,"attrs":attrs}):_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.label,"required":_vm.required,"outlined":_vm.outlined,"dense":_vm.dense,"readonly":"","placeholder":"Scegli il link","rules":_vm.rules},model:{value:(_vm.local_value),callback:function ($$v) {_vm.local_value=$$v},expression:"local_value"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.on),callback:function ($$v) {_vm.on=$$v},expression:"on"}},[_c('v-card',{staticClass:"pa-0"},[_c('v-card-title',{staticClass:"d-flex my-0 py-2"},[_c('h4',[_vm._v("Seleziona il link da collegare")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","color":"error"},on:{"click":function($event){_vm.on = false}}},[_c('v-icon',[_vm._v("close")])],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"pa-4 ma-0",staticStyle:{"max-height":"50vh"}},[_c('div',[_c('search-field',{attrs:{"dense":"","outlined":""},model:{value:(_vm.keywords),callback:function ($$v) {_vm.keywords=$$v},expression:"keywords"}}),_c('v-chip-group',{attrs:{"mandatory":"","active-class":"primary"},model:{value:(_vm.view),callback:function ($$v) {_vm.view=$$v},expression:"view"}},[_c('v-chip',{attrs:{"value":"products"}},[_vm._v("Prodotti")]),_c('v-chip',{attrs:{"value":"categories"}},[_vm._v("Categorie")]),_c('v-chip',{attrs:{"value":"pages"}},[_vm._v("Pagine")])],1)],1),_c('div',[(_vm.loading)?_c('div',[_c('loading')],1):_c('div',{staticClass:"py-2"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.view === 'products'),expression:"view === 'products'"}],staticClass:"mb-4"},[(!_vm.items.products.length)?_c('div',{staticClass:"text-center"},[_c('p',[_vm._v("Nessun elemento disponibile")])]):_c('v-virtual-scroll',{attrs:{"items":_vm.items.products,"height":"300","item-height":"60"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',{key:item.id,attrs:{"value":_vm.local_value === _vm.dot.pick(_vm.itemValue, item),"active-class":"success","dense":""},on:{"click":function($event){return _vm.select(item)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.name.it))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.permalink.it))])],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":"","color":_vm.local_value === _vm.dot.pick(_vm.itemValue, item) ? 'success' : null}},[_c('v-icon',[_vm._v(_vm._s(_vm.local_value === _vm.dot.pick(_vm.itemValue, item) ? 'task_alt' : 'radio_button_unchecked'))])],1)],1)],1),_c('v-divider')]}}])})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.view === 'categories'),expression:"view === 'categories'"}],staticClass:"mb-3"},[(!_vm.items.categories.length)?_c('div',{staticClass:"text-center"},[_c('p',[_vm._v("Nessun elemento disponibile")])]):_c('v-virtual-scroll',{attrs:{"items":_vm.items.categories,"height":"300","item-height":"60"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',{key:item.id,attrs:{"value":_vm.local_value === _vm.dot.pick(_vm.itemValue, item),"active-class":"success","dense":""},on:{"click":function($event){return _vm.select(item)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.name.it))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.path.it))])],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":"","color":_vm.local_value === _vm.dot.pick(_vm.itemValue, item) ? 'success' : null}},[_c('v-icon',[_vm._v(_vm._s(_vm.local_value === _vm.dot.pick(_vm.itemValue, item) ? 'task_alt' : 'radio_button_unchecked'))])],1)],1)],1),_c('v-divider')]}}])})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.view === 'pages'),expression:"view === 'pages'"}],staticClass:"mb-3"},[(!_vm.items.pages.length)?_c('div',{staticClass:"text-center"},[_c('p',[_vm._v("Nessun elemento disponibile")])]):_c('v-virtual-scroll',{attrs:{"items":_vm.items.pages,"height":"300","item-height":"60"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',{key:item.id,attrs:{"value":_vm.local_value === _vm.dot.pick(_vm.itemValue, item),"active-class":"success","dense":""},on:{"click":function($event){return _vm.select(item)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.name))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.permalink))])],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":"","color":_vm.local_value === _vm.dot.pick(_vm.itemValue, item) ? 'success' : null}},[_c('v-icon',[_vm._v(_vm._s(_vm.local_value === _vm.dot.pick(_vm.itemValue, item) ? 'task_alt' : 'radio_button_unchecked'))])],1)],1)],1),_c('v-divider')]}}])})],1)])])]),_c('v-divider',{staticClass:"ma-0 pa-0"}),_c('v-card-actions',{staticClass:"ma-0 pa-0 pa-4"},[_c('v-spacer'),_c('bxs-btn',{attrs:{"text":""},on:{"click":function($event){_vm.on = false}}},[_vm._v("Annulla e chiudi")]),_vm._t("actions")],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }