<template>
    <div class="orders">
        <div class="mb-8">
            <h1>Clienti</h1>
        </div>

        <!-- :schema="{
            'agent.email': {
                instance: 'array',
                query_key: 'agent.email',
                items: agents_emails
            }
        }" -->
        <bxs-data-table
        ref="table"
        model-name="Customer"
        resource="customers"
        id="table-customers-view"
        :headers="[
            { value: 'external_id' },
            { value: 'business_name' },
            { value: 'vat_code' },
            { value: 'sdi' },
            { value: 'billing_address.address' },
            { value: 'email' },
            { value: 'phone' },
            { text: 'Email agente', value: 'agent.email' }
        ]"
        :static-query="{
            spread: ['agent', 'updated_user']
        }"
        :sort-by="['business_name']"
        :sort-dir="[false]"
        :hide-default-delete-item="$api.auth.me.level > 2">
            <template #append-toolbar="{ selected_ids }">
                <bxs-btn
                icon
                tooltip="Aggiungi nuovo elemento"
                @click="setForm()">
                    <v-icon>add</v-icon>
                </bxs-btn>

                <v-btn
                small
                color="primary"
                class="ml-2"
                @click="downloadExcellOS1(selected_ids)">Scarica excel (OS1)</v-btn>
            </template>

            <template #item-actions="{ id }">
                <v-list-item @click="setForm(id)">
                    <v-list-item-icon>
                        <v-icon>edit</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Modifica</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </bxs-data-table>

        <!-- ------------------------------------------------------------------------------------------------------------------------------------------------------------------------
            form
        ------------------------------------------------------------------------------------------------------------------------------------------------------------------------ -->
        <v-navigation-drawer
        v-model="form.on"
        app
        right
        clipped
        persistent
        width="440">
            <v-card
            height="calc(100vh - 60px)"
            class="d-flex flex-column pa-0">
                <div class="d-flex align-center pa-4">
                    <h2 class="mb-0">Cliente</h2>

                    <v-spacer></v-spacer>

                    <v-btn
                    fab
                    depressed
                    x-small
                    color="error"
                    @click="form.on = false">
                        <v-icon>close</v-icon>
                    </v-btn>
                </div>
                <v-divider></v-divider>

                <div
                class="px-8"
                style="flex-grow: 1; height: 100%; overflow: auto;">
                    <customer-form
                    ref="form"
                    :key="form.on + form.id"
                    hide-header
                    hide-footer
                    fluid
                    :id="form.id"
                    @submit="submit" />
                </div>

                <v-divider></v-divider>
                <v-card-actions class="pa-4">
                    <v-spacer></v-spacer>

                    <v-btn
                    text
                    @click="setForm()">Annulla e chiudi</v-btn>

                    <v-btn
                    color="primary"
                    @click="$refs.form.submit()">Salva</v-btn>
                </v-card-actions>
            </v-card>
        </v-navigation-drawer>
    </div>
</template>

<script>
import { rapidForm } from '@/mixins'

export default {
    name: 'customers',
    mixins: [rapidForm('customers')],
    data () {
        return {
            agents_emails: []
        }
    },
    async created () {
        this.agents_emails = await this.$api.distinct('User', 'email', { role: 'agent' })
    },
    methods: {
        async downloadExcellOS1 (ids) {
            this.$store.commit('loading', true)
            let query = `?access_token=${this.$api.auth.access_token}`

            if (ids && ids.length > 0) {
                query += `&ids=${ids.join(',')}`
            }

            try {
                window.open(
                    `${this.$api.enums.origins.api}/v1/customers/os1/downloads/excell${query}`,
                    '_blank'
                )

                this.$refs.table.getItems()
            } catch (err) {
                throw new Error(err.message || err.name)
            } finally {
                this.$store.commit('loading', false)
            }
        }
    }
}
</script>