var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"material-form"},[_c('formizer',{ref:"formizer",attrs:{"model":"Label","undo":"/labels","hide-footer":_vm.hideFooter,"hide-header":_vm.hideHeader,"id":_vm.id},on:{"modified":function($event){return _vm.$emit('modified', $event)},"submit":_vm.intSubmit},scopedSlots:_vm._u([{key:"header-toolbar",fn:function(ref){
var doc = ref.doc;
var method = ref.method;
return [_c('v-btn',{attrs:{"text":"","small":"","plain":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("chevron_left")]),_c('span',[_vm._v("Indietro")])],1),_c('h1',[_vm._v(_vm._s(method === 'post' ? 'Aggiungi etichetta' : doc.name))])]}},{key:"body",fn:function(ref){
var fields = ref.fields;
var rules = ref.rules;
var card = ref.card;
return [_c('v-card',_vm._b({},'v-card',card,false),[_c('div',{staticClass:"mb-8"},[_c('h2',[_vm._v("Informazioni base")])]),_c('div',[_c('v-text-field',{attrs:{"label":"* Nome","required":"","dense":"","outlined":"","rules":[rules.required]},model:{value:(fields.name.model),callback:function ($$v) {_vm.$set(fields.name, "model", $$v)},expression:"fields.name.model"}})],1)]),_c('v-card',_vm._b({},'v-card',card,false),[_c('div',{staticClass:"mb-8"},[_c('h2',[_vm._v("Stile")])]),_c('div',[_c('v-text-field',{attrs:{"label":"Colore","required":"","dense":"","outlined":"","rules":[rules.required]},model:{value:(fields.styles.color.model),callback:function ($$v) {_vm.$set(fields.styles.color, "model", $$v)},expression:"fields.styles.color.model"}})],1),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('p',{staticClass:"mb-0"},[_vm._v("Outlined")])]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"6"}},[_c('v-switch',{staticClass:"d-inline-block ma-0 pa-0",attrs:{"inset":"","hide-details":"","single-line":""},model:{value:(fields.styles.outlined.model),callback:function ($$v) {_vm.$set(fields.styles.outlined, "model", $$v)},expression:"fields.styles.outlined.model"}})],1)],1)],1),_vm._t("default")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }