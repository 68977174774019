<template>
    <div class="order-view boxed boxed-l">
        <bxs-data-lazy
        :key="key"
        v-model="doc"
        :promises="() => $api.orders.doc($route.params.id)">
            <template #default="{ data }">
                <v-toolbar
                flat
                class="mb-4">
                    <v-toolbar-title><b>Commessa Nr. {{ data.number_id }}</b></v-toolbar-title>

                    <v-spacer></v-spacer>

                    <v-btn
                    @click="print"
                    class="mr-2">
                        <v-icon left>print</v-icon>
                        <span>Stampa PDF</span>
                    </v-btn>

                    <bxs-dot-menu>
                        <v-list-item
                        v-if="data.status !== 'confirmed'"
                        @click="changeStatus(item, 'confirmed')">
                            <v-list-item-icon>
                                <v-icon>check</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Conferma</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item
                        v-if="data.status !== 'draft'"
                        @click="changeStatus(item, 'draft')">
                            <v-list-item-icon>
                                <v-icon>reply</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Rimanda</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item @click="refreshCustomerAuthcode(item)">
                            <v-list-item-icon>
                                <v-icon>refresh</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Rigenera authcode commessa</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item @click="sendAuthCodeToCustomer(item)">
                            <v-list-item-icon>
                                <v-icon>send</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Notifica authcode al cliente</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item
                        v-if="data.status !== 'canceled'"
                        @click="changeStatus(item, 'canceled')">
                            <v-list-item-icon>
                                <v-icon>cancel</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Annulla e cestina</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </bxs-dot-menu>
                </v-toolbar>

                <!-- ------------------------------------------------------------------------------------------------
                ------------------------------------------------------------------------------------------------ -->
                <v-row>
                    <v-col cols="12" md="4">
                        <v-card>
                            <div class="mb-2">
                                <div class="item-spacer">
                                    <h3>Stato</h3>
                                    <v-chip
                                    :color="getStatusColor(data.status)"
                                    small>{{ data.status | toHumanOrderStatus }}</v-chip>
                                </div>

                                <v-divider class="mt-1"></v-divider>
                            </div>

                            <div>
                                <div class="item-spacer">
                                    <p>Numero</p>
                                    <p>{{ data.number_id }}</p>
                                </div>

                                <div class="item-spacer">
                                    <p>Oggetto</p>
                                    <p>{{ data.description || '--' }}</p>
                                </div>

                                <div class="item-spacer">
                                    <p>Data apertura</p>
                                    <p>{{ data.open_date | toHumanDate('l') }}</p>
                                </div>

                                <div class="item-spacer">
                                    <p>Data creazione</p>
                                    <p>{{ data.created_at | toHumanDate('l') }}</p>
                                </div>

                                <div class="item-spacer">
                                    <p>Ultimo aggiornamento</p>
                                    <p>{{ data.updated_at | toHumanDate('l') }}</p>
                                </div>
                            </div>
                        </v-card>
                    </v-col>

                    <v-col cols="12" md="4">
                        <v-card>
                            <div class="mb-2">
                                <h3>Cliente</h3>
                                <v-divider class="mt-1"></v-divider>
                            </div>

                            <div>
                                <div class="item-spacer">
                                    <p>Denominazione</p>
                                    <p>{{ data.customer.business_name || '--' }}</p>
                                </div>

                                <div class="item-spacer">
                                    <p>P.IVA</p>
                                    <p>{{ data.customer.vat_code || '--' }}</p>
                                </div>

                                <div class="item-spacer">
                                    <p>Codice SDI</p>
                                    <p>{{ data.customer.sdi || '--' }}</p>
                                </div>

                                <div class="item-spacer">
                                    <p>PEC</p>
                                    <p>{{ data.customer.pec || '--' }}</p>
                                </div>

                                <div class="item-spacer">
                                    <p>Numero di telefono</p>
                                    <p>{{ data.customer.phone || '--' }}</p>
                                </div>

                                <div class="item-spacer">
                                    <p>Email</p>
                                    <p>{{ data.customer.email || '--' }}</p>
                                </div>
                            </div>
                        </v-card>
                    </v-col>

                    <v-col cols="12" md="4">
                        <v-card>
                            <div class="mb-2">
                                <h3>Sede legale</h3>
                                <v-divider class="mt-1"></v-divider>
                            </div>

                            <div>
                                <div class="item-spacer">
                                    <p>Indirizzo</p>
                                    <p>{{ data.customer.billing_address.address || '--' }}</p>
                                </div>

                                <div class="item-spacer">
                                    <p>CAP</p>
                                    <p>{{ data.customer.billing_address.cap || '--' }}</p>
                                </div>

                                <div class="item-spacer">
                                    <p>Città</p>
                                    <p>{{ data.customer.billing_address.city || '--' }}</p>
                                </div>

                                <div class="item-spacer">
                                    <p>Provincia</p>
                                    <p>{{ data.customer.billing_address.province || '--' }}</p>
                                </div>

                                <div class="item-spacer">
                                    <p>Note</p>
                                    <p>{{ data.customer.billing_address.note || '--' }}</p>
                                </div>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>

                <!-- ------------------------------------------------------------------------------------------------
                ------------------------------------------------------------------------------------------------ -->
                <div class="my-4">
                    <v-card>
                        <div class="mb-2">
                            <div class="d-flex">
                                <h3>Articoli</h3>
                                <v-spacer></v-spacer>
                                <h4>Totale pezzi <b>{{ numero_pezzi_totale }}</b></h4>
                            </div>
                            <v-divider class="mt-1"></v-divider>
                        </div>

                        <v-data-table
                        :headers="[
                            { text: 'SKU', value: 'product.sku' },
                            { text: 'Nome', value: 'product.name' },
                            { text: 'Taglia', value: 'product.size' },
                            { text: 'Quantità', value: 'quantity' },
                            { text: 'Prezzo netto unitario', value: 'price_net' },
                            { text: 'Prezzo netto', value: 'price_net_revenue' },
                        ]"
                        :footer-props="{
                            'items-per-page-options': [-1]
                        }"
                        :options="{
                            itemsPerPage: -1
                        }"
                        :items="data.rows"
                        hide-default-footer
                        height="25vh">
                        <template #item.price_net="{ value }">{{  value | toHumanPriceN }}</template>
                            <template #item.price_net_revenue="{ value }">{{  value | toHumanPriceN }}</template>
                        </v-data-table>
                    </v-card>
                </div>

                 <!-- ------------------------------------------------------------------------------------------------
                ------------------------------------------------------------------------------------------------ -->
                <v-row>
                    <v-col cols="12" md="4">
                        <v-card>
                            <div class="mb-2">
                                <div class="item-spacer">
                                    <h3>Spedizione</h3>

                                    <a
                                    role="button"
                                    class="info--text"
                                    @click="shipping_price.on = !shipping_price.on">
                                        <span>{{ !shipping_price.on ? 'Modifica prezzo' : 'Annulla' }}</span>
                                        <v-icon right color="info" style="font-size: 16px;">edit</v-icon>
                                    </a>
                                </div>

                                <v-divider class="mt-1"></v-divider>
                            </div>

                            <div>
                                <div>
                                    <div class="item-spacer">
                                        <p>Costo</p>
                                        <p>{{ data.delivery.gross | toHumanPriceN }}</p>
                                    </div>

                                    <div
                                    v-if="shipping_price.on"
                                    class="d-flex nowrap justify-space-between align-center">
                                        <currency-field
                                        v-model="shipping_price.value"
                                        label="Costo spdizione"
                                        suffix="€"
                                        hide-details
                                        single-line
                                        style="max-width: 75%;" />

                                        <v-btn
                                        small
                                        color="success"
                                        @click="savePrice">Salva</v-btn>
                                    </div>

                                    <v-divider v-if="shipping_price.on" class="my-4" />
                                </div>

                                <div class="item-spacer">
                                    <p>Data consegna</p>
                                    <p>{{ data.delivery.date | toHumanDate('l') }}</p>
                                </div>

                                <div class="item-spacer">
                                    <p>Note</p>
                                    <p>{{ data.delivery.note }}</p>
                                </div>
                            </div>
                        </v-card>
                    </v-col>

                    <v-col cols="12" md="4">
                        <v-card>
                            <div class="mb-2">
                                <h3>Pagamento</h3>
                                <v-divider class="mt-1"></v-divider>
                            </div>

                            <div>
                                <div class="item-spacer">
                                    <p>Metodo</p>
                                    <p>{{ data.payment.method | toHumanPaymentMethod }}</p>
                                </div>

                                <div class="item-spacer">
                                    <p>Sconto</p>
                                    <p>{{ data.payment.discount }}%</p>
                                </div>

                                <div class="item-spacer">
                                    <p>Note</p>
                                    <p>{{ data.payment.note }}</p>
                                </div>
                            </div>
                        </v-card>
                    </v-col>

                    <v-col cols="12" md="4">
                        <v-card>
                            <div class="mb-2">
                                <h3>Riepilogo</h3>
                                <v-divider class="mt-1"></v-divider>
                            </div>

                            <div>
                                <div class="item-spacer">
                                    <p>Sconto pagamento</p>
                                    <p>{{ ((data.cash.total_net / 100) * data.payment.discount) | toHumanPriceN }}</p>
                                </div>

                                <div class="item-spacer">
                                    <p>Imponibile <span class="mute--text">(sconto incluso)</span></p>
                                    <p>{{ data.cash.total_net | toHumanPriceN }}</p>
                                </div>

                                <div class="item-spacer">
                                    <p>IVA {{ data.cash.vat }}%</p>
                                    <p>{{ ((data.cash.total_gross - data.delivery.gross) - data.cash.total_net) | toHumanPriceN }}</p>
                                </div>

                                <div class="item-spacer">
                                    <p>Costo spedizione</p>
                                    <p>{{ data.delivery.gross | toHumanPriceN }}</p>
                                </div>

                                <div class="item-spacer">
                                    <p><b>TOTALE <span class="mute--text">(iva inclusa)</span></b></p>
                                    <p><b>{{ data.cash.total_gross | toHumanPriceN }}</b></p>
                                </div>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
            </template>
        </bxs-data-lazy>
    </div>
</template>

<script>
export default {
    name: 'order',
    data () {
        return {
            key: 1,
            doc: null,
            shipping_price: {
                on: false,
                value: null
            }
        }
    },
    computed: {
        numero_pezzi_totale () {
            if (!this.doc) return 0
            return this.doc.rows.reduce((a, b) => a + b.quantity, 0)
        }
    },
    methods: {
        async savePrice () {
            this.$store.commit('loading', true)

            try {
                await this.$api.orders.modify(this.doc.id, this.getCalc(this.shipping_price.value))

                this.shipping_price.on = false
                this.shipping_price.value = null
                this.key += 1
            } catch (err) {
                throw new Error(err.message || err.name || err)
            } finally {
                this.$store.commit('loading', false)
            }
        },
        getCalc (new_shipping_price) {
            let total_net = 0
            // eslint-disable-next-line
            let total_gross = 0
            const shipping_price = new_shipping_price
            const vat = this.doc.cash.vat || 22

            // 1
            this.doc.rows.forEach((item) => {
                total_net += item.price_net_revenue
            })

            if (this.doc.payment.discount > 0) {
                total_net -= total_net * (this.doc.payment.discount / 100)
            }

            // 3
            total_gross = total_net + (total_net * (vat / 100))

            // 4
            total_gross += shipping_price

            return {
                'delivery.gross': shipping_price,
                'cash.total_net': total_net,
                'cash.total_gross': total_gross,
                'cash.total_vat': total_gross / (vat / 100)
            }
        },
        async print () {
            this.$store.commit('loading', true)

            try {
                if (process.env.NODE_ENV === 'development') {
                    window.open(`http://localhost:5000/v1/orders/${this.doc.id}/pdf?access_token=${this.$api.auth.token}`, '_blank')
                } else {
                    window.open(`https://admin.tfork.it/v1/orders/${this.doc.id}/pdf?access_token=${this.$api.auth.token}`, '_blank')
                }
                // await this.$api.get('v1/orders/' + this.doc.id + '/pdf')
            } catch (err) {
            } finally {
                this.$store.commit('loading', false)
            }
        },
        getStatusColor (v) {
            if (v === 'draft') return 'info'
            else if (v === 'under_evaluation') return 'warning'
            else if (v === 'confirmed') return 'success'
            else if (v === 'canceled') return null
            return null
        },
        changeStatus (item, status) {
            let message = ''

            if (status === 'confirmed') message = 'confermare'
            else if (status === 'canceled') message = 'annullare'
            else if (status === 'draft') message = 'rimandare'

            this.$store.commit('SET_APP_DIALOG_CONFIRM', {
                title: 'Attenzione',
                text: `Vuoi ${message} la commessa <b>Nr. ${item.number_id}</b>?`,
                next: async () => {
                    try {
                        await this.$api.orders.modify(item.id, { status })
                        this.key += 1
                    } catch (err) {
                        throw new Error(err.message || err.name || err)
                    } finally {
                        this.$store.commit('loading', false)
                    }
                }
            })
        },
        refreshCustomerAuthcode (item) {
            this.$store.commit('SET_APP_DIALOG_CONFIRM', {
                title: 'Attenzione',
                text: `Vuoi rigenerare il codice autorizzazione cliente per la commessa nr. ${item.number_id}?`,
                next: async () => {
                    try {
                        await this.$api.patch('v1/orders/' + item.id + '/customers_auths_codes')
                        this.key += 1
                    } catch (err) {
                        throw new Error(err.message || err.name || err)
                    } finally {
                        this.$store.commit('loading', false)
                    }
                }
            })
        },
        sendAuthCodeToCustomer (item) {
            this.$store.commit('SET_APP_DIALOG_CONFIRM', {
                title: 'Attenzione',
                text: `Vuoi notificare il codice autorizzazione al cliente per la commessa nr. ${item.number_id}?`,
                next: async () => {
                    try {
                        await this.$api.sendMail({
                            from: 'noreply@tfork.it',
                            to: item.customer.email,
                            subject: `TforK | Codice visualizzazione commessa #${item.number_id}`,
                            template: 'order.customer',
                            data: {
                                url: `${process.env.NODE_ENV === 'development' ? 'http://localhost:8082' : 'https://agenti.tfork.it'}/o/${item.id}/c/${item.customer_authcode}`
                            }
                        })
                    } catch (err) {
                        throw new Error(err.message || err.name || err)
                    } finally {
                        this.$store.commit('loading', false)
                    }
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
p {
    margin-bottom: 0 !important;
}
.item-spacer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-bottom: 0.5rem;

    &:last-child {
        margin-bottom: 0;
    }
}
</style>