<template>
    <div class="settings">
        <v-row>
            <v-col cols="3">
                <v-card class="pa-0 overflow-hidden">
                    <div
                    class="d-flex flex-column justify-center"
                    style="height: 70px; padding: 0 1.5rem;">
                        <h4 class="mb-1">{{ $api.auth.me.email }}</h4>
                        <a :href="$api.enums.origins.app">{{ $api.enums.origins.app }}</a>
                    </div>

                    <v-divider></v-divider>

                    <bxs-data-lazy :promises="() => $api.settings.list()">
                        <template #default="{ data }">
                            <v-list
                            dense
                            class="pa-0 ma-0">
                                <v-list-item to="/settings/me">
                                    <v-list-item-icon>
                                        <v-icon>person</v-icon>
                                    </v-list-item-icon>

                                    <v-list-item-content>
                                        <v-list-item-title>Profilo</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-list-item
                                v-for="item in data"
                                :key="item.id"
                                :to="item.to"
                                :disabled="item.disabled">
                                    <v-list-item-icon>
                                        <v-icon :disabled="item.disabled">{{ item.icon }}</v-icon>
                                    </v-list-item-icon>

                                    <v-list-item-content>
                                        <v-list-item-title>{{ item.label }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </template>
                    </bxs-data-lazy>
                </v-card>
            </v-col>

            <v-col cols="9">
                <div
                v-if="$route.name === 'settings'"
                style="padding-top: 70px;">
                    <h1 class="mb-2">Impostazioni</h1>
                </div>

                <router-view />
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: 'settings',
    data () {
        return {
        }
    }
}
</script>