import Vue from 'vue'

import CommaToListField from './CommaToListField.vue'
import Formizer from './Formizer.vue'
import Loading from './Loading'
import CurrencyField from './CurrencyField.vue'
import QtyField from './QtyField.vue'
import SearchField from './SearchField.vue'
import VueCurrencyInput from 'vue-currency-input'
import Uploader from './storage/Uploader.vue'
import ProductDetailsField from './ProductDetailsField.vue'
import SelectOrderField from './SelectOrderField.vue'
import draggable from 'vuedraggable'
import DepField from './DepField'
import SeoField from './SeoField'
import WysiwygField from './WysiwygField.vue'
import LinkField from './LinkField.vue'

import BxsSelectResource from './BxsSelectResource.vue'
import BxsDateField from './BxsDateField.vue'
import BxsModal from './BxsModal.vue'
import BxsDotMenu from './BxsDotMenu.vue'
import BxsBtn from './BxsBtn.vue'
import BxsDataTable from './BxsDataTable.vue'
import CustomerForm from './forms/CustomerForm.vue'
import CustomerSelectField from './CustomerSelectField.vue'
import AddressSelectField from './AddressSelectField.vue'
import AddressForm from './forms/AddressForm.vue'
import ProductCategoryField from './ProductCategoryField.vue'
import DataLazy from './DataLazy.vue'

import FormMetaQuery from './forms/FormMetaQuery.vue'
import FormDep from './forms/FormDep.vue'
import FormLabel from './forms/FormLabel.vue'
import FormAttribute from './forms/FormAttribute.vue'
import FormAttributeOption from './forms/FormAttributeOption.vue'
import FormUser from './forms/FormUser.vue'
import PageDetails from './PageDetails.vue'

// libs
import { VTooltip } from 'floating-vue'
import Toast from 'vue-toastification'

import 'vue-toastification/dist/index.css'
import 'floating-vue/dist/style.css'

Vue.use(VueCurrencyInput)
Vue.directive('tooltip', VTooltip)
Vue.use(Toast, {
    position: 'top-right',
    timeout: 5000,
    closeOnClick: true,
    pauseOnFocusLoss: false,
    pauseOnHover: false,
    newestOnTop: false,
    transition: 'Vue-Toastification__fade',
    bodyClassName: ['bxs-notify'],
    maxToasts: 1
})

Vue.component('form-dep', FormDep)
Vue.component('form-label', FormLabel)
Vue.component('form-attribute', FormAttribute)
Vue.component('form-attribute-option', FormAttributeOption)
Vue.component('form-user', FormUser)
Vue.component('form-metaquery', FormMetaQuery)

Vue.component('bxs-data-lazy', DataLazy)
Vue.component('page-details', PageDetails)
Vue.component('bxs-select-resouce-field', BxsSelectResource)
Vue.component('product-category-field', ProductCategoryField)
Vue.component('bxs-date-field', BxsDateField)
Vue.component('bxs-modal', BxsModal)
Vue.component('bxs-dot-menu', BxsDotMenu)
Vue.component('bxs-btn', BxsBtn)
Vue.component('bxs-data-table', BxsDataTable)
Vue.component('customer-select-field', CustomerSelectField)
Vue.component('customer-form', CustomerForm)
Vue.component('address-select-field', AddressSelectField)
Vue.component('address-form', AddressForm)

Vue.component('loading', Loading)
Vue.component('formizer', Formizer)
Vue.component('comma-to-list-field', CommaToListField)
Vue.component('qty-field', QtyField)
Vue.component('search-field', SearchField)
Vue.component('uploader', Uploader)
Vue.component('currency-field', CurrencyField)
Vue.component('product-details-field', ProductDetailsField)
Vue.component('select-order-field', SelectOrderField)
Vue.component('dep-field', DepField)
Vue.component('seo-field', SeoField)
Vue.component('wysiwyg-field', WysiwygField)
Vue.component('link-field', LinkField)
Vue.component('draggable', draggable)
