var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"orders"},[_vm._m(0),_c('bxs-data-table',{ref:"table",attrs:{"model-name":"Customer","resource":"customers","id":"table-customers-view","headers":[
        { value: 'external_id' },
        { value: 'business_name' },
        { value: 'vat_code' },
        { value: 'sdi' },
        { value: 'billing_address.address' },
        { value: 'email' },
        { value: 'phone' },
        { text: 'Email agente', value: 'agent.email' }
    ],"static-query":{
        spread: ['agent', 'updated_user']
    },"sort-by":['business_name'],"sort-dir":[false],"hide-default-delete-item":_vm.$api.auth.me.level > 2},scopedSlots:_vm._u([{key:"append-toolbar",fn:function(ref){
    var selected_ids = ref.selected_ids;
return [_c('bxs-btn',{attrs:{"icon":"","tooltip":"Aggiungi nuovo elemento"},on:{"click":function($event){return _vm.setForm()}}},[_c('v-icon',[_vm._v("add")])],1),_c('v-btn',{staticClass:"ml-2",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.downloadExcellOS1(selected_ids)}}},[_vm._v("Scarica excel (OS1)")])]}},{key:"item-actions",fn:function(ref){
    var id = ref.id;
return [_c('v-list-item',{on:{"click":function($event){return _vm.setForm(id)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("edit")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Modifica")])],1)],1)]}}])}),_c('v-navigation-drawer',{attrs:{"app":"","right":"","clipped":"","persistent":"","width":"440"},model:{value:(_vm.form.on),callback:function ($$v) {_vm.$set(_vm.form, "on", $$v)},expression:"form.on"}},[_c('v-card',{staticClass:"d-flex flex-column pa-0",attrs:{"height":"calc(100vh - 60px)"}},[_c('div',{staticClass:"d-flex align-center pa-4"},[_c('h2',{staticClass:"mb-0"},[_vm._v("Cliente")]),_c('v-spacer'),_c('v-btn',{attrs:{"fab":"","depressed":"","x-small":"","color":"error"},on:{"click":function($event){_vm.form.on = false}}},[_c('v-icon',[_vm._v("close")])],1)],1),_c('v-divider'),_c('div',{staticClass:"px-8",staticStyle:{"flex-grow":"1","height":"100%","overflow":"auto"}},[_c('customer-form',{key:_vm.form.on + _vm.form.id,ref:"form",attrs:{"hide-header":"","hide-footer":"","fluid":"","id":_vm.form.id},on:{"submit":_vm.submit}})],1),_c('v-divider'),_c('v-card-actions',{staticClass:"pa-4"},[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.setForm()}}},[_vm._v("Annulla e chiudi")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$refs.form.submit()}}},[_vm._v("Salva")])],1)],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-8"},[_c('h1',[_vm._v("Clienti")])])}]

export { render, staticRenderFns }