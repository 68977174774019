<template>
    <div class="bxs-data-table">
        <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
            TOOLBAR
        ------------------------------------------------------------------------------------------------------------------------------------------------ -->
        <v-toolbar
        v-if="!hideToolbar"
        flat>
            <span class="ml-1"></span>
            <slot name="prepend-toolbar" />

            <bxs-btn
            v-if="toCreateItem"
            :disabled="computed_has_selected"
            :to="toCreateItem"
            icon
            tooltip="Aggiungi nuovo elemento"
            @click="$emit('click:toolbar-new')">
                <v-icon>add</v-icon>
            </bxs-btn>

            <bxs-btn
            v-if="!hideDefaultDeleteItem"
            tooltip="Elimina selezionati"
            :disabled="!computed_has_selected"
            icon
            @click="deleteDocs()">
                <v-icon>delete</v-icon>
            </bxs-btn>

            <bxs-btn
            icon
            tooltip="Compatta tabella"
            @click="compact">
                <v-icon>view_compact_alt</v-icon>
            </bxs-btn>

            <bxs-btn
            tooltip="Gestisci i campi visibili della tabella"
            icon
            @click="show_order_fields = true">
                <v-icon>low_priority</v-icon>
            </bxs-btn>

            <bxs-btn
            tooltip="Ripristina campi visibili suggeriti"
            icon
            @click="restartFields">
                <v-icon>restart_alt</v-icon>
            </bxs-btn>

            <bxs-dot-menu>
                <v-list-item
                v-if="selected_fields.length > 1"
                @click="show_order_fields = true">
                    <v-list-item-icon>
                        <v-icon>low_priority</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Gestici i campi della tabella</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item
                v-if="selected_fields.length > 1"
                @click="restartFields">
                    <v-list-item-icon>
                        <v-icon>restart_alt</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Ripristina campi visibili suggeriti</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <slot name="toolbar-menu" />

                <v-list-item
                :disabled="!computed_has_selected"
                @click="deleteDocs()">
                    <v-list-item-icon>
                        <v-icon>delete</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Elimina selezionati</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </bxs-dot-menu>

            <v-chip
            v-if="computed_query_filters && Object.keys(computed_query_filters).length > 0"
            small
            class="mr-3">{{ Object.keys(computed_query_filters).length }} filtri applicati</v-chip>

            <slot
            name="append-toolbar"
            :selected_ids="computed_selected_ids" />

            <!-- fields -->
            <v-spacer></v-spacer>

            <!-- <v-icon
            v-if="!computed_has_selected_fields"
            v-tooltip="'Seleziona i campi da visualizzare nella tabella'"
            color="info"
            class="mr-3 pointer">info</v-icon> -->

            <!-- <v-select
            v-model="selected_fields"
            :items="computed_schema_arr"
            dense
            clearable
            multiple
            label="Campi visibili"
            hide-details
            single-line
            outlined
            flat
            :menu-props="{
                'offset-y': true
            }"
            style="max-width: 240px">
                <template #selection="{ index }">
                    <v-chip v-if="!index" small>{{ selected_fields.length }}/{{ computed_schema_arr.length }} campi visibili</v-chip>
                </template>

                <template #prepend-item>
                    <v-list-item
                    ripple
                    @mousedown.prevent>
                        <v-list-item-content>
                            <v-text-field
                            v-model="fields_search"
                            single-line
                            hide-details
                            prepend-inner-icon="search"
                            filled
                            dense
                            clearable
                            label="Cerca" />
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider class="ma-0 pa-0"></v-divider>

                    <v-list-item
                    ripple
                    @mousedown.prevent
                    @click="toggleAllFields">
                        <v-list-item-action>
                            <v-icon>{{ computed_icon_select_fields }}</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>Seleziona tutti</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider class="ma-0 pa-0"></v-divider>
                </template>
            </v-select> -->

            <search-field
            v-model="local_filters.keywords"
            prepend-icon="search"
            label="Cerca"
            clearable
            hide-details
            single-line
            class="ml-6"
            style="max-width: 240px" />
        </v-toolbar>

        <v-divider></v-divider>

        <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
            TABLE
        ------------------------------------------------------------------------------------------------------------------------------------------------ -->
        <!-- :key="computed_headers.length" -->
        <v-data-table
        ref="table"
        v-model="selected"
        fixed-header
        :headers="computed_headers"
        :height="height"
        :items="local_items"
        :items-per-page.sync="options.itemsPerPage"
        :item-key="itemKey"
        :footer-props="footerProps"
        :loading="loading"
        :multi-sort="options.multiSort"
        :must-sort="options.mustSort"
        :options.sync="options"
        :server-items-length="pagination.total"
        :show-select="showSelect && local_items.length > 0"
        :class="classes"
        @click:row="handleClickRow"
        @dblclick:row="$emit('dblclick:row', $event)">
            <template #no-data>
                <span v-if="!selected_fields.length">Seleziona i campi da visualizzare</span>
                <span v-else>Nessun elemento disponibile</span>
            </template>

            <!--  -->
            <template
            v-if="computed_has_headers && computed_has_filters"
            #header="{ props }">
                <thead class="v-data-table-header">
                    <tr class="pa-0 ma-0">
                        <th
                        v-for="v in props.headers"
                        :key="v.value"
                        :class="v.class"
                        :width="v.width"
                        class="v-data-table__divider px-0">
                            <!-- <v-icon v-if="">toll</v-icon> -->

                            <span v-if="v.filterable && v.value !== 'id' && v.value !== 'imgs' && v.value !== 'img' && v.value !== 'img_picker' && computed_filters[v.value]">
                                <bxs-date-field
                                v-if="computed_filters[v.value].type === 'date'"
                                v-model="computed_filters[v.value].model"
                                dense
                                flat
                                :outlined="false"
                                solo
                                clearable
                                full-width
                                hide-details
                                :prepend-inner-icon="null"
                                style="font-size: 1rem; font-weight: 400;"
                                @input="handleInputFilter">
                                    <template #append>
                                        <bxs-btn
                                        x-small
                                        @click="changeOperatorItemFiter(computed_filters[v.value])">{{ computed_filters[v.value].operators[computed_filters[v.value].operator_index] }}</bxs-btn>
                                    </template>
                                </bxs-date-field>

                                <!-- prepend-inner-icon="edit_note" -->
                                <v-combobox
                                v-else-if="computed_filters[v.value].type === 'array'"
                                v-model="computed_filters[v.value].model"
                                :items="computed_filters[v.value].items"
                                :item-text="computed_filters[v.value].item_text || 'text'"
                                :item-value="computed_filters[v.value].item_value || 'value'"
                                clearable
                                multiple
                                solo
                                flat
                                :outlined="false"
                                hide-details
                                dense
                                deletable-chips
                                full-width
                                small-chips
                                chips
                                :return-object="false"
                                style="font-size: 1rem; font-weight: 400;"
                                @input="handleInputFilter">
                                    <template #selection="{ index }">
                                        <v-chip
                                        v-if="!index"
                                        color="primary"
                                        x-small>{{ computed_filters[v.value].model.length }}</v-chip>
                                    </template>
                                </v-combobox>

                                <!-- prepend-inner-icon="checklist" -->
                                <v-select
                                v-else-if="computed_filters[v.value].type === 'boolean'"
                                v-model="computed_filters[v.value].model"
                                :items="[{ text: 'Si', value: true }, { text: 'No', value: false }]"
                                :item-text="computed_filters[v.value].item_text || 'text'"
                                :item-value="computed_filters[v.value].item_value || 'value'"
                                clearable
                                solo
                                :outlined="false"
                                flat
                                hide-details
                                dense
                                full-width
                                :return-object="false"
                                style="font-size: 1rem; font-weight: 400;"
                                @input="handleInputFilter">
                                    <template #selection="{ index }">
                                        <v-chip
                                        v-if="!index"
                                        x-small>{{ computed_filters[v.value].model ? 'Si' : 'No' }}</v-chip>
                                    </template>
                                </v-select>

                                <currency-field
                                v-else-if="computed_filters[v.value].type === 'number'"
                                v-model="computed_filters[v.value].model"
                                :emit-immediately="false"
                                :currency="null"
                                dense
                                :outlined="false"
                                flat
                                hide-details
                                :suffix="null"
                                clearable
                                solo
                                @clear="clearItemFilterValue(computed_filters[v.value])"
                                @input="handleInputFilter">
                                    <template #append>
                                        <bxs-btn
                                        x-small
                                        @click="changeOperatorItemFiter(computed_filters[v.value])">{{ computed_filters[v.value].operators[computed_filters[v.value].operator_index] }}</bxs-btn>
                                    </template>
                                </currency-field>

                                <search-field
                                v-else
                                v-model="computed_filters[v.value].model"
                                :type="computed_filters[v.value].input_type"
                                dense
                                flat
                                :outlined="false"
                                :label="null"
                                solo
                                clearable
                                full-width
                                hide-details
                                :prepend-inner-icon="null"
                                style="font-size: 1rem; font-weight: 400;"
                                @input="handleInputFilter">
                                    <template
                                    v-if="computed_filters[v.value].operators.length > 1"
                                    #append>
                                        <bxs-btn
                                        small
                                        @click="changeOperatorItemFiter(computed_filters[v.value])">{{ computed_filters[v.value].operators[computed_filters[v.value].operator_index] }}</bxs-btn>
                                    </template>
                                </search-field>
                            </span>

                            <div
                            v-else
                            v-tooltip="'Filtro non attivo'"
                            style="display: block;"
                            class="text-center">
                                <v-icon size="20">block</v-icon>
                            </div>
                        </th>
                    </tr>
                </thead>
            </template>

            <!--  -->
            <template
            v-for="v in computed_headers"
            :slot="'item.' + v.value"
            slot-scope="{ item, value }">
                <slot
                :name="'item.' + v.value"
                :value="value"
                :item="item">
                    <span
                    :key="v.value"
                    v-tooltip="{
                        content: $t(String(value)),
                        placement: 'top',
                        delay: {
                            show: 200,
                            hide: 0
                        }
                    }">{{ $t(value) }}</span>
                </slot>
            </template>

            <template #item.created_at="{ value }">{{ value | toHumanDate('l') }}</template>
            <template #item.updated_at="{ value }">{{ value | toHumanDate('l') }}</template>

            <template #item.imgs="{ item, value }">
                <div class="py-2">
                    <slot
                    v-if="$scopedSlots['item.imgs']"
                    name="item.imgs"
                    :item="item"
                    :value="value" />

                    <v-avatar
                    v-else
                    size="50"
                    @click="$emit('click:item-imgs', item)">
                        <v-img
                        v-if="value && value.length > 0"
                        :src="value[0].url"
                        aspect-ratio="1" />

                        <v-img
                        v-else
                        src="/img/no-photo.png"
                        aspect-ratio="1" />
                    </v-avatar>
                </div>
            </template>

            <template #item.img="{ item, value }">
                <div class="py-2">
                    <v-avatar
                    size="50"
                    class="pointer"
                    @click="$emit('click:item-img', item)">
                        <v-img
                        v-if="value && value.url"
                        :src="value.url"
                        aspect-ratio="1" />

                        <v-img
                        v-else
                        src="/img/no-photo.png"
                        aspect-ratio="1" />
                    </v-avatar>
                </div>
            </template>

            <template #item.id="{ item, value }">
                <bxs-dot-menu>
                    <!-- <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle>{{ item.name.it || item.title.it }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider class="ma-0 pa-0"></v-divider> -->

                    <v-list-item
                    v-if="toUpdateItem"
                    :to="`${toUpdateItem}/${value}`">
                        <v-list-item-icon>
                            <v-icon>edit</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>Visualizza / Modifica</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <slot
                    name="item-actions"
                    :item="item"
                    :id="value" />

                    <v-list-item
                    v-if="!hideDefaultDuplicateItem"
                    @click="duplicate(value)">
                        <v-list-item-icon>
                            <v-icon>content_copy</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Duplica</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                    v-if="!hideDefaultDeleteItem"
                    @click="deleteDocs(value)">
                        <v-list-item-icon>
                            <v-icon>delete</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>Elimina</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </bxs-dot-menu>
            </template>
        </v-data-table>

        <!-- ------------------------------------------------------------------------------------------------------------
            MODAL ORDER TABLE FIELDS
        ------------------------------------------------------------------------------------------------------------ -->
        <bxs-modal
        v-model="show_order_fields"
        title="Gestisci i campi visibili della tabella"
        width="1024">
            <div class="d-flex mb-6">
                <!-- <v-chip
                outlined
                class="mr-1"
                @click="toggleAllFields">Seleziona tutti</v-chip> -->

                <v-chip
                outlined
                class="mr-1"
                @click="selected_fields = []">Deseleziona tutti</v-chip>
            </div>

            <div v-if="computed_has_selected_fields">
                <div class="mb-6">
                    <h3>Campi selezionati</h3>
                </div>

                <draggable
                v-model="selected_fields"
                ghost-class="draggable-ghost"
                tag="ul"
                class="d-flex flex-wrap">
                    <li
                    v-for="(v, i) in selected_fields"
                    :key="v"
                    class="dragging mr-2 mb-2">
                        <v-chip class="dragging">
                            <v-icon small>drag_indicator</v-icon>
                            <span class="mx-2">{{ $t(v) }}</span>
                            <v-icon
                            small
                            @click="deselectField(i)">close</v-icon>
                        </v-chip>
                    </li>
                </draggable>
            </div>

            <v-divider
            v-if="computed_has_selected_fields"
            class="my-3"></v-divider>

            <div>
                <div class="mb-6">
                    <h3 class="mb-1">Campi disponibili</h3>
                    <p class="mb-3">Seleziona i campi da visualizzare nella tabella.</p>

                    <v-text-field
                    v-model="fields_search"
                    single-line
                    hide-details
                    prepend-inner-icon="search"
                    filled
                    dense
                    clearable
                    label="Cerca" />
                </div>

                <v-chip-group column>
                    <v-chip
                    v-for="(v, i) in computed_schema_arr"
                    :key="i"
                    :value="v.value"
                    :disabled="selected_fields.includes(v.value)"
                    @click="selected_fields.push(v.value)">{{ v.text }}</v-chip>
                </v-chip-group>
            </div>
        </bxs-modal>
    </div>
</template>

<script>
import dot from 'dot-object'

export default {
    name: 'bxs-data-table',
    props: {
        resource: {
            type: String,
            required: false,
            default: null
        },
        schema: {
            type: Object,
            required: false,
            default: null
        },
        height: {
            type: [String, Number],
            required: false,
            default: '60vh'
        },
        'to-create-item': {
            type: String,
            required: false,
            default: null
        },
        'to-update-item': {
            type: String,
            required: false,
            default: null
        },
        'item-key': {
            type: String,
            required: false,
            default: 'id'
        },
        'hide-filters': {
            type: Array,
            required: false,
            default: () => []
        },
        'hide-default-delete-item': {
            type: Boolean,
            required: false,
            default: false
        },
        'hide-default-duplicate-item': {
            type: Boolean,
            required: false,
            default: true
        },
        'hide-id-item': {
            type: Boolean,
            required: false,
            default: false
        },
        'hide-toolbar': {
            type: Boolean,
            required: false,
            default: false
        },
        id: {
            type: String,
            required: false,
            default: null
        },
        'col-width': {
            type: String,
            required: false,
            default: '160px'
        },
        'model-name': {
            type: String,
            required: false,
            default: null
        },
        'sort-by': {
            type: Array,
            required: false,
            default: () => ['created_at']
        },
        'sort-dir': {
            type: Array,
            required: false,
            default: () => [true]
        },
        'static-query': {
            type: Object,
            required: false,
            default: () => {}
        },
        'static-filters': {
            type: Object,
            required: false,
            default: () => {}
        },
        'show-select': {
            type: Boolean,
            required: false,
            default: true
        },
        headers: {
            type: Array,
            required: false,
            default: () => []
        },
        items: {
            type: Array,
            required: false,
            default: () => []
        },
        'items-per-page': {
            type: [String, Number],
            required: false,
            default: 40
        },
        filters: {
            type: Array,
            required: false,
            default: () => []
        },
        'remove-fields': {
            type: Array,
            required: false,
            default: () => ['__v']
        }
    },
    data() {
        return {
            // items
            local_resource: this.resource,
            local_items: [],
            selected: [],
            local_filters: {
                keywords: null,
                ...this.staticFilters
            },
            // fields
            local_schema: {},
            selected_fields: [],
            show_order_fields: false,
            type_to_input: {
                String: 'text',
                Number: 'number',
                Date: 'date'
            },
            fields_search: null,
            computed_headers: [],
            computed_filters: {},
            // table
            classes: {
                'bxs-table-dense': false
            },
            col: {
                width: this.colWidth
            },
            loading: true,
            options: {
                itemsPerPage: this.itemsPerPage,
                multiSort: true,
                mustSort: false, // ordinamento, passerà sempre tra crescente e decrescente
                page: 1,
                sortBy: this.sortBy,
                sortDesc: this.sortDir
            },
            footerProps: {
                'items-per-page-options': [10, 20, this.itemsPerPage],
                showFirstLastPage: true
            },
            pagination: {
                total: 0
            }
        }
    },
    computed: {
        // fields
        computed_schema_arr () {
            let v = []

            const a = [...new Set([
                ...this.headers.map(i => i.value).filter(i => i in this.local_schema),
                ...Object.keys(this.local_schema)
            ])]

            v = a.map((v) => {
                return {
                    text: this.getLabelFromLocalHeaders(v),
                    value: v
                }
            }).sort((a, b) => {
                return a.text.toLowerCase() > b.text.toLowerCase() ? 1 : b.text.toLowerCase() > a.text.toLowerCase() ? -1 : 0
            })

            if (this.fields_search && this.fields_search.length > 0) {
                v = v.filter((i) => {
                    return i.text.toLowerCase().includes(this.fields_search) || i.text.toUpperCase().includes(this.fields_search)
                })
            }

            return v
        },
        computed_has_headers () {
            return this.computed_headers.length > 0
        },
        computed_has_filters () {
            return !!this.computed_filters && Object.keys(this.computed_filters).length > 0
        },
        computed_has_selected_all_fields () {
            return this.selected_fields.length === this.computed_schema_arr.length
        },
        computed_has_selected_fields () {
            return this.selected_fields.length > 0
        },
        computed_icon_select_fields () {
            let v = 'check_box_outline_blank'

            if (this.selected_fields.length >= 1 && this.selected_fields.length < this.computed_schema_arr.length) {
                v = 'indeterminate_check_box'
            }

            if (this.computed_has_selected_all_fields) {
                v = 'disabled_by_default'
            }

            return v
        },
        // query
        computed_query_filters () {
            const v = {}

            if (this.computed_filters && Object.keys(this.computed_filters).length > 0) {
                for (const k in this.computed_filters) {
                    const item = this.computed_filters[k]

                    if (item.model && String(item.model).length > 0) {
                        v[item.field] = item.operators[item.operator_index] + '|' + item.model
                    }
                }
            }

            return v
        },
        computed_has_query_filters () {
            return !!this.computed_query_filters && Object.keys(this.computed_query_filters).length > 0
        },
        computed_sort() {
            const v = {}

            this.options.sortBy.forEach((key, index) => {
                const field = this.local_schema[key] || {}
                v[field.query_key || key] = this.options.sortDesc[index] === true ? -1 : 1
            })

            return v
        },
        // items
        computed_has_selected() {
            return this.computed_selected_ids.length > 0
        },
        computed_selected_ids() {
            return this.selected.map(item => item.id)
        }
    },
    created () {
        this.start()
    },
    mounted () {
        this.$nextTick(this.play)
    },
    watch: {
        schema: {
            deep: true,
            immediate: false,
            handler(newVal) {
                console.log('table watch schema')
                this.getSchema()
            }
        },
        options: {
            deep: true, // Questo farà sapere a Vue di guardare all'interno dell'array
            immediate: false, // Si attiva non appena il componente viene creato
            handler(newVal) {
                console.log('table watch options')
                this.getItems()
            }
        },
        'local_filters.keywords' (newVal, oldVal) {
            console.log('table watch filters.keywords')
            this.getItems()
        },
        selected_fields (newVal) {
            console.log('table watch selected_fields')

            // localStorage.setItem((this.id || this.resource) + '.table_fields', newVal)
            this.computed_headers = this.genHeaders()
            this.computed_filters = this.genFilters()
        }
    },
    methods: {
        async start () {
            console.log('table start')

            await this.getSchema()

            if (this.computed_has_query_filters) {
                this.getItems()
            }
        },
        play () {
            console.log('table play')
        },
        // fields
        async getSchema () {
            console.log('table getSchema')

            const schema = {}

            if (this.modelName && this.modelName.length > 0) {
                // const paths = await this.$api.getSchema(this.modelName)
                const paths = this.$api.enums.app.schemes[this.modelName]
                for (const k in paths) {
                    schema[k] = {
                        query_key: paths[k].path,
                        instance: paths[k].instance.toLowerCase()
                    }
                }
            }

            if (!Object.keys(schema).length) {
                return alert('Schema is required!')
            }

            if (this.schema) {
                for (const k in this.schema) {
                    schema[k] = this.schema[k]
                }
            }

            if (this.removeFields.length > 0) {
                this.removeFields.forEach((v) => {
                    if (schema[v]) delete schema[v]
                })
            }

            this.local_schema = schema

            // set initial fields
            // const is_first_time = !localStorage.getItem((this.id || this.resource) + '.table_fields.fallback')
            // if (is_first_time) localStorage.setItem((this.id || this.resource) + '.table_fields.fallback', true)

            let fields = localStorage.getItem((this.id || this.resource) + '.table_fields') ? localStorage.getItem((this.id || this.resource) + '.table_fields').split(',') : []

            // is_first_time
            if (!fields.length) {
                fields = this.headers.map(i => i.value)
            }

            this.selected_fields = fields // .filter(i => i in this.local_schema) => rimuove campi che non sono in schema

            return schema
        },
        genHeaders () {
            if (!this.computed_has_selected_fields) return []

            console.log('table genHeaders', this.selected_fields)

            const h = JSON.parse(JSON.stringify(this.selected_fields))
            const v = []

            h.forEach((i) => {
                const fieldFromHeaders = this.headers.find(h => h.value === i)

                const i_f = {
                    text: this.getLabelFromLocalHeaders(i),
                    value: i,
                    filterable: true,
                    sortable: true
                }

                if (fieldFromHeaders) {
                    if ('filterable' in fieldFromHeaders) {
                        i_f.filterable = fieldFromHeaders.filterable
                    }

                    if ('sortable' in fieldFromHeaders) {
                        i_f.sortable = fieldFromHeaders.sortable
                    }
                }

                //
                if (v.some(m => m.value === i_f.value)) return

                const is_img = i_f.value === 'img' || i_f.value === 'imgs' || i_f.value === 'img_picker'
                let cellClass = 'truncated'
                let width = i_f.width || this.col.width

                if (is_img) {
                    cellClass += ' bxs-table--col-img'
                    width = '1%'
                    i_f.filterable = false
                    i_f.sortable = false
                }

                v.push({
                    width: width,
                    divider: i_f.divider || false,
                    cellClass: cellClass,
                    class: 'truncated',
                    ...i_f
                })
            })

            if (!this.hideIdItem) {
                v.push({
                    text: '',
                    value: 'id',
                    width: '1%',
                    cellClass: 'cell-id sticky-right',
                    class: 'cell-id sticky-right',
                    sortable: false,
                    filterable: false
                })
            }

            return v
        },
        genFilters () {
            if (!this.computed_has_selected_fields) return {}

            console.log('table genFilters', this.selected_fields)

            const items = {}
            const keys = JSON.parse(JSON.stringify(this.selected_fields))
            const storage_v = localStorage.getItem((this.id || this.resource) + '.table_filters')
            const storage_items = storage_v && storage_v.length > 0 ? JSON.parse(storage_v) : null

            keys.forEach((k) => {
                const field = this.local_schema[k] || this.headers.find(i => i.value === k)

                if (field) {
                    let operators = ['=']
                    let operator = '='

                    if (field.instance === 'number' || field.instance === 'date') {
                        operators = ['=', '<', '<=', '>', '>=']
                    } else if (field.instance === 'array') {
                        operators = ['[]']
                        operator = '[]'
                    } else if (field.instance === 'boolean') {
                        operators = ['=']
                        operator = '='
                    }

                    items[k] = {
                        label: this.getLabelFromLocalHeaders(k),
                        field: field.query_key || k,
                        operators: field.operators || operators,
                        operator: field.operator || operator,
                        operator_index: field.operator_index || 0,
                        model: null,
                        type: field.instance || 'string',
                        input_type: this.type_to_input[field.instance] || 'text',
                        items: field.items || []
                    }

                    if (storage_items && Object.keys(storage_items).length > 0) {
                        if (k in storage_items) {
                            const spl_val = storage_items[k].split('|')
                            items[k].operator = spl_val[0]
                            items[k].operator_index = items[k].operators.findIndex(_v => _v === items[k].operator)
                            items[k].model = items[k].operator === '[]' ? spl_val[1].split(',') : spl_val[1]
                        }
                    }
                }
            })

            return items
        },
        getLabelFromLocalHeaders (k) {
            let label = k
            const ih = this.headers.find(_i => _i.value === k)

            if (ih && ih.text) label = ih.text
            else label = this.$t(k)

            return label
        },
        restartFields () {
            console.log('table restartFields')

            if (this.headers.length > 0) {
                this.selected_fields = this.headers.map(i => i.value)
            }
        },
        toggleAllFields () {
            if (this.selected_fields.length > 0) this.selected_fields = []
            else this.selected_fields = JSON.parse(JSON.stringify(this.computed_schema_arr.map(i => i.value)))
        },
        deselectField (i) {
            this.selected_fields.splice(i, 1)
        },
        // filters
        clearItemFilterValue (item) {
            item.operator_index = 0
            item.operator = item.operators[0]
        },
        changeOperatorItemFiter (item) {
            console.log('table changeOperatorItemFiter')

            if (item.operator_index >= item.operators.length - 1) item.operator_index = 0
            else item.operator_index += 1

            this.handleInputFilter()
        },
        handleInputFilter (val) {
            console.log('table handleInputFilter', val)

            const v_alt = {}
            this.selected_fields.forEach((v) => {
                const field = this.local_schema[v]
                if (field && this.computed_query_filters[field.query_key || v]) {
                    v_alt[v] = this.computed_query_filters[field.query_key || v]
                }
            })
            // localStorage.setItem((this.id || this.resource) + '.table_filters', JSON.stringify(v_alt))

            this.getItems()
        },
        // table
        compact () {
            this.classes['bxs-table-dense'] = !this.classes['bxs-table-dense']
            this.col.width = this.classes['bxs-table-dense'] ? '60px' : this.colWidth
            this.computed_headers = this.genHeaders()
        },
        handleClickRow (item) {
            this.$emit('click:row', item)
        },
        // data
        async getItems() {
            this.loading = true
            console.log('table getItems', this.computed_query_filters)

            try {
                const docs = await dot.pick(this.resource, this.$api).list({
                    limit: this.options.itemsPerPage,
                    page: this.options.page,
                    sort: this.computed_sort,
                    ...this.staticQuery,
                    filters: {
                        ...this.local_filters,
                        ...this.computed_query_filters
                    }
                })

                this.local_items = docs.docs || docs
                this.pagination.total = docs.pagination ? docs.pagination.total_docs : this.local_items.length

                return docs
            } catch (err) {
                throw new Error(err)
            } finally {
                this.loading = false
            }
        },
        async deleteDocs(ids, title, text) {
            try {
                this.$store.commit('SET_APP_DIALOG_CONFIRM', {
                    title: title || 'Attenzione',
                    text: text || 'Vuoi eliminare questa risorsa?',
                    next: async () => {
                        this.loading = true
                        await dot.pick(this.resource, this.$api).remove(ids || this.computed_selected_ids)
                        this.loading = false
                        this.getItems()
                    }
                })
            } catch (err) {
                throw new Error(err.message || err.name)
            }
        },
        async duplicate (id) {
            this.loading = true

            try {
                await this.$api.duplicate(this.modelName, id)
                this.getItems()
            } catch (err) {
                throw new Error(err.message || err.name)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang="scss">
.bxs-data-table {
    // .v-select__selections {
    //     input {
    //         display: none;
    //     }
    // }
}

table > tbody > th > td.sticky-right,
table > thead > th > th.sticky-right,
table > tbody > tr > td.sticky-right,
table > thead > tr > th.sticky-right {
    position: sticky !important;
    position: -webkit-sticky !important;
    right: 0 !important;
    border-left: 1px solid rgba(#fff, 0.1);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    z-index: 1;
}

table > thead > th > th.sticky-right,
table > thead > tr > th.sticky-right {
    z-index: 3 !important;
}

table > tbody > th > td.sticky-left,
table > thead > th > th.sticky-left,
table > tbody > tr > td.sticky-left,
table > thead > tr > th.sticky-left {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0 !important;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    z-index: 1;
}

table > thead > th > th.sticky-left,
table > thead > tr > th.sticky-left {
    z-index: 3 !important;
}

.v-data-table {
    tbody {
        .v-input {
            &:not(.v-input--radio-group, .v-input--switch) {
                font-size: 12px;

                .v-input__control {
                    min-height: 40px !important;

                    .v-input__slot {
                        background: rgba(#000, 0.05) !important;
                    }
                }
            }
        }

        tr {
            &:nth-child(even) {
                background-color: rgba(#000, 0.02);
            }
        }

        &.theme--dark {
            tr {
                &:nth-child(even) {
                    background-color: rgba(#fff, 0.02);
                }
            }
        }

        td {
            border-bottom: none !important;
        }
    }

    th,
    td {
        width: 160px !important;
        max-width: 160px !important;

        &.bxs-table--col-img,
        &.cell-id {
            width: 1% !important;
            max-width: 1% !important;
        }

        &.cell-id {
            border-left: thin solid rgba(0, 0, 0, .12);
        }
    }

    &.bxs-table-dense {
        th,
        td  {
            width: 60px !important;
            max-width: 60px !important;
        }
    }
}
</style>