<template>
    <div class="address">
        <formizer
        ref="formizer"
        model="Address"
        undo="/clienti"
        hide-footer
        hide-header
        :id="id"
        @draw-completed="drawCompleted"
        @submit="intSubmit">
            <template #body="{ fields, rules }">
                <v-combobox
                v-model="fields.indirizzo.model"
                :items="$api.enums.address_indirizzi"
                :return-object="false"
                label="* Indirizzo stradale"
                outlined
                dense
                :rules="[rules.required]" />

                <v-combobox
                v-model="fields.città.model"
                :items="$api.enums.address_cities"
                :return-object="false"
                label="* Città"
                outlined
                dense
                :rules="[rules.required]" />

                <v-row>
                    <v-col cols="6">
                        <v-combobox
                        v-model="fields.cap.model"
                        :items="$api.enums.address_caps"
                        :return-object="false"
                        label="Cap"
                        dense
                        filled
                        outlined />
                    </v-col>

                    <v-col cols="6">
                        <v-combobox
                        v-model="fields.provincia.model"
                        :items="$api.enums.address_provinces"
                        :return-object="false"
                        label="Provincia"
                        filled
                        outlined
                        dense />
                    </v-col>

                    <v-col cols="12">
                        <v-text-field
                        v-model="fields.nation.model"
                        label="Nazione"
                        dense
                        filled
                        outlined
                        hide-details
                        :menu-props="{ top: false, bottom: true, offsetY: true }" />
                    </v-col>
                </v-row>

                <!-- <comma-to-list-field
                v-model="fields.riferimenti.model"
                label="Riferimenti" /> -->

                <v-combobox
                v-model="fields.note.model"
                :items="$api.enums.address_note"
                :return-object="false"
                label="Note (riferimento, scala, piano, cantiere, interno, ...)"
                dense
                filled
                outlined />
            </template>
        </formizer>
    </div>
</template>

<script>
export default {
    name: 'customer',
    props: {
        id: {
            type: String,
            required: false,
            default: null
        },
        'contact-id': {
            type: String,
            required: false,
            default: null
        },
        'hide-footer': {
            type: Boolean,
            required: false,
            default: false
        },
        'hide-header': {
            type: Boolean,
            required: false,
            default: true
        }
    },
    methods: {
        drawCompleted (method, fields, doc, next) {
            this.$refs.formizer.valorize({
                contact: this.contactId
            })

            next()
        },
        submit () {
            return this.$refs.formizer.submit()
        },
        async intSubmit (method, model, body, id, isUndo, undo) {
            this.$emit('submit', method, model, body, id, isUndo, undo)
        }
    }
}
</script>
