<template>
    <div class="deps">
        <div class="mb-6">
            <h1>Categorie</h1>
        </div>

        <bxs-data-table
        v-show="view === 'table'"
        ref="table"
        model-name="Dep"
        resource="deps"
        id="table-deps-view"
        to-create-item="/deps/new?type=category"
        to-update-item="/deps"
        :headers="[
            { text: 'Nome', value: 'name.it' },
            { text: 'Livello', value: 'level' },
            { text: 'Path', value: 'path.it' },
            { text: 'Slug', value: 'slug.it' },
            { text: 'Ordine in menu', value: 'orders.menu' },
            { text: 'Nr. prodotti visibili', value: 'product_count' },
            { text: 'Disponibile', value: 'is_available' },
            { text: 'Visibile in menu', value: 'is_in_menu' }
        ]"
        :sort-by="['name.it']"
        :sort-dir="[false]"
        :static-filters="{
            type: '=|category'
        }">
            <!-- <template #append-toolbar>
                <bxs-btn
                tooltip="Vista ad albero"
                icon
                @click="view = 'tree'">
                    <v-icon>account_tree</v-icon>
                </bxs-btn>
            </template> -->

            <template #item.orders.menu="{ item, value }">
                <qty-field
                :value="value"
                hide-details
                single-line
                dense
                solo
                flat
                :outlined="false"
                style="max-width: 120px;"
                @change="$api.deps.modify(item.id, { 'orders.menu': $event })" />
            </template>

            <template #item.is_available="{ item, value }">
                <v-switch
                :input-value="value"
                inset
                dense
                hide-details
                single-line
                class="d-inline ma-0 pa-0"
                @change="$api.deps.modify(item.id, { is_available: $event })" />
            </template>

            <template #item.is_in_menu="{ item, value }">
                <v-switch
                :input-value="value"
                inset
                dense
                hide-details
                single-line
                class="d-inline ma-0 pa-0"
                @change="$api.deps.modify(item.id, { is_in_menu: $event })" />
            </template>

            <template #item-actions="{ item }">
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>edit</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Modifica</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item
                :href="item.permalink.it"
                target="_blank">
                    <v-list-item-icon>
                        <v-icon>language</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Preview</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </bxs-data-table>

        <bxs-data-lazy
        v-if="view === 'tree'"
        v-model="tree_items"
        :promises="() => $api.deps.list({ view: 'tree', sort: { name: 1 }, filters: { type: '=|category' } })">
            <div>
                <v-toolbar flat>
                    <bxs-btn
                    tooltip="Vista a tabella"
                    text
                    @click="view = 'table'">
                        <v-icon left>chevron_left</v-icon>
                        <span>Indietro</span>
                    </bxs-btn>
                </v-toolbar>

                <bxs-tree-view v-model="tree_items">
                    <!-- <template #prepend>
                        <v-icon left>drag_indicator</v-icon>
                    </template>

                    <template #label="{ item }">
                        <span>{{ item.name }}</span>
                    </template> -->
                </bxs-tree-view>
            </div>
        </bxs-data-lazy>
    </div>
</template>

<script>
export default {
    name: 'deps',
    data () {
        return {
            view: 'table',
            tree_items: []
        }
    }
}
</script>