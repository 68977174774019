import Vue from 'vue'
import VueRouter from 'vue-router'

import {
    auth,
    nextFactory
} from './middlewares'

import Home from '../views/Home.vue'
import Signin from '../views/Signin.vue'
import SigninRecovery from '../views/SigninRecovery.vue'
import SigninRecoveryVerify from '../views/SigninRecoveryVerify.vue'
import SigninReset from '../views/SigninReset.vue'

import Agents from '../views/Agents.vue'
import Banner from '../views/Banner.vue'
import Banners from '../views/Banners.vue'
import Brands from '../views/Brands.vue'
import Categories from '../views/Categories.vue'
import Collections from '../views/Collections.vue'
import Customers from '../views/Customers.vue'
import Dep from '../views/Dep.vue'
import File from '../views/File.vue'
import Orders from '../views/Orders.vue'
import Order from '../views/Order.vue'
import OrderPDFTemplate from '../views/OrderPDFTemplate.vue'
import Page from '../views/Page.vue'
import Pages from '../views/Pages.vue'
import Product from '../views/Product.vue'
import Products from '../views/Products.vue'
import Storage from '../views/Storage.vue'
import Store from '../views/Store.vue'
import Stores from '../views/Stores.vue'
import Subs from '../views/Subs.vue'
import User from '../views/User.vue'
import Users from '../views/Users.vue'

import Histories from '../views/Histories.vue'
import Settings from '../views/Settings.vue'
import SettingsProfile from '../views/children/settings/SettingsProfile.vue'
import SettingsAuths from '../views/children/settings/SettingsAuths.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/signin',
        name: 'signin',
        component: Signin,
        meta: {
            middlewares: [auth]
        }
    },
    {
        path: '/signin/recovery',
        name: 'signin_recovery',
        component: SigninRecovery
    },
    {
        path: '/signin/recovery_verify',
        name: 'signin_recovery_verify',
        component: SigninRecoveryVerify
    },
    {
        path: '/signin/reset',
        name: 'signin_reset',
        component: SigninReset
    },
    {
        path: '*',
        redirect: '/'
    },
    // ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    {
        path: '/',
        name: 'home',
        component: Home,
        meta: {
            middlewares: [auth]
        }
    },
    // ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    {
        path: '/histories',
        name: 'histories',
        component: Histories,
        meta: {
            middlewares: [auth]
        }
    },
    // ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    {
        path: '/settings',
        name: 'settings',
        component: Settings,
        meta: {
            middlewares: [auth]
        },
        children: [
            {
                path: '',
                redirect: 'me'
            },
            {
                path: 'me',
                name: 'settings_profile',
                component: SettingsProfile,
                meta: {
                    middlewares: [auth]
                }
            },
            {
                path: 'auths',
                name: 'settings_auths',
                component: SettingsAuths,
                meta: {
                    middlewares: [auth]
                }
            }
        ]
    },
    // ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    {
        path: '/pages',
        name: 'pages',
        component: Pages,
        meta: {
            middlewares: [auth]
        }
    },
    {
        path: '/pages/:id',
        name: 'page',
        component: Page,
        meta: {
            middlewares: [auth]
        }
    },
    // ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    {
        path: '/orders',
        name: 'orders',
        component: Orders,
        meta: {
            middlewares: [auth]
        }
    },
    {
        path: '/orders/:id',
        name: 'order',
        component: Order,
        meta: {
            middlewares: [auth]
        }
    },
    {
        path: '/orders/:id/pdf',
        name: 'order_pdf_template',
        component: OrderPDFTemplate
        // meta: {
        //     middlewares: [auth]
        // }
    },
    // ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    {
        path: '/customers',
        name: 'customers',
        component: Customers,
        meta: {
            middlewares: [auth]
        }
    },
    {
        path: '/agents',
        name: 'agents',
        component: Agents,
        meta: {
            middlewares: [auth]
        }
    },
    {
        path: '/users',
        name: 'users',
        component: Users,
        meta: {
            middlewares: [auth]
        }
    },
    {
        path: '/users/:id',
        name: 'user',
        component: User,
        meta: {
            middlewares: [auth]
        }
    },
    // ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    {
        path: '/storage',
        name: 'storage',
        component: Storage,
        meta: {
            middlewares: [auth]
        }
    },
    {
        path: '/files/:id',
        name: 'file',
        component: File,
        meta: {
            middlewares: [auth]
        }
    },
    // ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    {
        path: '/banners',
        name: 'banners',
        component: Banners,
        meta: {
            middlewares: [auth]
        }
    },
    {
        path: '/banners/:id',
        name: 'banner',
        component: Banner,
        meta: {
            is_form_view: true,
            middlewares: [auth]
        }
    },
    // ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    {
        path: '/collections/:slug',
        name: 'collections',
        component: Collections,
        meta: {
            middlewares: [auth]
        }
    },
    {
        path: '/products',
        name: 'products',
        component: Products,
        meta: {
            middlewares: [auth]
        }
    },
    {
        path: '/products/:id',
        name: 'product',
        component: Product,
        meta: {
            is_form_view: true,
            middlewares: [auth]
        }
    },
    // ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    {
        path: '/categories',
        name: 'categories',
        component: Categories,
        meta: {
            middlewares: [auth]
        }
    },
    {
        path: '/brands',
        name: 'brands',
        component: Brands,
        meta: {
            middlewares: [auth]
        }
    },
    {
        path: '/deps/:id',
        name: 'dep',
        component: Dep,
        meta: {
            is_form_view: true,
            middlewares: [auth]
        }
    },
    // ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    {
        path: '/stores',
        name: 'stores',
        component: Stores,
        meta: {
            middlewares: [auth]
        }
    },
    {
        path: '/stores/:id',
        name: 'store',
        component: Store,
        meta: {
            is_form_view: true,
            middlewares: [auth]
        }
    },
    // ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    {
        path: '/subs',
        name: 'subs',
        component: Subs,
        meta: {
            middlewares: [auth]
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

const ctx = Vue.prototype

router.beforeEach((to, from, next) => {
    if (to.name !== 'signin') {
        localStorage.setItem(window.$api.prefix + '_last_page', to.fullPath)
    }

    if (to.meta.middlewares) {
        const middlewares = Array.isArray(to.meta.middlewares) ? to.meta.middlewares : [to.meta.middlewares]
        const context = { ctx, from, to, next, router }
        const nextMiddleware = nextFactory(context, middlewares, 1)
        const auths = to.meta.authorize ? to.meta.authorize : []
        return middlewares[0](auths, { ...context, next: nextMiddleware })
    }

    return next()
})

export default router