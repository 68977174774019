<template>
    <div class="brands-view">
        <div class="mb-8">
            <h1>Brand</h1>
        </div>

        <!-- TOOLBAR -->
        <v-toolbar flat>
            <v-btn
            color="primary"
            :disabled="tableHasSelected"
            to="/deps/new?type=brand"
            class="mr-2">
                <span>Nuovo</span>
            </v-btn>

            <v-expand-transition>
                <span
                v-if="tableHasSelected"
                class="ml-2">
                    <v-btn icon>
                        <v-icon>delete</v-icon>
                    </v-btn>
                </span>
            </v-expand-transition>

            <v-spacer></v-spacer>

            <search-field
            v-model="table.filters.keywords"
            append-icon="search"
            label="Cerca"
            solo
            dense
            clearable
            hide-details
            single-line
            style="max-width: 240px" />
        </v-toolbar>

        <v-data-table
        v-model="table.selected"
        calculate-widths
        fixed-header
        :headers="tableHeader"
        height="60vh"
        :items="table.items"
        :items-per-page.sync="table.options.itemsPerPage"
        item-key="id"
        :footer-props="table.footerProps"
        :loading="table.loading"
        :multi-sort="table.options.multiSort"
        :must-sort="table.options.mustSort"
        :options.sync="table.options"
        :server-items-length="table.pagination.total"
        show-select>
            <template #item.imgs="{ value }">
                <div class="py-2 pointer">
                    <v-img
                    v-if="value.length > 0"
                    :src="value[0].url | toThumb"
                    max-width="60" />
                </div>
            </template>

            <template #item.id="{ value }">
                <v-menu :disabled="tableHasSelected" offset-y>
                    <template #activator="{ on }">
                        <v-btn
                        v-on="on"
                        :disabled="tableHasSelected"
                        icon>
                            <v-icon size="25">more_vert</v-icon>
                        </v-btn>
                    </template>

                    <v-list dense>
                        <v-list-item :to="`/categories/${value}`">
                            <v-list-item-title>Modifica</v-list-item-title>
                        </v-list-item>

                        <v-list-item @click="deleteDocs(value)">
                            <v-list-item-title>Elimina</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import tabler from '@/mixins/tabler'

export default {
    name: 'brands',
    mixins: [
        tabler(
            'deps',
            [
                { text: '', value: 'imgs', width: '80px' },
                { text: 'Nome', value: 'name.it', cellClass: 'sticky-left', class: 'sticky-left' }
            ],
            { spread: ['imgs'] }
        )
    ]
}
</script>