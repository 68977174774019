<template>
    <div class="material-form">
        <formizer
        ref="formizer"
        model="AttributeOption"
        undo="/attributs_options"
        :hide-footer="hideFooter"
        :hide-header="hideHeader"
        :id="id"
        @modified="$emit('modified', $event)"
        @draw-completed="drawCompleted"
        @submit="intSubmit">
            <template #header-toolbar="{ doc, method }">
                <v-btn
                text
                small
                plain
                @click="$router.go(-1)">
                    <v-icon left>chevron_left</v-icon>
                    <span>Indietro</span>
                </v-btn>

                <h1>{{ method === 'post' ? 'Aggiungi opzione attributo' : doc.name }}</h1>
            </template>

            <template #body="{ fields, rules, card }">
                <v-card v-bind="card">
                    <div class="mb-8">
                        <h2>Foto</h2>
                    </div>

                    <uploader
                    v-model="fields.img.model"
                    class="uploader-card" />
                </v-card>

                <v-card v-bind="card">
                    <div class="mb-8">
                        <h2>Informazioni base</h2>
                    </div>

                    <div>
                        <bxs-select-resouce-field
                        v-model="fields.attribute.model"
                        resource="attributes"
                        item-text="name"
                        required
                        outlined
                        disabled
                        label="Attributo"
                        form-title="attributo"
                        form-component="form-attribute" />

                        <v-text-field
                        v-model="fields.value.model"
                        label="* Valore"
                        required
                        dense
                        outlined
                        :rules="[rules.required]" />

                        <v-text-field
                        v-model="fields.icon.model"
                        label="Icona"
                        dense
                        outlined />

                        <v-text-field
                        v-model="fields.color_hex_1.model"
                        label="Colore 1"
                        dense
                        outlined />

                        <v-text-field
                        v-model="fields.color_hex_1.model"
                        label="Colore 2"
                        dense
                        outlined />
                    </div>
                </v-card>

                <slot />
            </template>
        </formizer>
    </div>
</template>

<script>
export default {
    name: 'form_attribute_option',
    props: {
        id: {
            type: String,
            required: false,
            default: null
        },
        'attribute-id': {
            type: String,
            required: false,
            default: null
        },
        'hide-footer': {
            type: Boolean,
            required: false,
            default: false
        },
        'hide-header': {
            type: Boolean,
            required: false,
            default: false
        }
    },
    methods: {
        drawCompleted (method, fieldObj, doc, next) {
            if (method === 'post') {
                this.$refs.formizer.valorize({
                    attribute: this.attributeId
                })
            }

            next()
        },
        submit () {
            return this.$refs.formizer.submit()
        },
        async intSubmit (method, model, body, id, isUndo, undo) {
            this.$emit('submit', method, model, body, id, isUndo, undo)
        }
    }
}
</script>