<template>
    <div>
        <v-card
        max-width="320"
        class="google-item mb-12 pa-3 truncated">
            <a
            v-if="local_fields.slug.it.model"
            :href="`${$api.enums.origins.app}/${slugify(local_fields.slug.it.model)}`"
            target="_blank"
            class="g-link ma-0"
            style="color: grey; text-decoration: none">{{ local_fields.permalink.it.model }}</a>

            <!--  -->
            <p class="g-title ma-0">
                <span v-if="local_fields.meta.title.it.model">{{ local_fields.meta.title.it.model }}</span>
                <span v-else>Titolo pagina</span>
            </p>

            <!--  -->
            <p class="g-desc ma-0">
                <span v-if="local_fields.meta.description.it.model">{{ local_fields.meta.description.it.model }}</span>
                <span v-else>Descrizione pagina.</span>
            </p>
        </v-card>

        <div>
            <v-text-field
            v-model="local_fields.slug.it.model"
            :disabled="!$api.auth.is_super"
            dense
            counter
            outlined
            label="Slug pagina" />

            <!-- canonical auto -->
            <!-- <v-text-field
            v-model="local_fields.meta.canonical.model"
            dense
            counter
            label="Canonical" /> -->

            <v-text-field
            v-model="local_fields.meta.title.it.model"
            dense
            counter
            outlined
            label="Titolo"
            maxlength="70" />

            <v-textarea
            v-model="local_fields.meta.description.it.model"
            label="Descrizione"
            counter
            auto-grow
            dense
            outlined
            maxlength="165" />
        </div>
    </div>
</template>

<script>
import { slugify } from '@/assets/libs/utils'

export default {
    name: 'seo-field',
    props: {
        fields: {
            type: Object,
            required: true
        },
        paths: {
            type: String,
            required: false,
            default: ''
        }
    },
    data () {
        return {
            slugify,
            local_fields: this.fields
        }
    }
}
</script>