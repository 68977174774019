<template>
    <div class="banners">
        <div class="mb-8">
            <h1>Banners</h1>
        </div>

        <v-card class="pa-0">
            <bxs-data-table
            ref="table"
            model-name="Banner"
            resource="banners"
            id="table-banners-view"
            to-create-item="/banners/new"
            to-update-item="/banners"
            :headers="[
                { text: '', value: 'imgs' },
                { text: 'Nome interno', value: 'name' },
                { text: 'Tipologia', value: 'type' },
                { text: 'Titolo', value: 'title.it' },
                { text: 'Sottotitolo', value: 'subtitle.it' },
                { text: 'Ordine', value: 'order' }
            ]">
                <template #item.order="{ item, value }">
                    <qty-field
                    :value="value"
                    hide-details
                    single-line
                    dense
                    solo
                    :outlined="false"
                    @change="$api.banners.modify(item.id, { order: $event })" />
                </template>
            </bxs-data-table>
        </v-card>
    </div>
</template>

<script>
export default {
    name: 'banners'
}
</script>