<template>
    <div class="dep-form">
        <formizer
        ref="formizer"
        model="Dep"
        undo="/deps"
        :hide-footer="hideFooter"
        :hide-header="hideHeader"
        :id="id"
        @modified="$emit('modified', $event)"
        @submit="intSubmit">
            <template #header-toolbar="{ fields }">
                <v-btn
                text
                small
                plain
                @click="$router.go(-1)">
                    <v-icon left>chevron_left</v-icon>
                    <span>Indietro</span>
                </v-btn>

                <h1>Categoria {{ fields.name.model }}</h1>
            </template>

            <template #body="{ fields, rules, card }">
                <v-card v-bind="card">
                    <div class="mb-8">
                        <h2>Foto cover</h2>
                    </div>

                    <uploader
                    ref="uploader"
                    v-model="fields.cover_img.model"
                    multiple
                    class="uploader-card" />
                </v-card>

                <v-card v-bind="card">
                    <div class="formizer--item--header">
                        <h2>Informazioni aggiuntive</h2>
                        <p>Informazioni legate alla scheda prodotto</p>
                    </div>

                    <v-select
                    v-model="fields.type.model"
                    :items="$api.enums.dep.types"
                    label="* Tipologia"
                    outlined
                    dense
                    :menu-props="{ top: false, bottom: true, offsetY: true }" />

                    <v-text-field
                    v-model="fields.name.model"
                    label="* Nome"
                    required
                    outlined
                    dense
                    :rules="[rules.required]" />

                    <product-category-field
                    v-model="fields.parent.model"
                    hide-create
                    label="Categoria genitore" />
                </v-card>

                <v-card v-bind="card">
                    <div class="mb-12">
                        <h3>Informazioni SEO</h3>
                    </div>

                    <seo-field :fields="fields" />
                </v-card>
            </template>
        </formizer>
    </div>
</template>

<script>
export default {
    name: 'form_dep',
    props: {
        id: {
            type: String,
            required: false,
            default: null
        },
        'hide-footer': {
            type: Boolean,
            required: false,
            default: false
        },
        'hide-header': {
            type: Boolean,
            required: false,
            default: false
        }
    },
    methods: {
        submit () {
            return this.$refs.formizer.submit()
        },
        async intSubmit (method, model, body, id, isUndo, undo) {
            this.$emit('submit', method, model, body, id, isUndo, undo)
        }
    }
}
</script>