<template>
    <div class="category-field">
        <p class="mb-2">{{ label }}</p>

        <!-- <v-skeleton-loader
        v-if="!preloaded"
        type="table-heading" /> -->

        <tree-select-field
        v-model="selected"
        :options="tree"
        :placeholder="placeholder"
        :name="'categories_' + Math.random()"
        :disabled="disabled"
        :required="required"
        no-results-text="Nessun elemento trovato"
        no-options-text="Nessun elemento disponibile al momento"
        no-children-text="Nessuna sottocategoria disponibile al momento"
        show-count
        :searchable="false"
        flat
        :multiple="multiple"
        :clearable="clearable">
            <template
            v-if="!hideCreate"
            #before-list>
                <v-divider></v-divider>
                <div>
                    <v-btn
                    text
                    block
                    color="info"
                    @click="setForm()">
                        <v-icon left>add</v-icon>
                        <span>Aggiungi nuova categoria</span>
                    </v-btn>
                </div>
                <v-divider></v-divider>
            </template>

            <template #value-label="{ node }">
                <span>{{ node.raw.path }}</span>
            </template>

            <template #option-label="{ node, shouldShowCount, count, labelClassName, countClassName }">
                <label :class="labelClassName">
                    <span>{{ node.raw.name }}</span>
                    <span
                    v-if="shouldShowCount"
                    :class="countClassName">({{ count }})</span>
                </label>
            </template>
        </tree-select-field>

        <!--  -->
        <bxs-modal
        v-model="form.on"
        :title="!form.id ? 'Aggiungi nuova categoria ' : 'Modifica categoria'">
            <form-dep
            ref="form"
            :key="form.on"
            :id="form.id"
            hide-header
            hide-footer
            @submit="submit" />

            <template #actions>
                <v-btn
                color="primary"
                @click="$refs.form.submit()">Salva</v-btn>
            </template>
        </bxs-modal>
    </div>
</template>

<script>
// import the component
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

import tree from '@/assets/libs/tree'

export default {
    name: 'product_category_field',
    components: {
        'tree-select-field': Treeselect
    },
    props: {
        value: {
            type: [String, Array],
            required: false,
            default: null
        },
        label: {
            type: String,
            required: false,
            default: 'Categoria/e'
        },
        placeholder: {
            type: String,
            required: false,
            default: 'Seleziona le categorie'
        },
        required: {
            type: Boolean,
            required: false,
            default: false
        },
        clearable: {
            type: Boolean,
            required: false,
            default: true
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        'hide-create': {
            type: Boolean,
            required: false,
            default: false
        },
        readonly: {
            type: Boolean,
            required: false,
            default: false
        },
        multiple: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data () {
        return {
            preloaded: false,
            loading: false,
            selected: this.value,
            //
            current: null,
            tree: [],
            //
            form: {
                on: null,
                id: null
            }
        }
    },
    async created () {
        await this.getItems()
        this.preloaded = true
    },
    watch: {
        value (newVal) {
            this.selected = newVal
        },
        selected () {
            this.$emit('input', this.selected)
        }
    },
    methods: {
        async getItems () {
            this.current = null
            this.loading = true
            const docs = await this.$api.deps.list({
                pagination: false,
                sort: { name: 1 },
                filters: {
                    type: 'category'
                }
            })
            this.tree = tree.view(docs)
            this.loading = false
            return docs
        },
        setForm (id) {
            this.form.id = id || null
            this.form.on = !this.form.on
        },
        async submit (method, model, body, id, undo, undoTo) {
            try {
                const doc_id = await this.$api.deps.save(id, body)

                this.getItems().then(() => {
                    if (this.multiple) this.selected.push(doc_id)
                    else this.selected = doc_id
                })

                this.setForm()
            } catch (err) {
                throw new Error(err.message || err.name || err)
            }
        }
    }
}
</script>

<style lang="scss">
.vue-treeselect {
    .vue-treeselect__control {
        min-height: 40px;
        border: 1px solid rgba(#000, 0.4);
        border-radius: 0.25rem;
        padding: 0 12px;

        &:focus {
            border: 1px solid var(--v-primary-base) !important;
        }

        .vue-treeselect__control-arrow-container {
            svg {
                fill: rgba(#000, 0.6);
            }
        }

        .vue-treeselect__placeholder {
            line-height: 39px;
            color: rgba(#000, 0.6);
            font-size: 1.25rem;
        }
    }

    .vue-treeselect__multi-value-item-container {
        .vue-treeselect__multi-value-item {
            background: #E0F2F1;
            padding: 0.25rem 0rem;
            font-size: 12px;

            span {
                color: #009688;
            }

            .vue-treeselect__value-remove {
                border-left-color: #fff;
            }
        }
    }

    .vue-treeselect__menu-container {
        font-size: 1rem;

        .vue-treeselect__menu {
            box-shadow: 0 0.1875rem 0.375rem -0.1875rem #17181814, 0 0.5rem 1.25rem -0.25rem #1718181f !important;
            padding-top: 0;
            padding-bottom: 1rem;
            font-size: 1rem;

            // .vue-treeselect__list-item {
            //     padding-top: 0.25rem;
            //     padding-bottom: 0.25rem;
            // }
        }
    }
}
</style>