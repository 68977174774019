<template>
    <div class="storage pb-12">
        <div v-if="!preloaded">
            <loading />
        </div>

        <!-- content -->
        <div v-else>
            <div
            v-if="!hideTitle"
            class="mb-8">
                <h1>Storage ({{ items.pagination.total_docs }})</h1>
            </div>

            <!-- header -->
            <v-toolbar class="mb-4">
                <span>
                    <v-btn
                    text
                    @click="selectAll">Seleziona tutti <span v-if="selected.length > 0" class="ml-1">({{ selected.length }})</span></v-btn>

                    <v-btn
                    v-if="selected_ids.length > 0"
                    icon
                    class="ml-4"
                    @click="deleteDocs(selected_ids)">
                        <v-icon>delete</v-icon>
                    </v-btn>
                </span>

                <v-spacer></v-spacer>

                <search-field
                v-model="keywords"
                append-icon="search"
                label="Cerca"
                solo
                dense
                clearable
                hide-details
                single-line
                style="max-width: 240px" />
            </v-toolbar>

            <div
            v-if="!items.docs.length"
            class="text-center">
                <v-icon
                size="120"
                class="mb-2">image</v-icon>
                <h1 class="mb-1">Storage</h1>
                <p>Nessuna immagine trovata al momento.</p>
            </div>

            <div v-else>
                <v-row>
                    <v-col
                    v-for="file in items.docs"
                    :key="file.id"
                    v-bind="cols"
                    class="mb-6">
                        <!-- @contextmenu="handleCtxMenu(file, $event)" -->
                        <v-card
                        hover
                        link
                        class="pa-0"
                        @click="setExpand(file)">
                            <v-img
                            :src="file.url || file.base64"
                            gradient="to bottom right, rgba(0, 0, 0, 0.4), rgba(0, 0, 0 , 0)"
                            aspect-ratio="1">
                                <div class="d-flex align-center ma-0 pa-0 pa-4">
                                    <v-chip
                                    v-if="file.is && file.is.used"
                                    hover
                                    small
                                    color="tertiary"
                                    class="mr-2">In uso</v-chip>

                                    <!-- <v-tooltip
                                    top
                                    color="tertiary">
                                        <template #activator="{ on }">
                                            <v-chip
                                            v-if="file.is && file.is.used"
                                            v-on="on"
                                            hover
                                            small
                                            color="tertiary"
                                            class="mr-2">In uso</v-chip>
                                        </template>
                                    </v-tooltip> -->

                                    <v-spacer></v-spacer>

                                    <v-checkbox
                                    :value="selected_ids.includes(file.id)"
                                    hide-details
                                    dark
                                    color="accent"
                                    class="d-inline-block ma-0 pa-0"
                                    @change="select(file)" />

                                    <!-- <v-btn
                                    small
                                    icon
                                    style="margin-top: -2px;"
                                    @click="setExpand(file)">
                                        <v-icon>open_in_new</v-icon>
                                    </v-btn> -->
                                </div>

                                <v-btn
                                v-if="!selected_ids.length"
                                icon
                                absolute
                                dark
                                bottom
                                right
                                @click="deleteDocs(file.id)">
                                    <v-icon>delete</v-icon>
                                </v-btn>
                            </v-img>
                        </v-card>

                        <!-- ------------------- -->
                        <div
                        class="d-flex mt-1"
                        @click="file.collapse = !file.collapse">
                            <div>
                                <p class="mb-0"><small>{{ file.name }}</small></p>
                                <small class="mb-0">{{ file.size.human }}</small>
                            </div>

                            <v-spacer></v-spacer>

                            <v-btn
                            icon
                            small>
                                <v-icon>expand_more</v-icon>
                            </v-btn>
                        </div>

                        <div
                        v-if="file.collapse"
                        class="mt-1">
                            <v-text-field
                            :value="file.alt && file.alt.it ? file.alt.it : null"
                            label="alt (testo alternativo)"
                            dense
                            outlined
                            filled
                            @change="$api.files.modify(file.id, { 'alt.it': $event })"></v-text-field>
                        </div>
                    </v-col>
                </v-row>

                <!-- <v-pagination
                v-model="items.pagination.page"
                :length="items.pagination.total_pages"
                total-visible="10"
                :disabled="loading"
                dense
                circle
                class="mt-4"
                @input="getItems" /> -->
            </div>
        </div>

        <!-- <v-dialog v-model="form.on">
            <v-card>
                <formizer
                ref="formizer"
                model="Dep"
                undo="/categories"
                :id="$route.params.id"
                @draw-completed="drawCompleted"
                @submit="intSubmit">
            </v-card>
        </v-dialog> -->
    </div>
</template>

<script>
import Loading from '../components/Loading.vue'
export default {
  components: { Loading },
    name: 'storage',
    props: {
        value: {
            type: [String, Array],
            required: false,
            default: null
        },
        'item-value': {
            type: String,
            required: false,
            default: 'id'
        },
        'return-object': {
            type: Boolean,
            required: false,
            default: false
        },
        'hide-title': {
            type: Boolean,
            required: false,
            default: false
        },
        cols: {
            type: Object,
            required: false,
            default: () => {
                return {
                    cols: 6,
                    md: 4,
                    lg: 3,
                    xl: 2
                }
            }
        },
        limit: {
            type: [String, Number],
            required: false,
            default: 20
        },
        'hide-details': {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data () {
        return {
            expand: {
                on: false,
                item: null
            },
            items: {
                docs: [],
                pagination: {
                    page: 1,
                    limit: this.limit,
                    totalPages: 0
                }
            },
            form: {
                on: false,
                id: null
            },
            //
            selected: [],
            keywords: null,
            //
            preloaded: false,
            loading: false,
            busy: false
        }
    },
    computed: {
        selected_ids () {
            return this.selected.map(i => i.id)
        }
    },
    mounted () {
        this.$nextTick(this.play)
    },
    watch: {
        keywords () {
            this.getItems(1, false)
        }
    },
    methods: {
        async play () {
            console.log('Storage play()')

            await this.getItems()
            this.preloaded = true

            window.addEventListener('scroll', this.handleScroll)
        },
        stop () {
            window.removeEventListener('scroll', this.handleScroll)
        },
        handleScroll () {
            if (
                (window.innerHeight + window.scrollY) >= (document.body.scrollHeight - (window.innerHeight / 2))
            ) {
                if (this.items.pagination.has_next_page && !this.busy) {
                    this.getItems(this.items.pagination.page += 1)
                }
            }
        },
        //
        async getItems (page = 1, infinite = true) {
            this.loading = true
            this.busy = true

            const docs = await this.$api.files.list({
                page: page,
                limit: this.items.pagination.limit,
                sort: { created_at: -1 },
                filters: {
                    keywords: this.keywords || null
                }
            })

            docs.docs.forEach((item) => {
                item.collapse = false
            })

            this.items.pagination = docs.pagination
            if (page <= 1 && !infinite) {
                this.items.docs = docs.docs
            } else {
                this.items.docs.push(...docs.docs)
            }

            this.loading = false
            this.busy = false

            return docs
        },
        async deleteDocs(ids, title, text) {
            console.log('Storage deleteDocs()')

            try {
                this.$store.commit('SET_APP_DIALOG_CONFIRM', {
                    title: title || 'Attenzione!',
                    text: text || 'Vuoi eliminare questa risorsa?',
                    next: async () => {
                        this.$store.commit('loading', true)
                        await this.$api.files.remove('many', ids)
                        this.$store.commit('loading', false)
                        this.selected = []
                        this.expand.on = false
                        this.getItems()
                    }
                })
            } catch (err) {
                console.error(err)
                alert(err.message || err.name)
            }
        },
        //
        setExpand (item) {
            console.log('Storage setExpand()')

            this.form.id = item.id || null
            this.form.on = !this.form.on
        },
        select (item) {
            console.log('Storage select()')

            if (this.selected_ids.includes(item.id)) {
                const i = this.selected.findIndex(i => i.id === item.id)
                this.selected.splice(i, 1)
            } else {
                this.selected.push(item)
            }

            this.$emit('select:item', JSON.parse(JSON.stringify(item)))
        },
        selectAll () {
            this.items.docs.forEach(this.select)
        },
        handleCtxMenu (file, evt) {
            evt.preventDefault()
            alert('success!')
            return false
        }
    },
    beforeDestroy () {
        this.stop()
    }
}
</script>