<template>
    <div
    :key="key"
    class="product-view boxed">
        <formizer
        ref="formizer"
        model="Store"
        undo="/stores"
        :id="$route.params.id"
        @submit="submit">
            <template #header-toolbar="{ doc, method }">
                <v-btn
                text
                small
                plain
                to="/stores">
                    <v-icon left>chevron_left</v-icon>
                    <span>Negozi</span>
                </v-btn>

                <h1 class="mb-0">{{ method === 'post' ? `Aggiungi` : 'Modifica' }} negozio</h1>
                <h3 v-if="doc && doc.name">{{ doc.name }}</h3>
            </template>

            <template #header-menu>
                <v-list-item to="/stores/new">Nuovo</v-list-item>
                <v-list-item>Anteprima</v-list-item>
                <v-list-item>Visualizza online</v-list-item>
            </template>

            <template #body="{ fields, card, rules }">
                <v-row>
                    <v-col cols="8">
                        <!-- ------------------------------------------------------------------------------------------------------------------------------------------------ -->
                        <v-card v-bind="card">
                            <div class="mb-8">
                                <h2>Aggiungi un immagine</h2>
                            </div>

                            <uploader
                            ref="uploader"
                            v-model="fields.img.model"
                            multiple />
                        </v-card>

                        <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
                            base info
                        ------------------------------------------------------------------------------------------------------------------------------------------------ -->
                        <v-card v-bind="card">
                            <div class="mb-8">
                                <h2>Informazioni base</h2>
                                <p>Informazioni di base necessarie per la creazione del negozio.</p>
                            </div>

                            <v-text-field
                            v-model="fields.name.model"
                            label="* Nome interno"
                            filled
                            dense
                            :rules="[rules.required]" />

                            <v-text-field
                            v-model="fields.title.it.model"
                            label="Titolo"
                            filled
                            dense />

                            <v-text-field
                            v-model="fields.subtitle.it.model"
                            label="Sottotitolo"
                            filled
                            dens />

                            <v-text-field
                            v-model="fields.gg_map.url.model"
                            label="Google maps link"
                            filled
                            dens />

                            <wysiwyg-field v-model="fields.description.it.model" />
                        </v-card>

                        <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
                            settings
                        ------------------------------------------------------------------------------------------------------------------------------------------------ -->
                        <v-card v-bind="card">
                            <div class="formizer--item--header">
                                <h2>Impostazioni</h2>
                            </div>

                            <v-row>
                                <v-col cols="6">
                                    <h4>Visibile in catalogo</h4>
                                </v-col>
                                <v-col
                                cols="6"
                                class="text-right">
                                    <v-switch
                                    v-model="fields.is_available.model"
                                    inset
                                    class="d-inline-block ma-0 pa-0" />
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>

                    <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
                        aside
                    ------------------------------------------------------------------------------------------------------------------------------------------------ -->
                    <v-col cols="4">
                        <v-card v-bind="card">
                            <div class="mb-8">
                                <h2>Impostazioni</h2>
                            </div>

                            <v-row>
                                <v-col cols="6">
                                    <h4>Visibile</h4>
                                </v-col>

                                <v-col
                                cols="6"
                                class="text-right">
                                    <v-switch
                                    v-model="fields.is_available.model"
                                    inset
                                    hide-details
                                    class="d-inline-block ma-0 pa-0" />
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
            </template>
        </formizer>
    </div>
</template>

<script>
export default {
    name: 'store',
    data () {
        return {
            key: 0
        }
    },
    methods: {
        async submit (method, model, body, id, isUndo, undo) {
            this.$store.commit('loading', true)

            try {
                const docId = await this.$api.stores.save(id, body)
                if (isUndo) return this.$router.push(undo)

                if (method === 'post') this.$router.push('/stores/' + docId)
                else this.key++
            } catch (err) {
                throw new Error(err.message || err.name)
            } finally {
                this.$store.commit('loading', false)
            }
        }
    }
}
</script>