<template>
    <div class="subs-view">
        <div class="mb-6">
            <h1>Iscritti newsletter</h1>
        </div>

        <bxs-data-table
        model-name="Sub"
        resource="subs"
        :headers="[
            { value: 'email' },
            { value: 'name' },
            { text: 'Data iscrizione', value: 'created_at' }
        ]">
            <template #prepend-toolbar>
                <bxs-btn
                tooltip="Scarica CSV"
                icon
                @click="downloadCsv">
                    <v-icon>download</v-icon>
                </bxs-btn>
            </template>
        </bxs-data-table>
    </div>
</template>

<script>
export default {
    name: 'subs',
    methods: {
        async downloadCsv () {
            this.$store.commit('loading', true)

            try {
                const doc = await this.$api.post('/v1/subs/csv')
                const blob = new Blob(['\ufeff', doc])
                const url = URL.createObjectURL(blob)
                const link = document.createElement('a')

                link.setAttribute('href', url)
                link.setAttribute('download', `tfork-iscritti-newsletter-${Date.now()}.csv`)
                document.body.appendChild(link)

                link.click()
            } catch (err) {
                console.log('subs downloadCsv() error', err)

                if (err === 'no_docs') {
                    return this.$toast.info('Nessuna lista da scaricare al momento')
                }

                throw new Error(err.message || err.name)
            } finally {
                this.$store.commit('loading', false)
            }
        }
    }
}
</script>