<template>
    <div class="orders">
        <div class="mb-8">
            <h1>Agenti</h1>
        </div>

        <bxs-data-table
        ref="table"
        model-name="Customer"
        resource="customers"
        id="table-customers-view"
        :headers="[
            { value: 'business_name' },
            { value: 'vat_code' },
            { value: 'email' },
            { value: 'phone' },
            { value: 'referente' },
            { value: 'sdi' },
            { value: 'pec' }
        ]"
        :static-filters="{
            role: 'agent'
        }">
            <template #prepend-toolbar>
                <bxs-btn
                icon
                tooltip="Aggiungi nuovo elemento"
                @click="setForm()">
                    <v-icon>add</v-icon>
                </bxs-btn>
            </template>

            <template #item-actions="{ id }">
                <v-list-item @click="setForm(id)">
                    <v-list-item-icon>
                        <v-icon>edit</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Modifica</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </bxs-data-table>

        <!-- forrm -->
        <v-navigation-drawer
        v-model="form.on"
        app
        right
        width="400">
            <v-card height="100%">
                <div class="d-flex align-center pa-3">
                    <h2 class="mb-0">Agente</h2>

                    <v-spacer></v-spacer>

                    <v-btn
                    fab
                    depressed
                    x-small
                    color="error"
                    @click="form.on = false">
                        <v-icon>close</v-icon>
                    </v-btn>
                </div>
                <v-divider></v-divider>

                <div class="px-6">
                    <form-user
                    ref="form"
                    :key="form.on"
                    hide-header
                    hide-footer
                    fluid
                    :id="form.id"
                    @submit="submit" />
                </div>

                <v-divider></v-divider>
                <v-card-actions class="pa-3">
                    <v-spacer></v-spacer>

                    <v-btn
                    text
                    @click="setForm()">Annulla e chiudi</v-btn>

                    <v-btn
                    color="primary"
                    @click="$refs.form.submit()">Salva</v-btn>
                </v-card-actions>
            </v-card>
        </v-navigation-drawer>
    </div>
</template>

<script>
export default {
    name: 'customers',
    data () {
        return {
            form: {
                on: false,
                id: null
            }
        }
    },
    methods: {
        setForm (id) {
            this.form.id = id || null
            this.form.on = !this.form.on
        },
        async submit (method, model, body, id) {
            this.$store.commit('loading', true)

            try {
                await this.$api.customers.save(id, body)
                this.setForm()
            } catch (err) {
                throw new Error(err.name || err.message)
            } finally {
                this.$store.commit('loading', false)
            }
        }
    }
}
</script>