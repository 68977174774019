var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"material-form"},[_c('formizer',{ref:"formizer",attrs:{"model":"Attribute","populate":"options","undo":"/attributs","hide-footer":_vm.hideFooter,"hide-header":_vm.hideHeader,"id":_vm.id},on:{"modified":function($event){return _vm.$emit('modified', $event)},"draw-completed":_vm.drawCompleted,"submit":_vm.intSubmit},scopedSlots:_vm._u([{key:"header-toolbar",fn:function(ref){
var doc = ref.doc;
var method = ref.method;
return [_c('v-btn',{attrs:{"text":"","small":"","plain":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("chevron_left")]),_c('span',[_vm._v("Indietro")])],1),_c('h1',[_vm._v(_vm._s(method === 'post' ? 'Aggiungi attributo' : doc.name))])]}},{key:"body",fn:function(ref){
var fields = ref.fields;
var rules = ref.rules;
var card = ref.card;
return [_c('v-card',_vm._b({},'v-card',card,false),[_c('div',{staticClass:"mb-8"},[_c('h2',[_vm._v("Informazioni base")])]),_c('div',[_c('v-text-field',{attrs:{"label":"* Nome interno","required":"","dense":"","outlined":"","rules":[rules.required]},model:{value:(fields.name.model),callback:function ($$v) {_vm.$set(fields.name, "model", $$v)},expression:"fields.name.model"}}),_c('v-text-field',{attrs:{"label":"Label","required":"","dense":"","outlined":""},model:{value:(fields.label.model),callback:function ($$v) {_vm.$set(fields.label, "model", $$v)},expression:"fields.label.model"}})],1)]),_c('v-card',_vm._b({},'v-card',card,false),[_c('div',{staticClass:"d-flex align-center mb-8"},[_c('h2',[_vm._v("Opzioni")]),_c('v-spacer'),_c('v-btn',{attrs:{"depressed":"","color":"info"},on:{"click":function($event){return _vm.addItem(null)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("add")]),_c('span',[_vm._v("Aggiungi")])],1)],1),_c('v-data-table',{staticClass:"table-edit mt-3",attrs:{"headers":_vm.headers,"items":_vm.items,"fixed-header":"","disable-filtering":"","disable-pagination":"","disable-sort":"","item-key":"id","footer-props":{ 'items-per-page-options': [-1] },"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.value",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"dense":"","hide-details":"","single-line":"","outlined":"","rules":[_vm.$api.enums.fields.rules.required]},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}})]}},{key:"item.id",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.items.length > 1)?_c('bxs-dot-menu',[(!item._deleted)?_c('v-list-item',{on:{"click":function($event){return _vm.removeItem(index)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("close")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Elimina")])],1)],1):_c('v-list-item',{on:{"click":function($event){item._deleted = false}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("refresh")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Ripristina")])],1)],1)],1):_vm._e()]}}],null,true)})],1),_vm._t("default")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }