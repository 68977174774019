<template>
    <div class="setting-profile-view">
        <div class="mb-8">
            <v-btn
            text
            small
            plain
            @click="$router.go(-1)">
                <v-icon left>chevron_left</v-icon>
                <span>Indietro</span>
            </v-btn>
            <h2>Account</h2>
            <v-divider class="my-4"></v-divider>
        </div>

        <bxs-data-lazy
        v-model="data_page"
        :promises="{
            me: $api.users.doc($api.auth.me.id),
            access: $api.auth.getAccess()
        }">
            <template #default>
                <v-card>
                    <div>
                        <v-chip>{{ data_page.me.status }}</v-chip>
                    </div>

                    <v-card-title>{{ data_page.me.name }}</v-card-title>
                    <v-card-subtitle>{{ data_page.me.email }}</v-card-subtitle>
                </v-card>

                <!-- <pre>
                    <code>{{ data_page.access }}</code>
                </pre> -->
            </template>

        </bxs-data-lazy>
    </div>
</template>

<script>
export default {
    name: 'settings_profile',
    data () {
        return {
            data_page: null
        }
    }
}
</script>