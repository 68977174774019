<template>
    <div class="product-variant">
        <div class="d-flex">
            <div>
                <h3 class="mb-0">{{ title }}</h3>
            </div>

            <v-spacer></v-spacer>

            <v-btn
            depressed
            @click="addItem(null)">
                <v-icon left>add</v-icon>
                <span>Aggiungi</span>
            </v-btn>
        </div>

        <v-data-table
        v-if="items.length > 0"
        :headers="headers"
        :items="items"
        fixed-header
        disable-filtering
        disable-pagination
        disable-sort
        item-key="id"
        :footer-props="{ 'items-per-page-options': [-1] }"
        hide-default-footer
        class="table-edit mt-3">
            <template #body="{ items }">
                <draggable
                :list="items"
                :ghost-class="items.length > 1 ? 'draggable-ghost' : null"
                :disabled="items.length <= 1"
                tag="tbody">
                    <tr
                    v-for="(item, index) in items"
                    :key="index">
                        <td>
                            <v-btn
                            v-if="items.length > 1"
                            icon
                            small
                            plain>
                                <v-icon>drag_indicator</v-icon>
                            </v-btn>
                        </td>

                        <!-- <td>
                            <uploader
                            v-model="item.imgs"
                            multiple />
                        </td> -->

                        <td>
                            <v-combobox
                            v-model="item['size.it']"
                            :items="$api.enums.products_sizes_it"
                            dense
                            hide-details
                            single-line
                            outlined
                            placeholder="* Taglia"
                            :rules="[$api.enums.fields.rules.required]" />
                        </td>

                        <!-- <td>
                            <v-combobox
                            v-model="item['color.it']"
                            :items="$api.enums.products_colors_it"
                            dense
                            hide-details
                            outlined
                            single-line
                            placeholder="* Colore"
                            :rules="[$api.enums.fields.rules.required]" />
                        </td> -->

                        <!-- <td>
                            <v-text-field
                            v-model="item.code"
                            dense
                            hide-details
                            outlined
                            single-line
                            placeholder="Codice" />
                        </td> -->

                        <td>
                            <v-text-field
                            v-model="item.sku"
                            dense
                            hide-details
                            outlined
                            single-line
                            placeholder="SKU" />
                        </td>

                        <!-- <td>
                            <v-text-field
                            v-model="item.mpn"
                            dense
                            hide-details
                            outlined
                            single-line
                            placeholder="MPN" />
                        </td> -->

                        <td>
                            <currency-field
                            v-model="item['prices.net']"
                            hide-details
                            single-line
                            placeholder="Prezzo lordo"
                            outlined
                            dense />
                        </td>

                        <td>
                            <v-btn
                            v-if="!item._deleted"
                            text
                            @click="removeItem(index)">
                                <v-icon>close</v-icon>
                            </v-btn>

                            <v-btn
                            v-else
                            text
                            @click="item._deleted = false">Annulla</v-btn>
                        </td>
                    </tr>
                </draggable>
            </template>
        </v-data-table>
    </div>
</template>

<script>
export default {
    name: 'product-variant-2',
    props: {
        value: {
            type: Array,
            required: false,
            default: () => []
        },
        method: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: false,
            default: 'Varianti'
        }
    },
    computed: {
        headers () {
            return [
                { text: '', value: '_drag_icon', width: '40px' },
                // { text: '', value: 'imgs', width: '120px' },
                { text: 'Taglia', value: 'size.it', width: '120px' },
                // { text: 'Colore', value: 'color.it', width: '120px' },
                // { text: 'Codice', value: 'code', width: '120px' },
                { text: 'SKU', value: 'sku', width: '120px' },
                // { text: 'MPN', value: 'mpn', width: '120px' },
                { text: 'Prezzo lordo', value: 'prices.net', width: '120px' },
                { text: '', value: 'id', width: '40px' }
            ]
        }
    },
    data () {
        return {
            oldItems: [],
            items: [],
            attributeActionType: null
        }
    },
    watch: {
        items: {
            deep: true,
            immediate: false,
            handler (newVal, oldVal) {
                this.$emit('input', newVal)
            }
        }
    },
    created () {
        this.oldItems = JSON.parse(JSON.stringify(this.value))

        // if (!this.value.length) {
        //     this.addItem()
        // }
        if (this.value.length > 0) {
            this.value.forEach(this.addItem)
        }
    },
    methods: {
        addItem (item) {
            const itemD = {
                'size.it': item ? item.size.it : null,
                // 'color.it': item ? item.color.it : null,
                'prices.net': item && item.prices ? item.prices.net : 0,
                // imgs: item ? item.imgs : [],
                // code: item ? item.code : null,
                // mpn: item ? item.mpn : null,
                sku: item ? item.sku : null,
                id: item ? item.id : null,
                _deleted: false,
                _is_new: !item
            }

            this.items.push(itemD)
        },
        removeItem (i) {
            const item = this.items[i]

            if (this.method === 'post' || (item && item._is_new)) {
                this.items.splice(i, 1)
            } else {
                if (this.oldItems.length > 0) item._deleted = true
                else this.items.splice(i, 1)
            }
        }
    }
}
</script>