<template>
    <div class="banner boxed">
        <formizer
        ref="formizer"
        model="Banner"
        undo="/banners"
        :id="$route.params.id"
        @submit="submit">
            <template #header-toolbar="{ doc, method }">
                <v-btn
                text
                small
                plain
                @click="$router.go(-1)">
                    <v-icon left>chevron_left</v-icon>
                    <span>Indietro</span>
                </v-btn>

                <h1 class="mb-0">{{ method === 'post' ? `Aggiungi` : 'Modifica' }} banner</h1>
                <h3 v-if="doc && doc.name">{{ doc.name }}</h3>
            </template>

            <template #body="{ fields, rules, card }">
                <v-row>
                    <v-col cols="8">
                        <v-card v-bind="card">
                            <div class="mb-8">
                                <h2>Foto e media</h2>
                            </div>

                            <uploader
                            ref="uploader"
                            v-model="fields.img.model" />
                        </v-card>

                        <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
                            base
                        ------------------------------------------------------------------------------------------------------------------------------------------------ -->
                        <v-card v-bind="card">
                            <div class="mb-8">
                                <h2>Informazioni base</h2>
                            </div>

                            <v-text-field
                            v-model="fields.name.model"
                            label="* Nome interno"
                            dense
                            outlined
                            :rules="[rules.required]" />

                            <v-text-field
                            v-model="fields.title.it.model"
                            label="Titolo"
                            dense
                            outlined />

                            <v-text-field
                            v-model="fields.subtitle.it.model"
                            label="Sottotitolo"
                            dense
                            outlined />
                        </v-card>

                        <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
                            link
                        ------------------------------------------------------------------------------------------------------------------------------------------------ -->
                        <v-card
                        v-bind="card"
                        class="px-0">
                            <div>
                                <div style="padding: 0 2rem;">
                                    <div class="mb-4">
                                        <h2>Link</h2>
                                    </div>

                                    <div>
                                        <v-checkbox
                                        v-model="fields.button.on.model"
                                        hide-details
                                        label="Questo banner ha un link"></v-checkbox>
                                    </div>
                                </div>

                                <v-divider
                                v-if="fields.button.on.model"
                                class="my-4"></v-divider>
                            </div>

                            <div
                            v-if="fields.button.on.model"
                            class="mt-4"
                            style="padding: 0 2rem;">
                                <v-text-field
                                v-model="fields.button.label.it.model"
                                label="Label bottone"
                                dense
                                outlined
                                required />

                                <link-field
                                v-model="fields.button.to.model"
                                required
                                :rules="[rules.required]" />
                            </div>
                        </v-card>
                    </v-col>

                    <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
                        aside
                    ------------------------------------------------------------------------------------------------------------------------------------------------ -->
                    <v-col cols="4">
                        <div class="sticky sticky-spacer-top">
                            <v-card class="mb-4">
                                <div class="mb-4">
                                    <p class="mb-2">Tipologia</p>
                                    <v-select
                                    v-model="fields.type.model"
                                    :items="$api.enums.banner.types"
                                    required
                                    dense
                                    outlined
                                    single-line
                                    hide-details
                                    :menu-props="{ top: false, bottom: true, offsetY: true }" />
                                </div>

                                <div>
                                    <p class="mb-2">Colonne</p>
                                    <qty-field
                                    v-model="fields.col.model"
                                    :min="1"
                                    :max="12"
                                    dense
                                    outlined />
                                </div>

                                <div>
                                    <p class="mb-2">Aspetto</p>
                                    <v-select
                                    v-model="fields.aspect_ratio.model"
                                    :items="$api.enums.banner.aspect_ratios"
                                    required
                                    dense
                                    outlined
                                    single-line
                                    hide-details
                                    :menu-props="{ top: false, bottom: true, offsetY: true }" />
                                </div>
                            </v-card>

                            <v-card>
                                <div class="mb-4">
                                    <h3>Impostazioni</h3>
                                </div>

                                <div class="d-flex align-center">
                                    <p class="ma-0 pa-0">Disponibile</p>
                                    <v-spacer></v-spacer>
                                    <v-switch
                                    v-model="fields.is_available.model"
                                    inset
                                    dense
                                    hide-details
                                    single-line
                                    class="d-inline ma-0 pa-0" />
                                </div>
                            </v-card>
                        </div>
                    </v-col>
                </v-row>
            </template>
        </formizer>
    </div>
</template>

<script>
export default {
    name: 'banner',
    methods: {
        async submit(method, model, body, id, is_undo, undo) {
            this.$store.commit('loading', true)

            try {
                const doc_id = await this.$api.banners.save(id, body)

                if (is_undo) {
                    if (undo) return this.$router.push(undo)
                    else return this.$router.go(-1)
                }

                if (method === 'post') {
                    this.$router.push('/banners/' + doc_id)
                }
            } catch (err) {
                throw new Error(err.message || err.name)
            } finally {
                this.$store.commit('loading', false)
            }
        }
    }
}
</script>