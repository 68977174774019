<template>
    <div class="contact-field">
        <v-autocomplete
        v-model="selected"
        :disabled="disabled"

        item-text="text"
        item-value="id"
        :cache-items="false"
        :items="items"
        return-object
        :dense="dense"

        :search-input.sync="keywords"

        :label="required ? '* ' + label : label"
        :placeholder="placeholder"

        :hide-selected="false"
        :hide-no-data="false"
        :no-filter="true"
        :multiple="multiple"

        :loading="loading"
        allow-overflow
        :hide-details="hideDetails"
        :clearable="clearable"
        :outlined="outlined"
        :filled="filled"
        :rules="rules"
        :flat="flat"
        :solo="solo"
        :menu-props="{ top: false, bottom: true, offsetY: true }"

        @change="handleChange">
            <template #selection="{ item }">{{ item.ragione_sociale }}</template>

            <template
            v-if="!hideCreate"
            #prepend-item>
                <v-list-item
                ripple
                @mousedown.prevent
                @click="setForm()">
                    <v-list-item-action>
                        <v-icon>add</v-icon>
                    </v-list-item-action>

                    <v-list-item-content>
                        <v-list-item-title>Nuovo {{ label }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>
            </template>

            <template #item="{ item }">
                <v-list-item-icon>
                    <v-icon>{{ item.grade === 'company' ? 'business' : 'person' }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>
                        <span>{{ item.ragione_sociale }}</span>
                    </v-list-item-title>

                    <v-list-item-subtitle>
                        <small>
                           <span v-if="item.grade === 'company'">P.IVA: {{ item.partita_iva || '-–' }}</span>
                           <span
                           v-else
                           class="text-uppercase">CF: {{ item.codice_fiscale || '--' }}</span>
                        </small>
                    </v-list-item-subtitle>

                    <v-list-item-subtitle>
                        <small>{{ item.billing_address.città }} ({{ item.billing_address.provincia }}) - {{ item.billing_address.indirizzo }}</small>
                    </v-list-item-subtitle>
                </v-list-item-content>
            </template>
        </v-autocomplete>

        <!--  -->
        <v-dialog
        v-model="contact.on"
        persistent
        right
        scrollable
        width="768">
            <v-card>
                <v-card-title>{{ !contact.id ? 'Nuovo' : 'Modifica' }} {{ type == 'customer' ? 'cliente' : 'fornitore' }}</v-card-title>
                <v-divider></v-divider>

                <v-card-text class="py-8">
                    <contact-form
                    ref="contact"
                    :id="contact.id"
                    hide-header
                    hide-footer
                    @submit="submitContact" />
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions class="pt-4">
                    <v-spacer></v-spacer>

                    <v-btn
                    text
                    @click="contact.on = null">Annulla</v-btn>

                    <v-btn
                    text
                    color="primary"
                    @click="$refs.contact.submit()">Salva</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import CustomerForm from '@/components/forms/CustomerForm.vue'

export default {
    name: 'contact-field',
    components: {
        'contact-form': CustomerForm
    },
    props: {
        value: {
            type: [String, Array],
            required: false,
            default: null
        },
        label: {
            type: String,
            required: false,
            default: ''
        },
        placeholder: {
            type: String,
            required: false,
            default: ''
        },
        required: {
            type: Boolean,
            required: false,
            default: false
        },
        dense: {
            type: Boolean,
            required: false,
            default: true
        },
        outlined: {
            type: Boolean,
            required: false,
            default: true
        },
        solo: {
            type: Boolean,
            required: false,
            default: false
        },
        filled: {
            type: Boolean,
            required: false,
            default: true
        },
        'hide-details': {
            type: Boolean,
            required: false,
            default: true
        },
        flat: {
            type: Boolean,
            required: false,
            default: true
        },
        clearable: {
            type: Boolean,
            required: false,
            default: false
        },
        rules: {
            type: Array,
            required: false,
            default: () => []
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        type: {
            type: String,
            required: false,
            default: null
        },
        'hide-create': {
            type: Boolean,
            required: false,
            default: false
        },
        multiple: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data () {
        return {
            isPrivate: true,
            selected: null,
            items: [],
            keywords: null,
            loading: false,

            contact: {
                on: null,
                id: 'new'
            }
        }
    },
    watch: {
        keywords (newVal) {
            this.getItems()
        },
        selected () {
            this.$emit('input', this.selected ? this.selected.id : null)
        }
    },
    created () {
        this.start()
    },
    methods: {
        async start () {
            if (this.value) {
                const item = await this.$api.contacts.doc(this.value)

                if (item) {
                    this.selected = {
                        ...item,
                        text: item.ragione_sociale
                    }
                    this.items = [this.selected]
                }
            }
        },
        async getItems () {
            if (!this.keywords || !this.keywords.length) {
                return
            }

            this.items = []
            this.loading = true
            const filters = {
                keywords: this.keywords
            }

            if (this.type) {
                filters.type = '=|' + this.type
            }

            try {
                const docs = await this.$api.contacts.list({
                    pagination: false,
                    filters: filters,
                    sort: {
                        ragione_sociale: 1,
                        cognome: 1
                    }
                })

                this.items = docs
            } catch (err) {
                console.log(err)
            } finally {
                this.loading = false
            }
        },
        setForm (id) {
            // this.contact.id = id || null
            this.contact.on = !this.contact.on
        },
        handleChange () {
            this.$emit('change', this.selected)
        },
        async submitContact (method, model, body, id, undo, undoTo) {
            try {
                const docId = await this.$api.contacts.save(id, {
                    ...body,
                    type: this.type
                })
                const doc = await this.$api.contacts.doc(docId)

                if (doc) {
                    this.selected = doc
                    const index = this.items.findIndex(i => i.id === this.selected.id)

                    if (index !== -1) {
                        this.items[index] = this.selected
                    } else {
                        this.items.push(this.selected)
                    }
                }

                this.contact.on = false
                this.contact.id = null

                this.handleChange()
            } catch (err) {
                throw new Error(err.message || err.name)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.contact-field {
    // display: block;
    // width: 100%;
    flex: 1 1 auto;
}
</style>