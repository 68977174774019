<template>
    <div class="wysiwyg-field mb-6">
        <div class="wysiwyg-field--editor">
            <div
            v-if="editor"
            class="wysiwyg-field--editor--header">
                <v-menu>
                    <template #activator="{ on, attrs }">
                        <bxs-btn
                        v-on="on"
                        v-bind="attrs">Paragrafo</bxs-btn>
                    </template>

                    <v-list>
                        <v-list-item
                        :color="editor.isActive('paragraph') ? 'success' : null"
                        icon
                        depressed
                        small
                        @click="editor.commands.setParagraph()">Paragrafo</v-list-item>

                        <v-list-item
                        v-for="n in 6"
                        :key="n"
                        :color="editor.isActive('heading', { level: n }) ? 'success' : null"
                        icon
                        depressed
                        small
                        @click="editor.commands.setHeading({ level: n })">Titolo {{ n }}</v-list-item>
                    </v-list>
                </v-menu>

                <v-divider
                vertical
                class="mx-2"></v-divider>

                <!-- :color="editor.isActive('bold') ? 'success' : null" -->
                <v-btn
                icon
                small
                depressed
                @click="editor.commands.toggleBold()">
                    <v-icon>format_bold</v-icon>
                </v-btn>

                <!-- :color="editor.isActive('italic') ? 'success' : null" -->
                <v-btn
                icon
                small
                depressed
                @click="editor.commands.toggleItalic()">
                    <v-icon>format_italic</v-icon>
                </v-btn>

                <!-- :color="editor.isActive('paragraph') ? 'success' : null" -->
                <v-btn
                icon
                small
                depressed
                @click="editor.commands.setParagraph()">
                    <v-icon>text_fields</v-icon>
                </v-btn>

                <v-divider
                vertical
                class="mx-2"></v-divider>

                <!--  -->
                <v-btn
                icon
                small
                :color="editor.isActive('BulletList') ? 'success' : null"
                depressed
                @click="editor.commands.toggleBulletList()">
                    <v-icon>format_list_bulleted</v-icon>
                </v-btn>

                <v-btn
                icon
                small
                :color="editor.isActive('OrderedList') ? 'success' : null"
                depressed
                @click="editor.commands.toggleOrderedList()">
                    <v-icon>format_list_numbered</v-icon>
                </v-btn>

                <v-btn
                icon
                small
                depressed
                @click="editor.commands.setHorizontalRule()">
                    <v-icon>horizontal_rule</v-icon>
                </v-btn>

                <v-divider
                vertical
                class="mx-2"></v-divider>

                <v-btn
                icon
                small
                depressed
                @click="editor.commands.setTextAlign('left')">
                    <v-icon>format_align_left</v-icon>
                </v-btn>

                <v-btn
                icon
                small
                depressed
                @click="editor.commands.setTextAlign('center')">
                    <v-icon>format_align_center</v-icon>
                </v-btn>

                <v-btn
                icon
                small
                depressed
                @click="editor.commands.setTextAlign('right')">
                    <v-icon>format_align_right</v-icon>
                </v-btn>

                <v-btn
                icon
                depressed
                small
                @click="editor.commands.unsetTextAlign()">
                    <v-icon>format_textdirection_r_to_l</v-icon>
                </v-btn>

                <v-divider
                vertical
                class="mx-2"></v-divider>

                <!--  -->
                <v-btn
                icon
                small
                depressed
                @click="handleEditorLink">
                    <v-icon>link</v-icon>
                </v-btn>

                <v-btn
                icon
                small
                depressed
                @click="editor.commands.unsetLink()">
                    <v-icon>link_off</v-icon>
                </v-btn>

                <v-divider
                vertical
                class="mx-2"></v-divider>

                <v-btn
                icon
                depressed
                small
                @click="editor.commands.undo()">
                    <v-icon>undo</v-icon>
                </v-btn>

                <v-btn
                icon
                depressed
                small
                @click="editor.commands.redo()">
                    <v-icon>redo</v-icon>
                </v-btn>
            </div>

            <!--  -->
            <div class="wysiwyg-field--editor--content">
                <editor-content :editor="editor" />
            </div>

            <!--  -->
            <div class="wysiwyg-field--editor--footer"></div>
        </div>

        <!-- links dialog -->
        <!-- <link-field /> -->
    </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Placeholder from '@tiptap/extension-placeholder'
import TextAlign from '@tiptap/extension-text-align'
import Link from '@tiptap/extension-link'

export default {
    name: 'wysiwyg-field',
    props: {
        value: {
            type: String,
            required: false,
            default: null
        },
        placeholder: {
            type: String,
            required: false,
            default: 'Descrizione'
        }
    },
    components: {
        EditorContent
    },
    data() {
        return {
            links: {
                on: false,
                keywords: null,
                items: {}
            },
            editor: null
        }
    },
    mounted () {
        this.$nextTick(() => {
            this.editor = new Editor({
                content: this.replaceBr(this.value),
                extensions: [
                    StarterKit,
                    Placeholder.configure({
                        placeholder: this.placeholder,
                        showOnlyWhenEditable: true
                    }),
                    TextAlign.configure({
                        types: ['heading', 'paragraph'],
                        alignments: ['left', 'center', 'right']
                    }),
                    Link.configure({
                        protocols: ['tel', 'mailto'],
                        autolink: true,
                        openOnClick: true,
                        linkOnPaste: true
                    })
                ],
                onUpdate: () => {
                    // HTML
                    // let html = this.editor.getHTML()
                    // html = this.insertBrTags(html)
                    // console.log('html', html)
                    this.$emit('input', this.insertBrTags(this.editor.getHTML()))

                    // JSON
                    // this.$emit('input', this.editor.getJSON())
                }
            })
        })
    },
    watch: {
        value(value) {
            // HTML
            const isSame = this.insertBrTags(this.editor.getHTML()) === value

            // JSON
            // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)
            if (isSame) return

            this.editor.commands.setContent(this.replaceBr(value), false)
        }
    },
    methods: {
        insertBrTags(str) {
            if (!str) return ''
            const P_REGEX = /(<p\s?((style=")([a-zA-Z0-9:;.\s()\-,]*)("))?>)(<\/p>)/g
            return str.replaceAll(P_REGEX, '<br class="t-editor-br">')
        },
        // removeBrTags(str) {
        //     const BR_REGEX = /(<p\s?((style=")([a-zA-Z0-9:;.\s()\-,]*)("))?>)<br>(<\/p>)/g
        //     return str.replaceAll(BR_REGEX, '$1$6')
        // },
        replaceBr (str) {
            if (!str) return ''
            return str.replaceAll('<br class="t-editor-br">', '<p><br class"ProseMirror-trailingBreak"></p>')
        },
        //
        handleEditorLink () {
            // from, to, empty
            const { from, to, empty } = this.editor.state.selection
            console.log(from, to, empty)
            if (empty) return
            this.links.on = true
        },
        async getLinks () {
            this.links.items = await this.$api.get('/v1/links', {
                keywords: this.links.keywords
            })
        },
        handleConfirmLink (link) {
            const { state } = this.editor

            if (state.selection.empty) {
                return alert('Seleziona una parola o frase per applicare il link')
            }

            this.editor.commands.setLink({
                href: link,
                target: '_self'
            })
            this.links.on = false
        }
    },
    beforeDestroy() {
        this.editor.destroy()
    }
}
</script>

<style lang="scss">
.wysiwyg-field {
    .ProseMirror {
        outline: none;
        min-height: 120px;

        // .ProseMirror-trailingBreak {
        //     display: none;
        // }

        ul,
        ol,
        li {
            list-style: initial;
        }
    }
}
</style>

<style lang="scss" scoped>
$wysiwyg-field-border-width: 1px;
$wysiwyg-field-border-color: rgba(0,0,0, 0.2);
$wysiwyg-field-border-radius: 0.35rem;
$editor-background-color: #fff;

.wysiwyg-field {
    display: block;
    position: relative;

    &--editor {
        display: flex;
        flex-direction: column;
        max-height: 26rem;
        // color: #0d0d0d;
        background-color: $editor-background-color;
        border: $wysiwyg-field-border-width solid $wysiwyg-field-border-color;
        border-radius: $wysiwyg-field-border-radius;

        &--header {
            display: flex;
            align-items: center;
            flex: 0 0 auto;
            flex-wrap: wrap;
            padding: 0.25rem;
            border-bottom: $wysiwyg-field-border-width solid $wysiwyg-field-border-color;
            background-color: rgb(#000, 0.05);
        }

        &--content {
            padding: 1.25rem 1rem;
            flex: 1 1 auto;
            overflow-x: hidden;
            overflow-y: auto;
        }

        &--footer {
            display: flex;
            flex: 0 0 auto;
            min-height: 20px;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            border-top: $wysiwyg-field-border-width solid $wysiwyg-field-border-color;
            font-size: 12px;
            font-weight: 600;
            // color: #0d0d0d;
            white-space: nowrap;
            padding: 0.25rem 0.75rem;
        }
    }
}
</style>