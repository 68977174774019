<template>
    <div class="address-field">
        <v-select
        v-model="selected"

        item-text="text"
        item-value="id"
        :items="items"

        :label="required ? '* ' + label : label"
        :placeholder="placeholder"

        return-object
        :disabled="disabled"
        :rules="rules"
        :multiple="multiple"
        :loading="loading"

        :dense="dense"
        :hide-details="hideDetails"
        :clearable="clearable"
        :outlined="outlined"
        :filled="filled"
        :flat="flat"
        :solo="solo"

        :menu-props="{ top: false, bottom: true, offsetY: true }"

        @change="handleChange">
            <template #selection="{ item }">
                <span class="py-1">{{ item.indirizzo }}, {{ item.città }} ({{ item.provincia }})</span>
            </template>

            <!--  -->
            <template
            v-if="!hideCreate"
            #prepend-item>
                <v-list-item
                ripple
                @mousedown.prevent
                @click="setForm()">
                    <v-list-item-content>
                        <v-list-item-title>Aggiungi Nuovo indirizzo</v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                        <v-icon>add</v-icon>
                    </v-list-item-action>
                </v-list-item>
                <v-divider></v-divider>

                <v-list-item @mousedown.prevent>
                    <v-list-item-content>
                        <v-text-field
                        v-model="keywords"
                        single-line
                        hide-details
                        prepend-inner-icon="search"
                        dense
                        solo
                        filled
                        clearable
                        label="Cerca indirizzo"
                        class="ma-0 pa-0" />
                    </v-list-item-content>
                </v-list-item>
                <!-- <v-divider></v-divider> -->
            </template>

            <!--  -->
            <template #item="{ item }">
                <v-list-item-content>
                    <v-list-item-title>{{ item.città }} ({{ item.provincia }})</v-list-item-title>
                    <v-list-item-subtitle>{{ item.indirizzo }}</v-list-item-subtitle>
                    <v-list-item-subtitle><small>{{ item.note }}</small></v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                    <v-btn
                    icon
                    @click="setForm(item.id)">
                        <v-icon>edit</v-icon>
                    </v-btn>
                </v-list-item-action>
            </template>
        </v-select>

        <!--  -->
        <bxs-modal
        v-model="form.on"
        :title="!form.id ? 'Nuovo indirizzo' : 'Modifica indirizzo'">
            <address-form
            ref="form"
            :id="form.id"
            :contact-id="contactId"
            hide-header
            hide-footer
            @submit="submit" />

            <template #actions>
                <v-btn
                color="primary"
                @click="$refs.form.submit()">Salva</v-btn>
            </template>
        </bxs-modal>
    </div>
</template>

<script>
import AddressForm from '@/components/forms/AddressForm.vue'

export default {
    name: 'address-field',
    components: {
        'address-form': AddressForm
    },
    props: {
        value: {
            type: String,
            required: false,
            default: null
        },
        type: {
            type: String,
            required: false,
            default: null
        },
        'contact-id': {
            type: [String, Boolean, Number],
            required: false,
            default: null
        },
        //
        label: {
            type: String,
            required: false,
            default: null
        },
        placeholder: {
            type: String,
            required: false,
            default: null
        },
        required: {
            type: Boolean,
            required: false,
            default: false
        },
        dense: {
            type: Boolean,
            required: false,
            default: true
        },
        outlined: {
            type: Boolean,
            required: false,
            default: true
        },
        filled: {
            type: Boolean,
            required: false,
            default: false
        },
        clearable: {
            type: Boolean,
            required: false,
            default: false
        },
        rules: {
            type: Array,
            required: false,
            default: () => []
        },
        'hide-details': {
            type: Boolean,
            required: false,
            default: true
        },
        solo: {
            type: Boolean,
            required: false,
            default: false
        },
        flat: {
            type: Boolean,
            required: false,
            default: true
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        'hide-create': {
            type: Boolean,
            required: false,
            default: false
        },
        multiple: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data () {
        return {
            isPrivate: true,
            selected: null,
            items: [],
            keywords: null,
            loading: false,

            form: {
                on: null,
                id: null
            }
        }
    },
    watch: {
        keywords (newVal) {
            this.getItems()
        },
        selected () {
            this.$emit('input', this.selected ? this.selected.id : null)
        },
        contactId () {
            this.start()
        }
    },
    created () {
        this.start()
    },
    methods: {
        async start () {
            console.log('address field start')

            if (this.contactId) {
                this.$api.addresses.list({
                    pagination: false,
                    sort: {
                        indirizzo: 1
                    },
                    filters: {
                        type: '=|' + this.type,
                        contact: '=|' + this.contactId
                    }
                }).then((docs) => {
                    this.items = docs
                })
            }

            if (this.value) {
                const item = await this.$api.addresses.doc(this.value)
                this.selected = item
                this.items = [this.selected]
            }
        },
        async getItems () {
            console.log('address field getItems')

            if (!this.keywords || !this.keywords.length) {
                return
            }

            this.items = []
            this.loading = true
            const filters = {
                keywords: this.keywords
            }

            if (this.type) {
                filters.type = '=|' + this.type
            }

            if (this.contactId) {
                filters.contact = '=|' + this.contactId
            }

            try {
                const docs = await this.$api.addresses.list({
                    pagination: false,
                    filters: filters,
                    sort: {
                        indirizzo: 1
                    }
                })

                this.items = docs
            } catch (err) {
                console.log(err)
            } finally {
                this.loading = false
            }
        },
        handleChange () {
            console.log('address field handleChange')
            this.$emit('change', this.selected)
        },
        setForm (id) {
            if (!this.contactId) {
                return alert('Seleziona la controparte!')
            }

            this.form.id = id || null
            this.form.on = !this.form.on
        },
        async submit (method, model, body, id, undo, undoTo) {
            if (!this.contactId) {
                return alert('Seleziona la controparte!')
            }

            console.log('address field submit', body)

            try {
                const docId = await this.$api.addresses.save(id, body)
                const doc = await this.$api.addresses.doc(docId)

                if (doc) {
                    this.selected = doc
                    const index = this.items.findIndex(i => i.id === this.selected.id)

                    if (index !== -1) {
                        this.items[index] = this.selected
                    } else {
                        this.items.push(this.selected)
                    }
                }

                this.form.id = null
                this.form.on = false
                this.handleChange()
            } catch (err) {
                throw new Error(err.message || err.name)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.address-field {
    // display: block;
    // width: 100%;
    flex: 1 1 auto;
}
</style>

<style lang="scss">
.address-field {
    .v-select__selections {
        input {
            display: none;
        }
    }
}
</style>