var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"orders"},[_vm._m(0),_c('bxs-data-table',{ref:"table",attrs:{"model-name":"Order","resource":"orders","id":"table-orders-view","headers":[
        { value: 'created_at' },
        { text: 'Numero', value: 'number_id' },
        { value: 'agent.email' },
        { text: 'Cliente', value: 'customer.business_name' },
        { text: 'Cliente P.IVA', value: 'customer.vat_code' },
        { text: 'Cliente authcode', value: 'customer_authcode' },
        { text: 'Descrizione/Oggetto', value: 'description' },
        { text: 'Ultima esportazione OS1', value: 'export_dates' },
        { text: 'Stato', value: 'status' },
        { text: 'Nr. Articoli', value: 'rows' },
        { text: 'Totale imponibile', value: 'cash.total_net' },
        { text: 'Totale lordo', value: 'cash.total_gross' }
    ],"static-query":{
        spread: ['agent.ref']
    }},scopedSlots:_vm._u([{key:"append-toolbar",fn:function(ref){
    var selected_ids = ref.selected_ids;
return [_c('v-btn',{staticClass:"ml-2",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.downloadExcellOS1()}}},[_vm._v("Scarica tutti gli ordini excel (OS1)")]),_c('v-btn',{staticClass:"ml-2",attrs:{"small":"","color":"primary","disabled":!selected_ids.length},on:{"click":function($event){return _vm.downloadExcellOS1(selected_ids)}}},[_vm._v("Scarica ordini selezionati excel (OS1)")])]}},{key:"item.rows",fn:function(ref){
    var value = ref.value;
return [_vm._v(_vm._s(value.length))]}},{key:"item.delivery.gross",fn:function(ref){
    var value = ref.value;
return [_vm._v(_vm._s(_vm._f("toHumanPriceN")(value)))]}},{key:"item.payment.discount",fn:function(ref){
    var value = ref.value;
return [_vm._v(_vm._s(value)+"%")]}},{key:"item.cash.total_net",fn:function(ref){
    var value = ref.value;
return [_vm._v(_vm._s(_vm._f("toHumanPriceN")(value)))]}},{key:"item.cash.total_gross",fn:function(ref){
    var value = ref.value;
return [_vm._v(_vm._s(_vm._f("toHumanPriceN")(value)))]}},{key:"item.export_dates",fn:function(ref){
    var value = ref.value;
return [(value && value.length > 0)?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(value[0]),expression:"value[0]"}]},[_vm._v(_vm._s(value[0]))]):_vm._e(),_c('span',[_vm._v("No")])]}},{key:"item.status",fn:function(ref){
    var value = ref.value;
return [_c('v-chip',{attrs:{"small":"","color":_vm.getStatusColor(value)}},[_vm._v(_vm._s(_vm._f("toHumanOrderStatus")(value)))])]}},{key:"item-actions",fn:function(ref){
    var item = ref.item;
return [_c('v-list-item',{attrs:{"to":'/orders/' + item.id}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Visualizza")])],1),_c('v-list-item-icon',[_c('v-icon',[_vm._v("chevron_right")])],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.print(item.id)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("print")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Stampa PDF")])],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.downloadExcellOS1([item.id], true)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("download")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Scarica excel")])],1)],1),(item.status !== 'confirmed')?_c('v-list-item',{on:{"click":function($event){return _vm.changeStatus(item, 'confirmed')}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("check")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Conferma")])],1)],1):_vm._e(),(item.status !== 'draft')?_c('v-list-item',{on:{"click":function($event){return _vm.changeStatus(item, 'draft')}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("reply")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Rimanda")])],1)],1):_vm._e(),_c('v-list-item',{on:{"click":function($event){return _vm.refreshCustomerAuthcode(item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("refresh")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Rigenera authcode cliente")])],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.sendAuthCodeToCustomer(item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("send")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Notifica authcode al cliente")])],1)],1),(item.status !== 'canceled')?_c('v-list-item',{on:{"click":function($event){return _vm.changeStatus(item, 'canceled')}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("cancel")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Annulla e cestina")])],1)],1):_vm._e()]}}])})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-8"},[_c('h1',[_vm._v("Commesse")])])}]

export { render, staticRenderFns }