import Auth from './Auth'
import File from './File'
import User from './User'
import History from './History'
import Product from './Product'
import Dep from './Dep'
import Banner from './Banner'
import Store from './Store'
import Sub from './Sub'
import Address from './Address'
import Customer from './Customer'
import Order from './Order'
import Setting from './Setting'

export const resources = {
    auth: {
        v: 'v1',
        path: 'auths',
        class: Auth
    },
    addresses: {
        v: 'v1',
        path: 'addresses',
        class: Address
    },
    customers: {
        v: 'v1',
        path: 'customers',
        class: Customer
    },
    orders: {
        v: 'v1',
        path: 'orders',
        class: Order
    },
    files: {
        v: 'v1',
        path: 'files',
        class: File
    },
    users: {
        v: 'v1',
        path: 'users',
        class: User
    },
    history: {
        v: 'v1',
        path: 'history',
        class: History
    },
    products: {
        v: 'v1',
        path: 'products',
        class: Product
    },
    deps: {
        v: 'v1',
        path: 'deps',
        class: Dep
    },
    banners: {
        v: 'v1',
        path: 'banners',
        class: Banner
    },
    stores: {
        v: 'v1',
        path: 'stores',
        class: Store
    },
    subs: {
        v: 'v1',
        path: 'subs',
        class: Sub
    },
    settings: {
        v: 'v1',
        path: 'settings',
        class: Setting
    }
}

export function genResources(config, tools) {
    for (const key in resources) {
        const resource = resources[key]
        const Class = resource.class
        resources[key] = new Class(config, tools, resource.v, resource.path)
    }

    return resources
}
