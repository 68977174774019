<template>
    <div class="customer">
        <formizer
        ref="formizer"
        model="Customer"
        :id="id"
        :hide-header="hideHeader"
        :hide-footer="hideFooter"
        @submit="intSubmit">
            <template #body="{ fields, rules }">
                <div class="sticky white py-3">
                    <v-chip-group
                    v-model="view"
                    mandatory
                    active-class="primary">
                        <v-chip
                        :outlined="view !== 'base'"
                        value="base">Informazioni base</v-chip>

                        <v-chip
                        :outlined="view !== 'billing_address'"
                        value="billing_address">Sede legale</v-chip>
                    </v-chip-group>
                </div>

                <!--  -->
                <div v-show="view === 'base'">
                    <v-text-field
                    v-model="fields.business_name.model"
                    label="* Denominazione"
                    dense
                    filled
                    :rules="[rules.required]" />

                    <v-text-field
                    v-model="fields.vat_code.model"
                    label="* Partita IVA / Codice fiscale"
                    dense
                    filled
                    :rules="[rules.required]" />

                    <v-text-field
                    v-model="fields.sdi.model"
                    label="* Codice SDI"
                    dense
                    filled
                    :rules="[rules.required]" />

                    <v-text-field
                    v-model="fields.billing_address.address.model"
                    label="* Indirizzo (Sede legale)"
                    filled
                    dense
                    :rules="[rules.required]" />

                    <v-text-field
                    v-model="fields.email.model"
                    label="* Email"
                    dense
                    filled
                    :rules="[rules.required]" />

                    <bxs-select-resouce-field
                    v-model="fields.agent.model"
                    resource="users"
                    :items-filters="{ role: 'agent' }"
                    item-text="name"
                    label="Agente"
                    required
                    dense
                    clearable
                    hide-edit
                    filled>
                        <template #item-content="{ item }">
                            <v-list-item-title>{{ item.name }}</v-list-item-title>
                            <v-list-item-subtitle>{{ item.email }}</v-list-item-subtitle>
                        </template>
                    </bxs-select-resouce-field>

                    <v-text-field
                    v-model="fields.pec.model"
                    label="Email (PEC)"
                    dense
                    filled />

                    <v-text-field
                    v-model="fields.phone.model"
                    label="Telefono"
                    dense
                    filled />

                    <v-text-field
                    v-model="fields.reference.model"
                    label="Contatto di riferimento"
                    dense
                    filled />

                    <v-text-field
                    v-model="fields.note.model"
                    label="Note"
                    dense
                    filled />
                </div>

                <!--  -->
                <div v-show="view === 'billing_address'">
                    <v-text-field
                    v-model="fields.billing_address.address.model"
                    label="Via"
                    filled
                    dense />

                    <v-combobox
                    v-model="fields.billing_address.city.model"
                    label="Città"
                    filled
                    dense
                    :items="$api.enums.address_cities" />

                    <v-combobox
                    v-model="fields.billing_address.province.model"
                    label="Provincia / Regione"
                    :items="$api.enums.address_provinces"
                    filled
                    dense />

                    <v-combobox
                    v-model="fields.billing_address.zip_code.model"
                    label="CAP"
                    dense
                    :return-object="false"
                    :items="$api.enums.address_caps"
                    filled />

                    <v-combobox
                    v-model="fields.billing_address.nation.model"
                    :items="$api.enums.address.nations"
                    dense
                    :return-object="false"
                    label="Nazione"
                    filled
                    :menu-props="{ top: false, bottom: true, offsetY: true }" />

                    <v-text-field
                    v-model="fields.billing_address.note.model"
                    label="Note indirizzo"
                    dense
                    filled />
                </div>
            </template>
        </formizer>
    </div>
</template>

<script>
export default {
    name: 'customer',
    props: {
        'hide-header': {
            type: Boolean,
            required: false,
            default: false
        },
        'hide-footer': {
            type: Boolean,
            required: false,
            default: false
        },
        id: {
            type: [String, Number, Boolean],
            required: false,
            default: null
        }
    },
    data () {
        return {
            view: 'base'
        }
    },
    methods: {
       submit () {
            return this.$refs.formizer.submit()
        },
        async intSubmit (method, model, body, id, isUndo, undo) {
            this.$emit('submit', method, model, body, id, isUndo, undo)
        }
    }
}
</script>
