var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"orders"},[_vm._m(0),_c('bxs-data-table',{ref:"table",attrs:{"model-name":"Customer","resource":"customers","id":"table-customers-view","headers":[
        { value: 'business_name' },
        { value: 'vat_code' },
        { value: 'email' },
        { value: 'phone' },
        { value: 'referente' },
        { value: 'sdi' },
        { value: 'pec' }
    ],"static-filters":{
        role: 'agent'
    }},scopedSlots:_vm._u([{key:"prepend-toolbar",fn:function(){return [_c('bxs-btn',{attrs:{"icon":"","tooltip":"Aggiungi nuovo elemento"},on:{"click":function($event){return _vm.setForm()}}},[_c('v-icon',[_vm._v("add")])],1)]},proxy:true},{key:"item-actions",fn:function(ref){
    var id = ref.id;
return [_c('v-list-item',{on:{"click":function($event){return _vm.setForm(id)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("edit")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Modifica")])],1)],1)]}}])}),_c('v-navigation-drawer',{attrs:{"app":"","right":"","width":"400"},model:{value:(_vm.form.on),callback:function ($$v) {_vm.$set(_vm.form, "on", $$v)},expression:"form.on"}},[_c('v-card',{attrs:{"height":"100%"}},[_c('div',{staticClass:"d-flex align-center pa-3"},[_c('h2',{staticClass:"mb-0"},[_vm._v("Agente")]),_c('v-spacer'),_c('v-btn',{attrs:{"fab":"","depressed":"","x-small":"","color":"error"},on:{"click":function($event){_vm.form.on = false}}},[_c('v-icon',[_vm._v("close")])],1)],1),_c('v-divider'),_c('div',{staticClass:"px-6"},[_c('form-user',{key:_vm.form.on,ref:"form",attrs:{"hide-header":"","hide-footer":"","fluid":"","id":_vm.form.id},on:{"submit":_vm.submit}})],1),_c('v-divider'),_c('v-card-actions',{staticClass:"pa-3"},[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.setForm()}}},[_vm._v("Annulla e chiudi")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$refs.form.submit()}}},[_vm._v("Salva")])],1)],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-8"},[_c('h1',[_vm._v("Agenti")])])}]

export { render, staticRenderFns }