<template>
    <div
    :key="key"
    class="product-view boxed">
        <formizer
        ref="formizer"
        model="Product"
        undo="/products"
        populate="variant_products"
        :id="$route.params.id"
        @submit="submit">
            <template #header-toolbar="{ doc, method }">
                <v-btn
                text
                small
                plain
                to="/products">
                    <v-icon left>chevron_left</v-icon>
                    <span>Prodotti</span>
                </v-btn>

                <h1 class="mb-0">{{ method === 'post' ? `Aggiungi` : 'Modifica' }} prodotto</h1>
                <h3 v-if="doc && doc.name">{{ doc.name.it }}</h3>
            </template>

            <template #header-menu="{ doc, method }">
                <v-list-item to="/products/new">Nuovo</v-list-item>
                <v-list-item
                v-if="method === 'put'"
                :href="doc.permalink.it"
                target="_blank">Visualizza in shop</v-list-item>
            </template>

            <template #body="{ fields, card, rules, method, id, doc }">
                <v-row>
                    <v-col cols="8">
                        <!-- ------------------------------------------------------------------------------------------------------------------------------------------------ -->
                        <v-card v-bind="card">
                            <div class="mb-8">
                                <h2>Foto e media</h2>
                            </div>

                            <uploader
                            ref="uploader"
                            v-model="fields.imgs.model"
                            multiple />
                        </v-card>

                        <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
                            base info
                        ------------------------------------------------------------------------------------------------------------------------------------------------ -->
                        <v-card v-bind="card">
                            <div class="mb-8">
                                <h2>Informazioni base</h2>
                                <p>Informazioni di base necessarie per la creazione del prodotto.</p>
                            </div>

                            <v-text-field
                            v-model="fields.name.it.model"
                            label="* Nome"
                            filled
                            dense
                            :rules="[rules.required]" />

                            <v-text-field
                            v-if="$api.auth.is_agency"
                            v-model="fields.code.model"
                            label="Codice interno"
                            filled
                            dense />

                            <v-text-field
                            v-model="fields.catalog_code.model"
                            label="Codice catalogo"
                            filled
                            dense />

                            <v-text-field
                            v-model="fields.sku.model"
                            label="Codice SKU"
                            filled
                            dense />

                            <v-text-field
                            v-model="fields.mpn.model"
                            label="Codice MPN"
                            filled
                            dense />

                            <dep-field
                            v-if="!fields.is_variant.model"
                            v-model="fields.categories.model"
                            label="Categorie"
                            type="category"
                            required
                            multiple
                            label-add="Nuova categoria"
                            :rules="[rules.requiredArr]" />
                        </v-card>

                        <!--  ------------------------------------------------------------------------------------------------------------------------------------------------
                            product details
                        ------------------------------------------------------------------------------------------------------------------------------------------------ -->
                        <v-card v-bind="card">
                            <div class="mb-8">
                                <h2>Informazioni aggiuntive</h2>
                                <p>Informazioni legate alla scheda prodotto</p>
                            </div>

                            <v-row>
                                <v-col cols="6">
                                    <v-combobox
                                    v-model="fields.color.it.model"
                                    :items="$api.enums.products_colors_it"
                                    label="Nome colore"
                                    filled
                                    dense />
                                </v-col>

                                <v-col cols="6">
                                    <div class="pl-3">
                                        <v-color-picker
                                        v-model="color_model"
                                        dot-size="22"
                                        hide-canvas
                                        hide-inputs
                                        mode="hexa"
                                        show-swatches
                                        width="100%"
                                        swatches-max-height="120"
                                        class="ma-0 pa-0 mb-6" />
                                    </div>
                                </v-col>
                            </v-row>

                            <v-textarea
                            v-model="fields.short_description.it.model"
                            label="Descrizione breve"
                            filled
                            dense
                            counter
                            maxlength="175" />

                            <!-- <v-textarea
                            v-model="fields.description.it.model"
                            label="Descrizione"
                            filled
                            dense
                            counter /> -->
                            <wysiwyg-field v-model="fields.description.it.model" />

                            <!-- <v-text-field
                            v-model="fields.package.it.model"
                            label="Packaging"
                            filled
                            dense /> -->

                            <!-- <div>
                                <v-row>
                                    <v-col cols="6">
                                        <h4>Dettagli aggiuntivi</h4>
                                    </v-col>

                                    <v-col
                                    cols="6"
                                    class="text-right">
                                        <v-switch
                                        v-model="fields.details.on.model"
                                        inset
                                        class="d-inline-block ma-0 pa-0" />
                                    </v-col>
                                </v-row>

                                <product-details-field
                                v-if="fields.details.on.model"
                                v-model="fields.details.value.model"
                                class="mt-3" />
                            </div> -->
                        </v-card>

                        <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
                            tags
                        ------------------------------------------------------------------------------------------------------------------------------------------------ -->
                        <v-card v-bind="card">
                            <div class="mb-8">
                                <h2>Categoria e tag</h2>
                            </div>

                            <dep-field
                            v-if="!fields.is_variant.model"
                            v-model="fields.categories.model"
                            label="Categoria/e"
                            type="category"
                            required
                            multiple
                            label-add="Nuova categoria"
                            :rules="[rules.requiredArr]" />

                            <!-- <dep-field
                            v-if="!fields.is_variant.model"
                            v-model="fields.brands.model"
                            label="Brand"
                            type="brand"
                            label-add="Nuovo brand" /> -->

                            <comma-to-list-field
                            v-model="fields.labels.model"
                            label="Etichette"
                            placeholder="Scrivi il valore"
                            :suggestions="$api.enums.products_labels" />
                        </v-card>

                        <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
                            prices
                        ------------------------------------------------------------------------------------------------------------------------------------------------ -->
                        <v-card
                        v-if="$api.auth.is_agency"
                        v-bind="card">
                            <div class="mb-8">
                                <h2>Prezzo e sconto</h2>
                            </div>

                            <currency-field
                            v-model="fields.prices.net.model"
                            label="Prezzo netto"
                            suffix="€" />

                            <currency-field
                            v-model="fields.prices.discount.model"
                            label="Sconto"
                            suffix="%"
                            :currency="null" />
                        </v-card>

                        <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
                            sizes
                        ------------------------------------------------------------------------------------------------------------------------------------------------ -->
                        <v-card
                        v-if="!fields.is_variant.model"
                        v-bind="card">
                            <div class="mb-8">
                                <h2>Varianti</h2>
                            </div>

                            <variants
                            v-model="fields.variant_products.model"
                            :method="method"
                            title="Taglie" />
                        </v-card>

                        <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
                            relateds
                        ------------------------------------------------------------------------------------------------------------------------------------------------ -->
                        <v-card
                        v-if="$api.auth.is_agency"
                        v-bind="card">
                            <div class="mb-8">
                                <h2>Correlati e famiglia</h2>
                            </div>

                            <select-order-field
                            v-model="fields.related_products.model"
                            resource="products"
                            item-text="name.it"
                            :query-sort="{ 'name.it': 1 }"
                            :exclude="[id]"
                            :query-filters="{ is_variant: '=|false' }">
                                <template #toolbar>
                                    <div>
                                        <h4 class="mb-0">Prodotti correlati</h4>
                                        <p>Prodotti simili o che potrebbero piacere.</p>
                                    </div>
                                </template>
                            </select-order-field>

                            <select-order-field
                            v-model="fields.family_products.model"
                            resource="products"
                            item-text="name.it"
                            :query-sort="{ 'name.it': 1 }"
                            :exclude="[id]"
                            :query-filters="{ is_variant: '=|false' }">
                                <template #toolbar>
                                    <div>
                                        <h4 class="mb-0">Prodotti famiglia</h4>
                                        <p>Prodotti dello stesso modello.</p>
                                    </div>
                                </template>
                            </select-order-field>
                        </v-card>

                        <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
                            settings
                        ------------------------------------------------------------------------------------------------------------------------------------------------ -->
                        <v-card v-bind="card">
                            <div class="mb-8">
                                <h2>Impostazioni</h2>
                            </div>

                            <v-row class="mb-4">
                                <v-col cols="6">
                                    <h4>Disponibile</h4>
                                </v-col>
                                <v-col
                                cols="6"
                                class="text-right">
                                    <v-switch
                                    v-model="fields.is_available.model"
                                    inset
                                    class="d-inline-block ma-0 pa-0" />
                                </v-col>
                            </v-row>

                            <v-row class="mb-4">
                                <v-col cols="6">
                                    <h4>Visibile in catalogo e ricerca</h4>
                                </v-col>
                                <v-col
                                cols="6"
                                class="text-right">
                                    <v-switch
                                    v-model="fields.is_visible.model"
                                    inset
                                    class="d-inline-block ma-0 pa-0" />
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="6">
                                    <h4>In evidenza</h4>
                                </v-col>

                                <v-col
                                cols="6"
                                class="text-right">
                                    <v-switch
                                    v-model="fields.is_highlighted.model"
                                    inset
                                    class="d-inline-block ma-0 pa-0" />
                                </v-col>
                            </v-row>
                        </v-card>

                        <!-- seo -->
                        <v-card v-bind="card">
                            <div class="mb-12">
                                <h2>Informazioni SEO</h2>
                            </div>

                            <seo-field
                            :fields="fields"
                            :paths="'/' + $api.enums.origins.paths.product" />
                        </v-card>
                    </v-col>

                    <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
                        aside
                    ------------------------------------------------------------------------------------------------------------------------------------------------ -->
                    <v-col cols="4">
                        <div class="sticky sticky-spacer-top">
                            <page-details
                            v-if="doc"
                            :src="doc && doc.imgs && doc.imgs.length > 0 ? doc.imgs[0].url : '/img/no-photo.png'"
                            :permalink="doc.permalink.it"
                            :title="doc.meta.title.it"
                            :description="doc.meta.description.it" />

                            <v-card class="mb-4">
                                <div class="mb-2">
                                    <p class="mb-1">Codice SKU</p>
                                    <v-text-field
                                    v-model="fields.sku.model"
                                    single-line
                                    dense
                                    hide-details
                                    outlined
                                    class="ma-0 pa-0" />
                                </div>

                                <div>
                                    <p class="mb-1">Codice produttore (MPN o altro)</p>
                                    <v-text-field
                                    v-model="fields.mpn.model"
                                    single-line
                                    hide-details
                                    dense
                                    outlined
                                    class="ma-0 pa-0" />
                                </div>
                            </v-card>

                            <v-card>
                                <div class="mb-4">
                                    <h3>Impostazioni</h3>
                                </div>

                                <div class="d-flex align-center mb-2">
                                    <p class="ma-0 pa-0">Disponibile</p>
                                    <v-spacer></v-spacer>
                                    <v-switch
                                    v-model="fields.is_available.model"
                                    inset
                                    dense
                                    single-line
                                    hide-details
                                    class="d-inline ma-0 pa-0" />
                                </div>

                                <div class="d-flex align-center">
                                    <p class="ma-0 pa-0">Visibile</p>
                                    <v-spacer></v-spacer>
                                    <v-switch
                                    v-model="fields.is_visible.model"
                                    inset
                                    dense
                                    single-line
                                    hide-details
                                    class="d-inline ma-0 pa-0" />
                                </div>
                            </v-card>
                        </div>
                    </v-col>
                </v-row>
            </template>
        </formizer>
    </div>
</template>

<script>
import Variants from '@/components/ProductVariant.vue'

export default {
    name: 'product',
    components: {
        variants: Variants
    },
    computed: {
        color_model: {
            get () {
                return this.color ? this.color.hex : null
            },
            set (v) {
                this.color = v ? v.hex : null
            }
        }
    },
    data () {
        return {
            color: null,
            key: 0
        }
    },
    watch: {
        color (newVal) {
            this.$refs.formizer.valorize({
                'color.hex': newVal
            })
        }
    },
    methods: {
        async submit (method, model, body, id, isUndo, undo) {
            this.$store.commit('loading', true)

            try {
                const docId = await this.$api.products.save(id, body)
                if (isUndo) return this.$router.push(undo)

                if (method === 'post') this.$router.push('/products/' + docId)
                else this.key++
            } catch (err) {
                throw new Error(err.message || err.name)
            } finally {
                this.$store.commit('loading', false)
            }
        }
    }
}
</script>