<template>
    <div>
        <v-app-bar
        app
        fixed
        color="#fff"
        clipped-right
        clipped-left
        height="60">
            <v-img
            src="/img/logo.jpg"
            max-width="40" />

            <v-app-bar-nav-icon
            class="ml-3"
            @click="$store.commit('set_is_open_menu')"></v-app-bar-nav-icon>

            <v-spacer></v-spacer>

            <v-chip
            v-if="$api.env === 'development'"
            class="mr-4">{{ $api.auth.me.role }} - {{ $api.auth.me.level }}</v-chip>

            <bxs-btn
            tooltip="Notifiche"
            icon>
                <v-icon>notifications</v-icon>
            </bxs-btn>

            <!-- <bxs-btn
            tooltip="Informazioni"
            icon>
                <v-icon>help</v-icon>
            </bxs-btn> -->

            <!-- menu -->
            <v-menu
            offset-y
            disable-keys
            nudge-bottom="10">
                <template #activator="{ on, attrs }">
                    <v-btn
                    v-on="on"
                    v-bind="attrs"
                    height="40"
                    color="rgba(0,0,0,0)"
                    elevation="0"
                    x-small
                    class="px-1 ml-1">
                        <v-avatar
                        color="primary"
                        size="30"
                        class="text-uppercase white--text">
                            <v-img v-if="$api.auth.me.img" :src="$api.auth.me.img" />
                            <span v-else>{{ $api.auth.me ? $api.auth.me.email[0] : '' }}</span>
                        </v-avatar>

                        <!-- <span class="ml-2">{{ $api.auth.me.name }} - {{ $api.auth.me.role }}</span> -->
                    </v-btn>
                </template>

                <v-list
                dense
                subheader>
                    <!-- <v-list-item
                    dense
                    :disabled="$api.env !== 'development'">
                        <v-list-item-icon>
                            <v-icon :disabled="$api.env !== 'development'">account_circle</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>Aaccount</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item> -->

                    <v-list-item
                    v-if="$api.auth.is_admin"
                    dense
                    :disabled="$api.env !== 'development'">
                        <v-list-item-icon>
                            <v-icon :disabled="$api.env !== 'development'">settings</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>Impostazioni</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-divider></v-divider>

                    <v-list-item
                    dense
                    @click="signout">
                        <v-list-item-icon>
                            <v-icon>logout</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Esci</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-app-bar>
    </div>
</template>

<script>
export default {
    name: 'navbar',
    props: {
        notifications: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    methods: {
        async signout () {
            this.$store.commit('loading', true)

            try {
                await this.$api.auth.signout()
                this.$router.go()
            } catch (err) {
                throw new Error(err.message || err.name)
            }
        }
    }
}
</script>