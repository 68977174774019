<template>
    <!-- :search-input.sync="keywords" -->
    <!-- :clearable="clearable" -->
    <!-- :no-filter="true" -->
    <div :class="['select-resource-field mb-4', { 'mb-6': !hideDetails }]">
        <v-select
        v-model="selected"

        :item-text="itemText"
        :item-value="itemValue"
        :items="items"

        :label="required ? '* ' + label : label"
        :placeholder="placeholder"

        :return-object="returnObject"
        :disabled="disabled"
        :rules="rules"
        :multiple="multiple"
        :loading="loading"

        :dense="dense"
        :hide-details="hideDetails"
        :clearable="clearable"
        :outlined="outlined"
        :filled="filled"
        :flat="flat"
        :solo="solo"
        :single-line="singleLine"
        :readonly="readonly"

        :menu-props="{ top: false, bottom: true, offsetY: true }"

        @change="handleChange">
            <template #selection="{ item, index }">
                <span
                v-if="!multiple"
                class="py-1">{{ dot.pick(itemText, item) }}</span>

                <span
                v-else-if="multiple && !index"
                class="py-1">{{ selected.length }} {{ multiple && selected.length > 1 ? 'elementi selezionati' : 'elemento selezionato' }}</span>
            </template>

            <!--  -->
            <template
            v-if="!hideCreate"
            #prepend-item>
                <v-list-item
                v-if="formComponent"
                ripple
                @mousedown.prevent
                @click="setForm()">
                    <v-list-item-content>
                        <v-list-item-title>Aggiungi nuova/o {{ formTitle }}</v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                        <v-icon>add</v-icon>
                    </v-list-item-action>
                </v-list-item>
                <v-divider v-if="formComponent"></v-divider>

                <v-list-item
                class="sticky white"
                @mousedown.prevent>
                    <v-list-item-content>
                        <v-text-field
                        v-model="keywords"
                        single-line
                        hide-details
                        prepend-inner-icon="search"
                        dense
                        filled
                        clearable
                        label="Cerca"
                        class="ma-0 pa-0" />
                    </v-list-item-content>
                </v-list-item>
            </template>

            <!--  -->
            <template #item="{ item }">
                <slot
                name="item-icon"
                :item="item" />

                <slot
                name="item-avatar"
                :item="item" />

                <v-list-item-action v-if="!$scopedSlots['item-icon'] && multiple">
                    <v-icon>{{ selected && selected.includes(item.id) ? 'check_box' : 'check_box_outline_blank' }}</v-icon>
                </v-list-item-action>

                <v-list-item-content>
                    <v-list-item-title
                    v-if="!$scopedSlots['item-content']"
                    v-html="dot.pick(itemText, item)"></v-list-item-title>

                    <slot
                    name="item-content"
                    :item="item" />
                </v-list-item-content>

                <v-list-item-action v-if="!hideEdit">
                    <v-btn
                    icon
                    @click="setForm(item.id)">
                        <v-icon>edit</v-icon>
                    </v-btn>
                </v-list-item-action>

                <!-- <v-list-item-action>
                    <v-icon>done</v-icon>
                </v-list-item-action> -->
            </template>
        </v-select>

        <!--  -->
        <bxs-modal
        v-if="formComponent"
        v-model="form.on"
        :title="!form.id ? 'Nuova/o ' + formTitle : 'Modifica ' + formTitle">
            <component
            :is="formComponent"
            ref="form"
            v-bind="formComponentProps"
            :key="form.on"
            :id="form.id"
            hide-header
            hide-footer
            fluid
            class="py-4"
            @submit="submit" />

            <template #actions>
                <v-btn
                color="primary"
                @click="$refs.form.submit()">Salva</v-btn>
            </template>
        </bxs-modal>
    </div>
</template>

<script>
import dot from 'dot-object'

export default {
    name: 'select-resource-field',
    props: {
        value: {
            type: [String, Array],
            required: false,
            default: null
        },
        resource: {
            type: String,
            required: true
        },
        'form-component': {
            type: String,
            required: false,
            default: null
        },
        'form-component-props': {
            type: Object,
            required: false,
            default: null
        },
        'form-title': {
            type: String,
            required: false,
            default: ''
        },
        'item-text': {
            type: String,
            required: false,
            default: 'text'
        },
        'item-value': {
            type: String,
            required: false,
            default: 'id'
        },
        'item-populate': {
            type: [String, Array],
            required: false,
            default: null
        },
        'exclude-ids': {
            type: Array,
            required: false,
            default: () => []
        },
        'items-filters': {
            type: Object,
            required: false,
            default: () => {}
        },
        //
        label: {
            type: String,
            required: false,
            default: null
        },
        placeholder: {
            type: String,
            required: false,
            default: null
        },
        'hide-edit': {
            type: Boolean,
            required: false,
            default: false
        },
        'return-object': {
            type: Boolean,
            required: false,
            default: false
        },
        'change-return-object': {
            type: Boolean,
            required: false,
            default: false
        },
        immediate: {
            type: Boolean,
            required: false,
            default: false
        },
        'single-line': {
            type: Boolean,
            required: false,
            default: false
        },
        required: {
            type: Boolean,
            required: false,
            default: false
        },
        dense: {
            type: Boolean,
            required: false,
            default: false
        },
        outlined: {
            type: Boolean,
            required: false,
            default: false
        },
        filled: {
            type: Boolean,
            required: false,
            default: false
        },
        clearable: {
            type: Boolean,
            required: false,
            default: false
        },
        rules: {
            type: Array,
            required: false,
            default: () => []
        },
        'hide-details': {
            type: Boolean,
            required: false,
            default: true
        },
        solo: {
            type: Boolean,
            required: false,
            default: false
        },
        flat: {
            type: Boolean,
            required: false,
            default: true
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        'hide-create': {
            type: Boolean,
            required: false,
            default: false
        },
        multiple: {
            type: Boolean,
            required: false,
            default: false
        },
        readonly: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data () {
        return {
            dot,
            selected: this.returnObject ? null : this.value,
            items: [],
            keywords: null,
            loading: false,

            form: {
                on: null,
                id: null
            }
        }
    },
    watch: {
        keywords (newVal) {
            console.log('bxs_select_resource watch keywords')

            this.getItems()
        },
        selected () {
            if (!this.readonly) {
                this.$emit('input', this._getValue())
            }
        },
        value (newVal, oldVal) {
            // if (JSON.parse(newVal) !== JSON.parse(oldVal)) {
            //     this.start()
            // }

            // if (!this.returnObject) {
            //     this.selected = newVal
            // }
        }
    },
    mounted () {
        this.$nextTick(this.start)
    },
    methods: {
        async start () {
            let spread = null

            if (this.itemPopulate && this.itemPopulate.length > 0) {
                spread = Array.isArray(this.itemPopulate) ? this.itemPopulate : this.itemPopulate.split(',')
            }

            if (this.immediate) {
                return dot.pick(this.resource, this.$api).list({
                    pagination: false,
                    spread: spread,
                    sort: {
                        [this.itemText]: 1
                    }
                }).then((docs) => {
                    this.items = docs
                })
            }

            if (this.value) {
                if (!this.multiple) {
                    const item = await dot.pick(this.resource, this.$api).doc(this.value, {
                        populate: spread
                    })

                    if (this.returnObject) this.selected = item

                    this.items = [item]
                } else {
                    const items = await dot.pick(this.resource, this.$api).list({
                        pagination: false,
                        spread: spread,
                        sort: { [this.itemText]: 1 },
                        filters: {
                            ...this.itemsFilters,
                            id: '[]|' + this.value.join(',')
                        }
                    })

                    if (this.returnObject) this.selected = items

                    this.items = items
                }
            }
        },
        async getItems () {
            if (!this.keywords || !this.keywords.length) {
                return
            }

            console.log('bxs_select_resource getItems')

            this.items = []
            this.loading = true
            let spread = null
            const filters = {
                keywords: this.keywords
            }

            if (this.excludeIds.length > 0) {
                filters.id = '<>|' + this.excludeIds.join(',')
            }

            if (this.itemPopulate && this.itemPopulate.length > 0) {
                spread = Array.isArray(this.itemPopulate) ? this.itemPopulate : this.itemPopulate.split(',')
            }

            try {
                const docs = await dot.pick(this.resource, this.$api).list({
                    pagination: false,
                    filters: {
                        ...filters,
                        ...this.itemsFilters
                    },
                    spread: spread,
                    sort: {
                        [this.itemText]: 1
                    }
                })

                this.items = docs
            } catch (err) {
                console.log(err)
            } finally {
                this.loading = false
            }
        },
        handleChange () {
            if (!this.readonly) {
                // if (this.changeReturnObject)
                this.$emit('change', this._getValue())
            }
        },
        setForm (id) {
            this.form.id = id || null
            this.form.on = !this.form.on
        },
        _getValue () {
            let v = this.selected

            if (this.returnObject) {
                if (this.multiple) {
                    v = []
                    this.selected.forEach((ite) => {
                        v.push(dot.pick(this.itemValue, ite))
                    })
                }
            }

            return v
        },
        select (item) {
            const item_v = dot.pick(this.itemValue, item)

            if (this.multiple) {
                if (!this.selected) this.selected = []

                if (this.returnObject) {
                    if (!this.selected.some(_i => dot.pick(this.itemValue, _i)) === item_v) {
                       this.selected.push(item)
                    }
                } else {
                    if (!this.selected.includes(item_v)) {
                        this.selected.push(item_v)
                    }
                }
            } else {
                if (this.returnObject) {
                    if (dot.pick(this.itemValue, this.selected) === item_v) {
                        this.selected = item
                    }
                } else {
                    this.selected = item_v
                }
            }

            // push selected in items
            const index = this.items.findIndex(i => dot.pick(this.itemValue, i) === dot.pick(this.itemValue, this.selected))
            if (index !== -1) this.items[index] = this.selected
            else this.items.push(this.selected)

            return this.selected
        },
        async submit (method, model, body, id, undo, undoTo) {
            let spread = null

            if (this.itemPopulate && this.itemPopulate.length > 0) {
                spread = Array.isArray(this.itemPopulate) ? this.itemPopulate : this.itemPopulate.split(',')
            }

            try {
                const doc_id = await dot.pick(this.resource, this.$api).save(id, body)
                const doc = await dot.pick(this.resource, this.$api).doc(doc_id, {
                    populate: spread
                })

                if (method === 'post') this.select(doc)
                this.setForm()
                this.handleChange()
            } catch (err) {
                throw new Error(err.message || err.name)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.select-resource-field {
    // display: block;
    // width: 100%;
    flex: 1 1 auto;
}
</style>

<style lang="scss">
.select-resource-field {
    .v-select__selections {
        input {
            display: none;
        }
    }
}
</style>