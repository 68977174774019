<template>
    <div class="product-details-field mb-6">
        <ul>
            <draggable
            v-model="items"
            :ghost-class="items.length > 1 ? 'draggable-ghost' : null"
            :disabled="items.length <= 1"
            tag="li">
                <v-row
                v-for="(item, i) in items"
                :key="i"
                no-gutters
                :class="{ draggable: items.length > 1, 'mb-3': items.length > 1 && i < items.length - 1 }">
                    <v-col
                    cols="4"
                    class="d-flex flex-nowrap">
                        <v-btn
                        v-if="items.length > 1"
                        icon
                        small
                        plain
                        class="mr-3">
                            <v-icon>drag_indicator</v-icon>
                        </v-btn>

                        <v-text-field
                        v-model="item.label.it"
                        label="Label"
                        hide-details
                        filled
                        dense />
                    </v-col>

                    <v-col
                    cols="4"
                    class="pl-3">
                        <v-text-field
                        v-model="item.value.it"
                        label="Valore"
                        filled
                        hide-details
                        dense />
                    </v-col>

                    <v-col
                    cols="4"
                    class="text-right pl-3">
                        <v-btn
                        icon
                        :disabled="items.length <= 1"
                        class="mr-3"
                        @click="remove(i)">
                            <v-icon>remove</v-icon>
                        </v-btn>

                        <v-btn
                        v-if="i === items.length - 1"
                        icon
                        @click="add">
                            <v-icon>add</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </draggable>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'extended-fields-field',
    props: {
        value: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    data () {
        return {
            items: this.value
        }
    },
    created () {
        if (!this.items.length) {
            this.items = [{ label: { it: null, en: null }, value: { it: null, en: null } }]
        }
    },
    watch: {
        items () {
            this.$emit('input', this.items)
        }
    },
    methods: {
        add () {
            this.items.push({ label: { it: null, en: null }, value: { it: null, en: null } })
            this.$emit('on-add')
        },
        remove (i) {
            this.items.splice(i, 1)
            this.$emit('on-remove')
        }
    }
}
</script>