<template>
    <div class="material-form">
        <formizer
        ref="formizer"
        model="Attribute"
        populate="options"
        undo="/attributs"
        :hide-footer="hideFooter"
        :hide-header="hideHeader"
        :id="id"
        @modified="$emit('modified', $event)"
        @draw-completed="drawCompleted"
        @submit="intSubmit">
            <template #header-toolbar="{ doc, method }">
                <v-btn
                text
                small
                plain
                @click="$router.go(-1)">
                    <v-icon left>chevron_left</v-icon>
                    <span>Indietro</span>
                </v-btn>

                <h1>{{ method === 'post' ? 'Aggiungi attributo' : doc.name }}</h1>
            </template>

            <template #body="{ fields, rules, card }">
                <v-card v-bind="card">
                    <div class="mb-8">
                        <h2>Informazioni base</h2>
                    </div>

                    <div>
                        <v-text-field
                        v-model="fields.name.model"
                        label="* Nome interno"
                        required
                        dense
                        outlined
                        :rules="[rules.required]" />

                        <v-text-field
                        v-model="fields.label.model"
                        label="Label"
                        required
                        dense
                        outlined />
                    </div>
                </v-card>

                <v-card v-bind="card">
                    <div class="d-flex align-center mb-8">
                        <h2>Opzioni</h2>

                        <v-spacer></v-spacer>

                        <v-btn
                        depressed
                        color="info"
                        @click="addItem(null)">
                            <v-icon left>add</v-icon>
                            <span>Aggiungi</span>
                        </v-btn>
                    </div>

                    <v-data-table
                    :headers="headers"
                    :items="items"
                    fixed-header
                    disable-filtering
                    disable-pagination
                    disable-sort
                    item-key="id"
                    :footer-props="{ 'items-per-page-options': [-1] }"
                    hide-default-footer
                    class="table-edit mt-3">
                        <template #item.value="{ item }">
                            <v-text-field
                            v-model="item.value"
                            dense
                            hide-details
                            single-line
                            outlined
                            :rules="[$api.enums.fields.rules.required]" />
                        </template>

                        <template #item.id="{ item, index }">
                            <bxs-dot-menu v-if="items.length > 1">
                                <v-list-item
                                v-if="!item._deleted"
                                @click="removeItem(index)">
                                    <v-list-item-icon>
                                        <v-icon>close</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>Elimina</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-list-item
                                v-else
                                @click="item._deleted = false">
                                    <v-list-item-icon>
                                        <v-icon>refresh</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>Ripristina</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </bxs-dot-menu>
                        </template>
                    </v-data-table>
                </v-card>

                <slot />
            </template>
        </formizer>
    </div>
</template>

<script>
export default {
    name: 'form_attribute',
    props: {
        id: {
            type: String,
            required: false,
            default: null
        },
        'hide-footer': {
            type: Boolean,
            required: false,
            default: false
        },
        'hide-header': {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data () {
        return {
            method: null,
            items: [],
            headers: [
                { text: '* Valore', value: 'value', width: '1%', divider: true },
                { text: '', value: 'id', width: '1%' }
            ]
        }
    },
    watch: {
        items: {
            deep: true,
            handler (newVal) {
                this.$refs.formizer.valorize({
                    options: newVal
                })
            }
        }
    },
    methods: {
        addItem (item) {
            const itemD = {
                value: item ? item.value : null,
                id: item ? item.id : null,
                _deleted: false,
                _is_new: !item,
                _is_editing: false
            }

            this.items.push(itemD)
        },
        removeItem (i) {
            const item = this.items[i]

            if (this.method === 'post' || (item && item._is_new)) {
                this.items.splice(i, 1)
            } else {
                if (this.oldItems.length > 0) item._deleted = true
                else this.items.splice(i, 1)
            }
        },
        //
        drawCompleted (method, fieldObj, doc, next) {
            this.method = method
            this.$refs.formizer.setModified(true)

            if (doc) {
                if (doc.options.length > 0) {
                    this.oldItems = JSON.parse(JSON.stringify(doc.options))
                    doc.options.forEach(this.addItem)
                }
            } else {
                this.addItem()
            }

            next()
        },
        submit () {
            return this.$refs.formizer.submit()
        },
        async intSubmit (method, model, body, id, isUndo, undo) {
            this.$emit('submit', method, model, body, id, isUndo, undo)
        }
    }
}
</script>