<template>
    <v-navigation-drawer
    v-model="$store.state.is_open_menu"
    app
    clipped
    open-on-hover
    width="240">
        <!-- <template #prepend>
            <v-list-item
            style="height: 60px;"
            class="my-0 py-0">
                <v-avatar
                size="30"
                color="grey"
                class="mr-4 white--text">{{ $api.auth.me.name.first[0] }}</v-avatar>

                <v-list-item-content class="truncated">
                    <v-list-item-title class="mb-0">{{ $api.auth.me.name.first }}</v-list-item-title>
                    <v-list-item-subtitle>{{ $api.auth.me.email }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </template>

        <v-divider class="ma-0 pa-0"></v-divider> -->

        <v-list
        dense
        subheader>
            <!-- home -->
            <v-list-item to="/">
                <v-list-item-icon>
                    <v-icon>home</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>Dashboard</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <!-- orders -->
            <v-list-item
            v-if="$api.auth.is_admin"
            to="/orders"
            exact
            color="primary">
                <v-list-item-icon>
                    <v-icon>article</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>Commesse</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <!-- customers -->
            <v-list-item
            v-if="$api.auth.is_admin"
            to="/customers"
            exact
            color="primary">
                <v-list-item-icon>
                    <v-icon>article</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>Clienti</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <!-- agents -->
            <!-- <v-list-item
            to="/agents"
            exact
            color="primary">
                <v-list-item-icon>
                    <v-icon>article</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>Agenti</v-list-item-title>
                </v-list-item-content>
            </v-list-item> -->

            <!-- subs -->
            <v-list-item
            to="/subs"
            exact>
                <v-list-item-icon>
                    <v-icon>storefront</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Iscritti newsletter</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-item
            to="/stores"
            exact>
                <v-list-item-icon>
                    <v-icon>storefront</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Negozi</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <!-- ---------------------------------------------------------------------------------------------------------------------------------------------------------------------
            --------------------------------------------------------------------------------------------------------------------------------------------------------------------- -->
            <v-list-item class="grey--text text--darken-1 mt-3">
                <v-list-item-content>
                    <v-list-item-title>Catalogo</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <!-- products -->
            <v-list-item
            to="/products"
            exact
            color="primary">
                <v-list-item-icon>
                    <v-icon>article</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>Prodotti</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <!-- categories -->
            <v-list-item
            to="/categories"
            exact>
                <v-list-item-icon>
                    <v-icon>category</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>Categorie</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <!-- pages -->
            <v-list-item
            v-if="$api.auth.is_admin"
            to="/pages"
            exact
            color="primary">
                <v-list-item-icon>
                    <v-icon>article</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>Pagine</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-item
            to="/banners"
            exact>
                <v-list-item-icon>
                    <v-icon>storefront</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Banners</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <!-- ---------------------------------------------------------------------------------------------------------------------------------------------------------------------
            --------------------------------------------------------------------------------------------------------------------------------------------------------------------- -->
            <v-list-item class="grey--text text--darken-1 mt-3">
                <v-list-item-content>
                    <v-list-item-title>Serivizi</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <!-- analytics -->
            <!-- <v-list-group
            prepend-icon="bar_chart"
            no-action>
                <template #activator>
                    <v-list-item-content>
                        <v-list-item-title >Analisi</v-list-item-title>
                    </v-list-item-content>
                </template>

                <v-list-item
                to="/analytics"
                exact>
                    <v-list-item-content>
                        <v-list-item-title>Globale</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item
                to="/analytics/orders"
                exact>
                    <v-list-item-content>
                        <v-list-item-title>Report su ordini</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item
                to="/analytics/customers"
                exact>
                    <v-list-item-content>
                        <v-list-item-title>Report sui clienti</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list-group> -->

            <!-- media -->
            <v-list-item
            to="/storage"
            exact>
                <v-list-item-icon>
                    <v-icon>storage</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>Files</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <!--  -->
            <v-list-item
            v-if="$api.auth.is_agency"
            to="/histories"
            exact>
                <v-list-item-icon>
                    <v-icon>calendar_month</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>Logs</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>

        <!-- ---------------------------------------------------------------------------------------------------------------------------------------------------------------------
        --------------------------------------------------------------------------------------------------------------------------------------------------------------------- -->
        <template
        v-if="$api.auth.is_admin"
        #append>
            <v-divider></v-divider>

            <v-list-item
            to="/settings"
            exact>
                <v-list-item-icon>
                    <v-icon>settings</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>Impostazioni</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </template>
    </v-navigation-drawer>
</template>

<script>
export default {
    name: 'sidebar'
}
</script>