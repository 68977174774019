<template>
    <v-row
    class="fill-height"
    align="center"
    justify="center">
        <v-col
        v-if="!hideLabel"
        class="subtitle-1 text-center"
        cols="12">Elaborazione</v-col>

        <v-col :cols="hideLabel ? 12 : 6">
            <v-progress-linear
            indeterminate
            height="6" />
        </v-col>
    </v-row>
</template>
<script>
export default {
    name: 'bxs-loading',
    props: {
        'hide-label': {
            type: Boolean,
            required: false,
            default: false
        }
    }
}
</script>