var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"files"},[_vm._m(0),_c('bxs-data-table',{ref:"table",attrs:{"model-name":"History","resource":"history","id":"table-files-view","headers":[
        { text: 'Data', value: '_created_at' },
        { text: 'User', value: 'user.email' },
        { text: 'Method', value: 'http_method' },
        { text: 'Model', value: 'model' },
        { text: 'Refs', value: 'ref' }
    ]},scopedSlots:_vm._u([{key:"item._created_at",fn:function(ref){
    var item = ref.item;
return [_vm._v(_vm._s(_vm._f("toHumanDate")(item.created_at,'lll')))]}},{key:"item.user.email",fn:function(ref){
    var value = ref.value;
return [_vm._v(_vm._s(value || '--'))]}},{key:"item.http_method",fn:function(ref){
    var value = ref.value;
return [_vm._v(_vm._s(value || '--'))]}},{key:"item.body",fn:function(ref){
    var value = ref.value;
return [_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(JSON.stringify(value)),expression:"JSON.stringify(value)"}]},[_vm._v(_vm._s(value || '--'))])]}},{key:"item.data",fn:function(ref){
    var value = ref.value;
return [_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(JSON.stringify(value)),expression:"JSON.stringify(value)"}]},[_vm._v(_vm._s(value || '--'))])]}}])})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-8"},[_c('h1',[_vm._v("Logs")])])}]

export { render, staticRenderFns }