var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"banners"},[_vm._m(0),_c('v-card',{staticClass:"pa-0"},[_c('bxs-data-table',{ref:"table",attrs:{"model-name":"Banner","resource":"banners","id":"table-banners-view","to-create-item":"/banners/new","to-update-item":"/banners","headers":[
            { text: '', value: 'imgs' },
            { text: 'Nome interno', value: 'name' },
            { text: 'Tipologia', value: 'type' },
            { text: 'Titolo', value: 'title.it' },
            { text: 'Sottotitolo', value: 'subtitle.it' },
            { text: 'Ordine', value: 'order' }
        ]},scopedSlots:_vm._u([{key:"item.order",fn:function(ref){
        var item = ref.item;
        var value = ref.value;
return [_c('qty-field',{attrs:{"value":value,"hide-details":"","single-line":"","dense":"","solo":"","outlined":false},on:{"change":function($event){return _vm.$api.banners.modify(item.id, { order: $event })}}})]}}])})],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-8"},[_c('h1',[_vm._v("Banners")])])}]

export { render, staticRenderFns }