<template>
    <div class="products-view">
        <div class="mb-8">
            <h1>Prodotti</h1>

            <div class="mt-6">
                <v-btn
                v-if="$api.auth.is_super"
                class="mr-2"
                @click="$api.post('v1/products/imports/csv')">Import from CSV</v-btn>

                <v-btn
                small
                color="primary"
                @click="downloadExcell">Scarica tutti gli articoli excel (OS1)</v-btn>
            </div>
        </div>

        <bxs-data-table
        ref="table"
        model-name="Product"
        resource="products"
        id="table-products-view"
        to-create-item="/products/new"
        to-update-item="/products"
        :schema="{
            categories: {
                instance: 'array',
                query_key: 'categories._id',
                items: $api.enums.categories
            },
            collection_year: {
                instance: 'array',
                query_key: 'collection_year',
                items: ['2022/2023', '2023/2024', '2024/2025']
            }
        }"
        :headers="[
            { value: 'imgs' },
            { text: 'Nome', value: 'name.it' },
            { text: 'Collezione', value: 'collection_year' },
            { value: 'sku' },
            { text: 'Categorie', value: 'categories' },
            { text: 'In evidenza', value: 'is_highlighted' },
            { text: 'Visibilità vetrina', value: 'is_visible' },
            { text: 'Visibilità agenti', value: 'is_available' }
        ]"
        :sort-by="['name.it', 'sku']"
        :sort-dir="[false, false]"
        :static-query="{
            spread: ['imgs', 'categories']
        }"
        :static-filters="{
            is_variant: '=|false'
        }">
            <template #toolbar-menu>
                <v-list-item @click="orders.on = true">
                    <v-list-item-icon>
                        <v-icon>sort</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Posiziona prodotti in evidenza</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>

            <!--  -->

            <template #item.size.it="{ value }">{{ value || '--' }}</template>
            <template #item._imgs="{ item }">{{ item.imgs }}</template>

            <template #item.categories="{ value }">{{ value.length > 0 ? value.map(i => i.name.it).join(', ') : '--' }}</template>

            <template #item.is_available="{ item }">
                <v-switch
                v-model="item.is_available"
                color="success"
                inset
                dense
                hide-details
                class="d-inline-block ma-0 pa-0"
                @change="$api.products.modify(item.id, { is_available: $event }).then(() => $refs.table.getItems())" />
            </template>

            <template #item.is_visible="{ item }">
                <v-switch
                v-model="item.is_visible"
                color="success"
                inset
                dense
                hide-details
                class="d-inline-block ma-0 pa-0"
                @change="$api.products.modify(item.id, { is_visible: $event }).then(() => $refs.table.getItems())" />
            </template>

            <template #item.is_highlighted="{ item }">
                <v-switch
                v-model="item.is_highlighted"
                color="success"
                inset
                dense
                hide-details
                class="d-inline-block ma-0 pa-0"
                @change="$api.products.modify(item.id, { is_highlighted: $event }).then(handleChangeIsHighlighted)" />
            </template>

            <template #item-actions="{ item }">
                <v-list-item
                :href="item.permalink.it"
                target="_blank">
                    <v-list-item-icon>
                        <v-icon>open_in_new</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Visualizza in catalogo</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </bxs-data-table>

        <!-- ------------------------------------------------------------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------------------------------------------------------------ -->
        <v-navigation-drawer
        v-model="orders.on"
        app
        right
        clipped
        persistent
        width="440">
            <v-card
            height="calc(100vh - 60px)"
            class="d-flex flex-column pa-0">
                <div class="d-flex align-center pa-4">
                    <h3>Posiziona i prodotti in evidenza</h3>

                    <v-spacer></v-spacer>

                    <v-btn
                    fab
                    depressed
                    x-small
                    color="error"
                    @click="orders.on = false">
                        <v-icon>close</v-icon>
                    </v-btn>
                </div>
                <v-divider></v-divider>

                <div
                class="pa-8"
                style="flex-grow: 1; height: 100%; overflow: auto;">
                    <draggable
                    v-model="orders.items"
                    ghost-class="draggable-ghost"
                    tag="ul"
                    class="no-gutters"
                    @change="handleChangePosHighlightedProducts">
                        <li
                        v-for="item in orders.items"
                        :key="item.id"
                        cols="3"
                        class="mb-1 pl-1 dragging">
                            <v-card
                            elevation="0"
                            color="primary darken-2"
                            dark
                            class="pa-2">
                                <div class="d-flex align-center">
                                    <v-icon
                                    v-if="orders.items.length > 1"
                                    class="mr-1">drag_indicator</v-icon>

                                    <v-avatar size="40">
                                        <v-img
                                        v-if="item.imgs.length > 0"
                                        :src="item.imgs[0].url | toThumb" />

                                        <v-img
                                        v-else
                                        src="/img/no-photo.png" />
                                    </v-avatar>

                                    <div class="ml-2 truncated">
                                        <p class="truncated ma-0">{{ item.name.it }}</p>
                                        <p class="truncated ma-0">{{ item.sku }}</p>
                                    </div>

                                    <v-spacer></v-spacer>

                                    <v-btn
                                    icon
                                    :color="item.is_highlighted ? 'yellow darken-2' : null"
                                    class="ml-6"
                                    @click="$api.products.modify(item.id, { is_highlighted: !item.is_highlighted }).then(handleChangeIsHighlighted)">
                                        <v-icon>star</v-icon>
                                    </v-btn>

                                    <span>{{ item.orders.highlighted }}</span>
                                </div>
                            </v-card>
                        </li>
                    </draggable>
                </div>

                <v-divider></v-divider>
                <v-card-actions class="pa-4">
                    <v-spacer></v-spacer>

                    <v-btn
                    text
                    @click="orders.on = false">Annulla e chiudi</v-btn>

                    <v-btn
                    color="primary"
                    @click="$refs.form.submit()">Salva</v-btn>
                </v-card-actions>
            </v-card>
        </v-navigation-drawer>
    </div>
</template>

<script>
export default {
    name: 'products',
    data () {
        return {
            orders: {
                on: false,
                items: []
            }
        }
    },
    watch: {
        'orders.on' (newVal, oldVal) {
            if (newVal) {
                this.getHighlightedProducts()
            }
        }
    },
    methods: {
        async downloadExcell (ids) {
            this.$store.commit('loading', true)
            let query = `?access_token=${this.$api.auth.access_token}`

            if (ids && ids.length > 0) {
                query += `&ids=${ids.join(',')}`
            }

            try {
                window.open(
                    `${this.$api.enums.origins.api}/v1/products/os1/downloads/excell${query}`,
                    '_blank'
                )
            } catch (err) {
                throw new Error(err.message || err.name)
            } finally {
                this.$store.commit('loading', false)
            }
        },
        handleChangeIsHighlighted () {
            this.$refs.table.getItems()
            this.getHighlightedProducts()
        },
        handleChangePosHighlightedProducts ({ moved }) {
            console.log('products handleChangePosHighlightedProducts()', moved)

            this.$api.products.modify(moved.element.id, {
                'orders.highlighted': moved.newIndex + 1
            }).then(this.handleChangeIsHighlighted)

            // this.$api.products.modify(
            //     'many',
            //     this.orders.items.map((item, i) => {
            //         return {
            //             id: item.id,
            //             'orders.highlighted': i + 1
            //         }
            //     })
            // ).then(this.handleChangeIsHighlighted)
        },
        async getHighlightedProducts () {
            try {
                this.orders.items = await this.$api.products.list({
                    pagination: false,
                    spread: ['imgs'],
                    sort: { 'orders.highlighted': 1 },
                    filters: {
                        is_highlighted: true
                    }
                })
            } catch (err) {
                throw new Error(err.message || err.name)
            }
        }
    }
}
</script>