var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"settings-metaquery"},[_c('div',{staticClass:"mb-8"},[_c('v-btn',{attrs:{"text":"","small":"","plain":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("chevron_left")]),_c('span',[_vm._v("Indietro")])],1),_c('h1',[_vm._v("Utenti")]),_c('v-divider',{staticClass:"my-4"})],1),_c('bxs-data-table',{ref:"table",attrs:{"model-name":"User","resource":"users","id":"table-settings-auths-view","hide-default-delete-item":"","hide-default-duplicate-item":"","headers":[
        { text: '', value: 'img' },
        { text: 'Nome', value: 'name' },
        { text: 'Email', value: 'email' },
        { text: 'Ruolo', value: 'role' },
        { text: 'Stato', value: 'status' },
        { text: 'Blocchi lettura', value: 'scopes.reads' },
        { text: 'Blocchi scrittura', value: 'scopes.writes' }
    ],"static-query":{
        spread: ['img']
    },"static-filters":{
        role: '[]|' + _vm.$api.enums.toArray('auth.roles').join(',')
    }},scopedSlots:_vm._u([{key:"prepend-toolbar",fn:function(){return [_c('bxs-btn',{attrs:{"icon":"","tooltip":"Aggiungi nuovo elemento rapido"},on:{"click":function($event){return _vm.setForm()}}},[_c('v-icon',[_vm._v("post_add")])],1)]},proxy:true},{key:"item.status",fn:function(ref){
    var value = ref.value;
return [_c('v-chip',[_vm._v(_vm._s(value || 'Actived'))])]}},{key:"item-actions",fn:function(ref){
    var item = ref.item;
    var id = ref.id;
return [_c('v-list-item',{on:{"click":function($event){return _vm.setForm(id)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("edit")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Modifica rapida")])],1)],1),(item.status !== 'banned')?_c('v-list-item',{on:{"click":function($event){return _vm.setStatus(item, 'banned')}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("person_off")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Banna")])],1)],1):_c('v-list-item',{on:{"click":function($event){return _vm.setStatus(item, 'actived')}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("person_off")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Attiva")])],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.deleteDoc(item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("delete")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Elimina")])],1)],1)]}}])}),(_vm.$api.auth.is_super)?_c('div',{staticClass:"mt-12"},[_c('div',{staticClass:"mb-8"},[_c('h1',[_vm._v("Sessioni")]),_c('v-divider',{staticClass:"my-4"})],1),(_vm.$api.auth.is_super)?_c('v-card',{staticClass:"pa-0"},[_c('v-data-table',{attrs:{"headers":[
                { text: 'UID', value: 'session.user_id' },
                { text: 'Email', value: 'email' },
                { text: 'Scadenza sessione', value: 'expires' },
                { text: 'Access token', value: 'session.access_token' },
                { text: 'Refresh access token', value: 'session.refresh_access_token' },
                { text: 'Scadenza accesso', value: 'access_token_expire' },
                { text: 'Scadenza refresh accesso', value: 'refresh_access_token_expire', divider: true },
                { text: '', value: 'id', width: '1%' }
            ],"items":_vm.sessions},scopedSlots:_vm._u([{key:"item.session.user_id",fn:function(ref){
            var value = ref.value;
return [_c('router-link',{attrs:{"to":("/users/" + value)}},[_vm._v(_vm._s(value))])]}},{key:"item.email",fn:function(ref){
            var item = ref.item;
return [_vm._v(_vm._s(_vm._f("valueFromJwt")(item.session.access_token,'email')))]}},{key:"item.expires",fn:function(ref){
            var value = ref.value;
return [_vm._v(_vm._s(_vm._f("toHumanDate")(value,'lll')))]}},{key:"item.session.access_token",fn:function(ref){
            var value = ref.value;
return [_vm._v("..."+_vm._s(value.slice(value.length - 5, value.length)))]}},{key:"item.session.refresh_access_token",fn:function(ref){
            var value = ref.value;
return [_vm._v("..."+_vm._s(value.slice(value.length - 5, value.length)))]}},{key:"item.access_token_expire",fn:function(ref){
            var item = ref.item;
return [_vm._v(_vm._s(_vm._f("toExpireDateFromJwt")(item.session.access_token,'exp')))]}},{key:"item.refresh_access_token_expire",fn:function(ref){
            var item = ref.item;
return [_vm._v(_vm._s(_vm._f("toExpireDateFromJwt")(item.session.refresh_access_token,'exp')))]}},{key:"item.id",fn:function(ref){
            var value = ref.value;
return [_c('bxs-dot-menu',[_c('v-list-item',{on:{"click":function($event){_vm.$api.auth.revoke(value).then(_vm.getSessions)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("block")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Revoca accesso")])],1)],1)],1)]}}],null,false,2363953291)})],1):_vm._e()],1):_vm._e(),_c('v-navigation-drawer',{attrs:{"app":"","right":"","clipped":"","persistent":"","width":"400"},model:{value:(_vm.form.on),callback:function ($$v) {_vm.$set(_vm.form, "on", $$v)},expression:"form.on"}},[_c('v-card',{staticClass:"d-flex flex-column pa-0",attrs:{"height":"calc(100vh - 60px)"}},[_c('div',{staticClass:"d-flex align-center pa-4"},[_c('h2',{staticClass:"mb-0"},[_vm._v("Utente")]),_c('v-spacer'),_c('v-btn',{attrs:{"fab":"","depressed":"","x-small":"","color":"error"},on:{"click":function($event){_vm.form.on = false}}},[_c('v-icon',[_vm._v("close")])],1)],1),_c('v-divider'),_c('div',{staticClass:"py-4 px-8",staticStyle:{"flex-grow":"1","height":"100%","overflow":"auto"}},[_c('form-user',{key:_vm.form.on,ref:"form",attrs:{"hide-header":"","hide-footer":"","fluid":"","id":_vm.form.id},on:{"modified":function($event){_vm.form.modified = $event},"submit":_vm.submit}})],1),_c('v-divider'),_c('v-card-actions',{staticClass:"pa-4"},[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.setForm()}}},[_vm._v("Annulla e chiudi")]),_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.form.modified},on:{"click":function($event){return _vm.$refs.form.submit()}}},[_vm._v("Salva")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }