import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import Api from './assets/libs/Api'
import translate from './assets/libs/translate'
import './components'
import './filters'
import vuetify from './plugins/vuetify'

const api = new Api({
    base_url: process.env.VUE_APP_API,
    app_id: 'tfork',
    prefix: 'tfk'
})

Vue.prototype.$api = api
window.$api = api
Vue.prototype.$t = translate
Vue.prototype.$eventHub = new Vue()
Vue.config.productionTip = false

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')