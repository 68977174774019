<template>
    <div class="dep boxed">
        <formizer
        ref="formizer"
        model="Dep"
        :id="$route.params.id"
        :hide-delete="$api.auth.is_admin"
        @draw-completed="drawCompleted"
        @submit="submit">
            <template #header-toolbar="{ fields, doc, method }">
                <v-btn
                text
                small
                plain
                @click="$router.go(-1)">
                    <v-icon left>chevron_left</v-icon>
                    <span>Indietro</span>
                </v-btn>

                <h1 class="mb-0">{{ method === 'post' ? `Aggiungi` : 'Modifica' }} {{ fields.type.model === 'category' ? 'categoria' : 'pagina' }}</h1>
                <h3 v-if="doc && doc.name">{{ doc.name.it }}</h3>
            </template>

            <template #body="{ fields, rules, card, method, id }">
                <v-row>
                    <v-col cols="8">
                        <v-card v-bind="card">
                            <div class="mb-8">
                                <h2>Informazioni base</h2>
                            </div>

                            <div>
                                <v-text-field
                                v-model="fields.name.it.model"
                                :label="fields.type.model === 'category' ? '* Nome' : '* Nome interno'"
                                required
                                dense
                                outlined
                                :rules="[rules.required]" />

                                <!-- :items-filters="{ type: '=|page' }" -->
                                <bxs-select-resouce-field
                                v-if="fields.type.model === 'category'"
                                v-model="fields.parent.model"
                                resource="deps"
                                :items-filters="{ type: `=|${$route.query.type}` }"
                                item-text="name"
                                clearable
                                hide-edit
                                dense
                                outlined
                                :exclude="method === 'put' ? [id] : []"
                                :label="$route.query.type === 'category' ? 'Dep genitore' : 'Pagina genitore'">
                                    <template #item-content="{ item }">
                                        <v-list-item-title>{{ item.name.it }} ({{ item.level }})</v-list-item-title>
                                        <v-list-item-subtitle>{{ item.permalink.it }}</v-list-item-subtitle>
                                    </template>
                                </bxs-select-resouce-field>

                                <v-textarea
                                v-model="fields.short_description.it.model"
                                label="Descrizione breve"
                                dense
                                outlined
                                counter
                                maxlength="175" />
                            </div>
                        </v-card>

                        <v-card v-bind="card">
                            <div class="mb-8">
                                <h3>Contenuto pagina</h3>
                            </div>

                            <v-text-field
                            v-model="fields.title.it.model"
                            label="Titolo"
                            required
                            dense
                            outlined />

                            <v-text-field
                            v-if="$api.auth.is_super"
                            v-model="fields.subtitle.it.model"
                            label="Sottotitolo"
                            required
                            dense
                            outlined />

                            <wysiwyg-field v-model="fields.content.it.model" />
                        </v-card>

                        <v-card v-bind="card">
                            <div class="mb-8">
                                <h3>Informazioni SEO</h3>
                            </div>

                            <seo-field :fields="fields" />
                        </v-card>
                    </v-col>

                    <v-col cols="4">
                        <div class="sticky sticky-spacer-top">
                            <v-card class="mb-4 pa-3">
                                <a
                                :href="fields.permalink.it.model || $api.enums.origins.app"
                                target="_blank"
                                class="ma-0 truncated"
                                style="color: grey; text-decoration: none">{{ fields.permalink.it.model || $api.enums.origins.app }}</a>

                                <p class="ma-0 truncated">
                                    <span>{{ fields.meta.title.it.model || 'Titolo pagina' }}</span>
                                </p>

                                <p class="ma-0 truncated">
                                    <span>{{ fields.meta.description.it.model || 'Descrizione pagina' }}</span>
                                </p>
                            </v-card>

                            <v-card class="mb-4">
                                <div class="mb-4">
                                    <uploader
                                    ref="uploader"
                                    label="Immagine cover"
                                    v-model="fields.cover_img.model" />
                                </div>

                                <div>
                                    <uploader
                                    ref="uploader"
                                    label="Immagine default"
                                    v-model="fields.img.model" />
                                </div>
                            </v-card>

                            <v-card class="mb-4">
                                <div class="mb-4">
                                    <h3>Impostazioni</h3>
                                </div>

                                <div class="d-flex align-center">
                                    <p class="ma-0 pa-0">Disponibile</p>
                                    <v-spacer></v-spacer>
                                    <v-switch
                                    v-model="fields.is_available.model"
                                    inset
                                    dense
                                    hide-details
                                    single-line
                                    class="d-inline ma-0 pa-0" />
                                </div>

                                <div
                                v-if="fields.type.model === 'category'"
                                class="d-flex align-center mt-4">
                                    <p class="ma-0 pa-0">In menù</p>
                                    <v-spacer></v-spacer>
                                    <v-switch
                                    v-model="fields.is_in_menu.model"
                                    inset
                                    dense
                                    hide-details
                                    single-line
                                    class="d-inline ma-0 pa-0" />
                                </div>
                            </v-card>
                        </div>
                    </v-col>
                </v-row>
            </template>
        </formizer>
    </div>
</template>

<script>
export default {
    name: 'dep',
    methods: {
        drawCompleted (method, fields, doc, next) {
            if (this.$route.query.type) {
                this.$refs.formizer.valorize({
                    type: this.$route.query.type
                })
            }

            next()
        },
        async submit(method, model, body, id, is_undo, undo) {
            console.log('Dep submit()', body)
            this.$store.commit('loading', true)

            try {
                const doc_id = await this.$api.deps.save(id, body)

                if (is_undo) {
                    if (undo) return this.$router.push(undo)
                    else return this.$router.go(-1)
                }

                if (method === 'post') {
                    this.$router.push('/deps/' + doc_id)
                    this.$router.go()
                }
            } catch (err) {
                throw new Error(err.message || err.name)
            } finally {
                this.$store.commit('loading', false)
            }
        }
    }
}
</script>