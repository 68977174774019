<template>
    <div class="files">
        <div class="mb-8">
            <h1>Logs</h1>
        </div>

        <bxs-data-table
        ref="table"
        model-name="History"
        resource="history"
        id="table-files-view"
        :headers="[
            { text: 'Data', value: '_created_at' },
            { text: 'User', value: 'user.email' },
            { text: 'Method', value: 'http_method' },
            { text: 'Model', value: 'model' },
            { text: 'Refs', value: 'ref' }
        ]">
            <template #item._created_at="{ item }">{{ item.created_at | toHumanDate('lll') }}</template>

            <template #item.user.email="{ value }">{{ value || '--' }}</template>

            <template #item.http_method="{ value }">{{ value || '--' }}</template>

            <template #item.body="{ value }">
                <span v-tooltip="JSON.stringify(value)">{{ value || '--' }}</span>
            </template>

            <template #item.data="{ value }">
                <span v-tooltip="JSON.stringify(value)">{{ value || '--' }}</span>
            </template>
        </bxs-data-table>
    </div>
</template>

<script>
export default {
    name: 'files',
    data () {
        return {
            // img: ['file_IywhjeXTphlx', 'file_3qttAa2OZuja', 'file_T0eBCnmK55iO']
        }
    }
}
</script>
