import Vue from 'vue'
import Vuex from 'vuex'
import {
    mobile as is_mobile,
    ipad as is_ipad,
    touchDevice as is_touch_device
} from 'is_js'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {},
    state: {
        preloaded: false,
        ready: false,
        loading: false,
        is_open_menu: true,
        is_mobile: is_mobile(),
        is_touch_device: is_touch_device(),
        dialog: {
            show: false,
            title: '',
            subtitle: '',
            text: '',
            btnNextText: 'conferma',
            btnNextColor: 'success',
            btnUndoText: 'annulla',
            btnUndoColor: 'info',
            maxWidth: 440,
            undo: () => { },
            next: () => { }
        }
    },
    mutations: {
        SET_APP_PRELOADED(state, bool) {
            state.preloaded = bool
        },
        loading(state, bool) {
            state.loading = bool
        },
        set_is_open_menu(state, bool) {
            state.is_open_menu = bool || !state.is_open_menu
        },
        SET_APP_DIALOG_CONFIRM(state, obj) {
            if (!('show' in obj)) obj.show = true
            Object.assign(state.dialog, obj)
        },
        CLEAR_APP_DIALOG_CONFIRM(state) {
            state.dialog.show = false

            setTimeout(() => {
                state.dialog = {
                    show: false,
                    title: '',
                    subtitle: '',
                    text: '',
                    btnNextText: 'conferma',
                    btnNextColor: 'success',
                    btnUndoText: 'annulla',
                    btnUndoColor: 'info',
                    maxWidth: 440,
                    undo: () => { },
                    next: () => { }
                }
            }, 500)
        },
        set_app_is_mobile(state, bool) {
            state.is_mobile = bool || is_mobile() || is_ipad()
        },
        set_app_is_touch_device(state, bool) {
            state.is_touch_device = bool || is_touch_device()
        }
    },
    actions: {
    }
})
