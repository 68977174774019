<template>
    <div class="banners">
        <div class="mb-8">
            <h1>Negozi</h1>
        </div>

        <v-card class="pa-0">
            <bxs-data-table
            ref="table"
            model-name="Store"
            resource="stores"
            id="table-stores-view"
            to-create-item="/stores/new"
            to-update-item="/stores"
            :headers="[
                { value: 'img' },
                { text: 'Nome interno', value: 'name' },
                { text: 'Titolo', value: 'title.it' },
                { text: 'Link maps', value: 'gg_map.url' }
            ]" />
        </v-card>
    </div>
</template>

<script>
export default {
    name: 'banners'
}
</script>