import dot from 'dot-object'

export default function (
    resource,
    header = [],
    staticQuery = {},
    staticFilters = {},
    sortBy = ['createdAt'],
    sortDir = [false]
) {
    return {
        computed: {
            tableSort() {
                const v = {}

                this.table.options.sortBy.forEach((key, index) => {
                    v[key] = this.table.options.sortDesc[index] === true ? 1 : -1
                })

                return v
            },
            tableSelectedIds() {
                return this.table.selected.map(item => item.id)
            },
            tableHasSelected() {
                return this.tableSelectedIds.length > 0
            },
            tableSelectedFilters() {
                const value = {}

                for (const key in this.table.collectionFilters) {
                    const obj = this.table.collectionFilters[key]

                    if (obj && obj.selected.length > 0) {
                        value[obj.field] = obj.selected
                    }
                }

                if (!Object.keys(value).length) return null
            },
            tableLocalFilters() {
                return {}
            },
            tableHeader() {
                const v = header.map((i, index) => {
                    // const styckedL = !index || (i.value === 'img' || i.value === 'imgs')
                    const isImg = i.value === 'img' || i.value === 'imgs'

                    return {
                        width: i.width || '200px',
                        divider: i.divider || true,
                        cellClass: 'truncated',
                        filterable: !isImg,
                        sortable: !isImg,
                        ...i
                    }
                })

                v.push({
                    text: '',
                    value: 'id',
                    width: '1%',
                    cellClass: 'cell-id sticky-right',
                    class: 'cell-id sticky-right',
                    sortable: false,
                    filterable: false
                })
                return v
            }
        },
        data() {
            return {
                table: {
                    resource: resource,
                    headers: [],
                    items: [],
                    selected: [],
                    loading: false,
                    options: {
                        itemsPerPage: 80,
                        multiSort: false,
                        mustSort: true, // ordinamento, passerà sempre tra crescente e decrescente
                        page: 1,
                        sortBy: sortBy,
                        sortDesc: sortDir
                    },
                    footerProps: {
                        'items-per-page-options': [20, 40, 80],
                        showFirstLastPage: true
                    },
                    pagination: {
                        total: 0
                    },
                    filters: {
                        keywords: null,
                        ...staticFilters
                    }
                }
            }
        },
        watch: {
            'table.options': {
                // Questo farà sapere a Vue di guardare all'interno dell'array
                deep: true,
                // Si attiva non appena il componente viene creato
                immediate: false,
                handler() {
                    this.getDataTable()
                }
            },
            'table.filters.keywords': {
                // Si attiva non appena il componente viene creato
                immediate: false,
                handler() {
                    this.getDataTable()
                }
            }
        },
        methods: {
            async getDataTable() {
                try {
                    this.table.loading = true

                    // this.table.query
                    const docs = await dot.pick(this.table.resource, this.$api).list({
                        limit: this.table.options.itemsPerPage,
                        page: this.table.options.page,
                        sort: this.tableSort,
                        ...staticQuery,
                        filters: {
                            ...this.table.filters,
                            ...this.tableLocalFilters
                        }
                    })

                    this.table.items = docs.docs || docs
                    this.table.pagination.total = docs.pagination ? docs.pagination.totalDocs : docs.length

                    this.table.items.forEach((item) => {
                        item.__is_edit = false
                    })

                    return docs
                } catch (err) {
                    throw new Error(err.message || err.name)
                } finally {
                    this.table.loading = false
                }
            },
            async deleteDocs(id, ids, title, text) {
                try {
                    this.$store.commit('SET_APP_DIALOG_CONFIRM', {
                        title: title || 'Attenzione!',
                        text: text || 'Vuoi eliminare questa risorsa?',
                        next: async () => {
                            this.$store.commit('loading', true)
                            await dot.pick(this.table.resource, this.$api).remove(id, ids)
                            this.$store.commit('loading', false)
                            this.selected = []
                            this.getDataTable()
                        }
                    })
                } catch (err) {
                    console.error(err)
                    alert(err.message || err.name)
                }
            }
        }
    }
}