import Vue from 'vue'
import moment from 'moment'
import { slugify, parseJwt } from './assets/libs/utils'
import Dinero from 'dinero.js'
import dot from 'dot-object'

moment.locale('it')
Dinero.defaultCurrency = 'EUR'
Dinero.globalLocale = 'it-IT'
Dinero.defaultPrecision = 4

Vue.prototype.$moment = moment

const formatter = new Intl.NumberFormat('it-IT', {
    style: 'currency',
    currency: 'EUR'
    // minimumFractionDigits: 2,
    // maximumFractionDigits: 4,
    // minimumSignificantDigits: 1,
    // maximumSignificantDigits: 4
})

Vue.filter('slugify', v => v ? slugify(v) : '')
Vue.filter('valueFromJwt', (v, k) => v ? dot.pick(k, parseJwt(v)) : v)
Vue.filter('toHumanPaymentMethod', (value) => {
    const api = Vue.prototype.$api
    let v = value

    if (!api || !api.enums || !api.enums.payment_method) return value

    const status = api.enums.payment_method.types.find(i => i.value === value)
    if (status) v = status.text

    return v
})
Vue.filter('toHumanOrderStatus', (value) => {
    const api = Vue.prototype.$api
    let v = value

    if (!api || !api.enums || !api.enums.order) return value

    const status = api.enums.order.status.find(i => i.value === value)
    if (status) v = status.text

    return v
})
Vue.filter('toExpireDateFromJwt', (v) => v ? moment.unix(parseJwt(v).exp).format('lll') : v)
Vue.filter('toHumanDate', (date, type = 'llll') => date ? moment(date).format(type) : '')
Vue.filter('toHumanCalendar', date => date ? moment(date).calendar() : '')
Vue.filter('toHumanPriceN', v => formatter.format(v))
Vue.filter('toHumanPrice', (val) => val && val > 0 ? Dinero({ amount: val }).toFormat() : '0,00 €')
Vue.filter('toHumanPricePDF', (val, suffix = '', fixed = 2) => {
    const c = formatter.format(Number(val).toFixed(fixed)).replace('€', '')
        .replace(',', '*').replace('.', '#')
        .replace('*', ',').replace('#', '.')
    // return `${Number(val).toFixed(fixed).replace('.', ',')} ${suffix}`
    return `${c} ${suffix}`
})
Vue.filter('toHumanHttpMethod', (v) => {
    if (v === 'post') return 'Crea'
    else if (v === 'put' || v === 'patch') return 'Modifica'
    else if (v === 'delete') return 'Elimina'
    return v
})

Vue.filter('toThumb', (url, v = 360) => {
    // http://localhost:5000/uploads/commons/pexels-anni-roenkae-2832382.jpg
    // console.log(url)
    if (!url || !url.length) return '/img/no-photo.png'
    const splitted = url.split('/')
    return url.replace(splitted[splitted.length - 1], `thumbs/${v}/${splitted[splitted.length - 1]}`)
})

Vue.filter('toHumanImage', (arr) => {
    if (!arr) return '/img/no-photo.png'

    const api = Vue.prototype.$api

    arr = Array.isArray(arr) ? arr : [arr]
    const val = []

    arr.forEach((id) => {
        const file = api.enums.files.find(img => img.id === id)
        if (file) val.push(file)
    })

    if (!val.length) return '/img/no-photo.png'

    return val[0].url
})