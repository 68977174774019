<template>
    <div class="deps">
        <div class="mb-6">
            <h1>Pagine</h1>
        </div>

        <bxs-data-table
        ref="table"
        model-name="Dep"
        resource="deps"
        id="table-pages-view"
        :to-create-item="$api.auth.is_super ? '/deps/new?type=page' : null"
        to-update-item="/deps"
        :hide-default-delete-item="$api.auth.is_admin"
        :headers="headers"
        :static-filters="{
            type: '=|page'
        }">
            <template #item.is_available="{ item, value }">
                <v-switch
                :input-value="value"
                inset
                dense
                hide-details
                single-line
                class="d-inline ma-0 pa-0"
                @change="$api.deps.modify(item.id, { is_available: $event })" />
            </template>

            <template #item-actions="{ item }">
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>edit</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Modifica</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item
                :href="item.permalink.it"
                target="_blank">
                    <v-list-item-icon>
                        <v-icon>language</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Preview</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </bxs-data-table>
    </div>
</template>

<script>
export default {
    name: 'pages',
    data () {
        return {
            headers: [
                { text: 'UID', value: 'uid' },
                { text: 'Nome interno', value: 'name.it' },
                { text: 'Path', value: 'path.it' },
                { text: 'Slug', value: 'slug.it' },
                { text: 'Permalink', value: 'permalink.it' },
                { text: 'Meta title', value: 'meta.title.it' },
                { text: 'Meta desc', value: 'meta.description.it' }
            ]
        }
    },
    created () {
        if (this.$api.auth.is_super) {
            this.headers.push({ text: 'Disponibile', value: 'is_available' })
        }
    }
}
</script>