<template>
    <div class="metaquery-form">
        <formizer
        ref="formizer"
        model="MetaQuery"
        :hide-footer="hideFooter"
        :hide-header="hideHeader"
        :id="id"
        @modified="$emit('modified', $event)"
        @draw-completed="drawCompleted"
        @submit="intSubmit">
            <template #header-toolbar="{ doc, method }">
                <v-btn
                text
                small
                plain
                @click="$router.go(-1)">
                    <v-icon left>chevron_left</v-icon>
                    <span>Indietro</span>
                </v-btn>

                <h1>{{ method === 'post' ? 'Aggiungi metarule' : doc.name }}</h1>
            </template>

            <template #body="{ fields, rules, card }">
                <v-card v-bind="card">
                    <v-text-field
                    v-model="fields.name.model"
                    label="* Nome"
                    required
                    outlined
                    dense
                    :rules="[rules.required]" />

                    <v-select
                    v-model="fields.model.model"
                    :items="$api.enums.metaquery.models"
                    label="* Entità"
                    required
                    dense
                    outlined
                    :rules="[rules.required]"
                    @change="handleChangeResource" />
                </v-card>

                <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
                ------------------------------------------------------------------------------------------------------------------------------------------------ -->
                <v-card
                v-bind="card"
                class="px-0">
                    <div>
                        <div style="padding: 0 2rem;">
                            <div class="mb-4">
                                <h2>Refenze entità</h2>
                            </div>

                            <div>
                                <v-checkbox
                                v-model="fields.refs.on.model"
                                hide-details
                                label="Questa query ha referenze definite"></v-checkbox>
                            </div>
                        </div>

                        <v-divider
                        v-if="fields.refs.on.model"
                        class="my-4"></v-divider>
                    </div>

                    <div
                    v-if="fields.refs.on.model"
                    class="mt-4"
                    style="padding: 0 2rem;">
                        <bxs-select-resouce-field
                        v-model="fields.refs.value.model"
                        :resource="items[fields.model.model].endpoint"
                        item-text="name"
                        outlined
                        multiple
                        item-populate="parent"
                        :label="items[fields.model.model].text" />
                    </div>
                </v-card>

                <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
                ------------------------------------------------------------------------------------------------------------------------------------------------ -->
                <v-card v-bind="card">
                    <div class="mb-8">
                        <h2>Match stage</h2>
                    </div>

                    <!-- <v-textarea
                    v-model="fields.match.model"
                    dense
                    outlined
                    label="Match" /> -->

                    <json-editor
                    ref="json_editor"
                    v-model="fields.match.model" />

                    <!-- <querybuilder
                    v-model="fields.match.model"
                    :rules="querybuilder.rules"
                    :maxDepth="2"
                    :labels="querybuilder.labels"
                    class="mb-6">
                    </querybuilder> -->

                    <!-- <div>
                        <v-select
                        v-model="fields.match.field.model"
                        :items="model_fields"
                        label="Campo entità"
                        outlined
                        required
                        dense />

                        <v-select
                        v-model="fields.match.operator.model"
                        :items="$api.enums.metaquery.operators"
                        label="* Operatore"
                        required
                        dense
                        outlined
                        :rules="[rules.required]" />

                        <v-text-field
                        v-model="fields.match.value.model"
                        label="Valore"
                        outlined
                        dense />
                    </div> -->
                </v-card>
            </template>
        </formizer>
    </div>
</template>

<script>
import JsonEditorVue from 'json-editor-vue'

export default {
    name: 'form_metaquery',
    components: {
        'json-editor': JsonEditorVue
    },
    props: {
        id: {
            type: String,
            required: false,
            default: null
        },
        'hide-footer': {
            type: Boolean,
            required: false,
            default: false
        },
        'hide-header': {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data () {
        return {
            items: {},
            model_fields: [],
            //
            querybuilder: {
                rules: [],
                labels: {
                    matchType: 'Match Type',
                    matchTypes: [
                        {
                            id: '$and',
                            label: 'AND'
                        },
                        {
                            id: '$or',
                            label: 'OR'
                        },
                        {
                            id: '$not',
                            label: 'NOT'
                        },
                        {
                            id: '$nor',
                            label: 'NOR'
                        }
                    ],
                    addRule: 'Add Rule',
                    removeRule: '&times;',
                    addGroup: 'Add Group',
                    removeGroup: '&times;',
                    textInputPlaceholder: 'value'
                }
            }
        }
    },
    methods: {
        async drawCompleted (method, fieldObj, doc, next) {
            if (method === 'post') {
                this.$refs.formizer.valorize({
                    model: this.$route.params.model || null
                })

                if (this.$route.params.model) {
                    this.handleChangeResource(this.$route.params.model)
                }
            }

            const items = await this.$api.enum('metaquery.models')
            items.forEach((item) => {
                this.items[item.value] = item
            })

            next()
        },
        handleChangeResource (val) {
            this.$api.getSchema(val).then((paths) => {
                this.model_fields = Object.keys(paths).sort()
            })
        },
        submit () {
            return this.$refs.formizer.submit()
        },
        async intSubmit (method, model, body, id, isUndo, undo) {
            // if (body.match) body.match = JSON.parse(body.match)
            this.$emit('submit', method, model, body, id, isUndo, undo)
        }
    }
}
</script>