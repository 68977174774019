<template>
    <div class="home">
        <section class="mb-12">
            <div class="mb-8">
                <h2>Dashboard</h2>

                <v-btn
                v-if="$api.env === 'development'"
                @click="getStatus">getStatus()</v-btn>
                <!--
                    md  960px > < 1264px
                    lg  1264px > < 1904px
                    xl  4k and ultra-wide > 1904px
                -->
            </div>

            <div>
                <div
                v-if="!status"
                class="text-center pa-6">
                    <loading />
                </div>

                <div
                v-else
                class="mb-12">
                    <v-row>
                        <v-col cols="3">
                            <v-alert
                            border="left"
                            color="warning"
                            class="pt-2 pl-6">
                                <h1>{{ status.orders_analytics.under_evaluation_count }}</h1>

                                <div class="d-flex">
                                    <p>Commese in valutazione</p>

                                    <v-spacer></v-spacer>

                                    <router-link to="/orders">
                                        <span>Visualizza</span>
                                        <v-icon>chevron_right</v-icon>
                                    </router-link>
                                </div>
                            </v-alert>
                        </v-col>

                        <v-col cols="3">
                            <v-alert
                            border="left"
                            color="info"
                            outlined
                            class="pt-2 pl-6">
                                <h1>{{ status.orders_analytics.draft_count }}</h1>
                                <p>Commese in bozza</p>
                            </v-alert>
                        </v-col>

                        <v-col cols="3">
                            <v-alert
                            border="left"
                            color="success"
                            outlined
                            class="pt-2 pl-6">
                                <h1>{{ status.orders_analytics.confirmed_count }}</h1>
                                <p>Commese confermate</p>
                            </v-alert>
                        </v-col>

                        <v-col cols="3">
                            <v-alert
                            border="left"
                            color="error"
                            outlined
                            class="pt-2 pl-6">
                                <h1>{{ status.orders_analytics.canceled_count }}</h1>
                                <p>Commese annullate</p>
                            </v-alert>
                        </v-col>
                    </v-row>

                    <!--  -->
                    <div class="mb-4">
                        <v-btn
                        small
                        color="primary"
                        class="ml-2"
                        @click="downloadExcellOS1">Scarica excel clienti (OS1)</v-btn>
                    </div>

                    <!-- --------------------------------------------------------------------------------------------------------------------------------------------------------
                    -------------------------------------------------------------------------------------------------------------------------------------------------------- -->
                    <v-row v-if="$api.auth.is_super">
                        <v-col cols="4">
                            <v-card>
                                <div class="mb-8">
                                    <p class="mute--text">Vendite totali</p>
                                </div>

                                <v-row>
                                    <v-col>
                                        <h1>{{ status.orders_analytics.total_net | toHumanPriceN }}</h1>
                                    </v-col>

                                    <v-col class="text-right">
                                        <p class="success--text mb-0">
                                            <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="9"
                                            height="9"
                                            viewBox="0 0 9 9"
                                            fill="currentColor"
                                            class="mr-2">
                                                <path d="M9,0L8,6.1L2.8,1L9,0z"></path>
                                                <circle cx="1" cy="8" r="1"></circle>
                                                <rect x="0" y="4.5" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -2.864 4.0858)" width="7.1" height="2"></rect>
                                            </svg>
                                            <span>00,0%</span>
                                        </p>

                                        <small class="mute--text">Compared to</small>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>

                        <v-col cols="4">
                            <v-card>
                                <div class="mb-8">
                                    <p class="mute--text">Valore medio dell'ordine</p>
                                </div>

                                <v-row>
                                    <v-col>
                                        <h1>{{ status.orders_analytics.valore_medio | toHumanPriceN }}</h1>
                                    </v-col>

                                    <v-col class="text-right">
                                        <p class="success--text mb-0">
                                            <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="9"
                                            height="9"
                                            viewBox="0 0 9 9"
                                            fill="currentColor"
                                            class="mr-2">
                                                <path d="M9,0L8,6.1L2.8,1L9,0z"></path>
                                                <circle cx="1" cy="8" r="1"></circle>
                                                <rect x="0" y="4.5" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -2.864 4.0858)" width="7.1" height="2"></rect>
                                            </svg>
                                            <span>00,0%</span>
                                        </p>

                                        <small class="mute--text">Compared to</small>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>

                        <v-col cols="4">
                            <v-card>
                                <div class="mb-8">
                                    <p class="mute--text">Totale commesse</p>
                                </div>

                                <v-row>
                                    <v-col>
                                        <h1>{{ status.orders_analytics.totale | toHumanPriceN }}</h1>
                                    </v-col>

                                    <v-col class="text-right">
                                        <p class="success--text mb-0">
                                            <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="9"
                                            height="9"
                                            viewBox="0 0 9 9"
                                            fill="currentColor"
                                            class="mr-2">
                                                <path d="M9,0L8,6.1L2.8,1L9,0z"></path>
                                                <circle cx="1" cy="8" r="1"></circle>
                                                <rect x="0" y="4.5" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -2.864 4.0858)" width="7.1" height="2"></rect>
                                            </svg>
                                            <span>00,0%</span>
                                        </p>

                                        <small class="mute--text">Compared to</small>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>

                    <!-- --------------------------------------------------------------------------------------------------------------------------------------------------------
                    -------------------------------------------------------------------------------------------------------------------------------------------------------- -->
                    <v-row v-if="$api.auth.is_super">
                        <v-col cols="6">
                            <v-card>
                                <div class="mb-4">
                                    <div class="mb-8">
                                        <p class="mute--text">Commesse recenti</p>
                                    </div>

                                    <v-data-table
                                    :headers="[
                                        { value: 'created_at' },
                                        { value: 'number_id' },
                                        { text: 'Cliente', value: 'customer.business_name' },
                                        { text: 'Stato', value: 'status' },
                                        { text: 'Totale imponibile', value: 'cash.total_net' },
                                        { text: 'Totale lordo', value: 'cash.total_gross' }
                                    ]"
                                    :items="orders">
                                        <template #item.created_at="{ value }">{{ value | toHumanDate('l') }}</template>

                                        <template #item.status="{ value }">{{ value | toHumanOrderStatus }}</template>

                                        <template #item.delivery.gross="{ value }">{{ value | toHumanPriceN }}</template>

                                        <template #item.payment.discount="{ value }">{{ value }}%</template>

                                        <template #item.cash.total_net="{ value }">{{ value | toHumanPriceN }}</template>

                                        <template #item.cash.total_gross="{ value }">{{ value | toHumanPriceN }}</template>
                                    </v-data-table>
                                </div>
                            </v-card>
                        </v-col>

                        <v-col cols="3">
                            <v-card>
                                <div class="mb-8">
                                    <p class="mute--text">Articoli più venduti</p>
                                </div>

                                <!-- <v-sheet
                                color="blue lighten-4"
                                class="py-6 text-center mb-4">
                                    <h1 class="blue--text" style="font-size: 3rem;">150</h1>
                                </v-sheet> -->

                                <div
                                v-if="!status.orders_analytics.selling_products.length"
                                class="text-center">
                                    <small>Gli articoli più venduti verranno visualizzati qui.</small>
                                </div>

                                <div v-else>
                                    <div>
                                        <div class="d-flex align-center justify-space-between mute--text">
                                            <small><b>Articolo</b></small>
                                            <small><b>Quantità</b></small>
                                        </div>
                                        <v-divider class="mt-1"></v-divider>
                                    </div>

                                    <v-virtual-scroll
                                    :items="status.orders_analytics.selling_products"
                                    height="300"
                                    item-height="45">
                                        <template #default="{ item }">
                                            <v-list-item
                                            dense
                                            :key="item.id"
                                            class="px-0">
                                                <v-list-item-content>
                                                    <v-list-item-title>{{ item.name }} - TG {{ item.size }}</v-list-item-title>
                                                    <v-list-item-subtitle>{{ item.sku }}</v-list-item-subtitle>
                                                </v-list-item-content>

                                                <v-list-item-action>
                                                    <small>{{ item.count }}</small>
                                                </v-list-item-action>
                                            </v-list-item>

                                            <v-divider></v-divider>
                                        </template>
                                    </v-virtual-scroll>
                                </div>
                            </v-card>
                        </v-col>

                        <v-col cols="3">
                            <v-card>
                                <div class="mb-8">
                                    <p class="mute--text">Vendite totali per agente</p>
                                </div>

                                <div
                                v-if="!status.orders_analytics.selling_agents.length"
                                class="text-center">
                                    <small>Nessun dato disponibile al momento</small>
                                </div>

                                <div v-else>
                                    <div>
                                        <div class="d-flex align-center justify-space-between mute--text">
                                            <small><b>Agente</b></small>
                                            <small><b>Vendite totali</b></small>
                                        </div>
                                        <v-divider class="mt-1"></v-divider>
                                    </div>

                                    <v-virtual-scroll
                                    :items="status.orders_analytics.selling_agents"
                                    height="300"
                                    item-height="45">
                                        <template #default="{ item }">
                                            <v-list-item
                                            dense
                                            :key="item.id"
                                            class="px-0">
                                                <v-list-item-content>
                                                    <v-list-item-title>{{ item.email }}</v-list-item-title>
                                                </v-list-item-content>

                                                <v-list-item-action>
                                                    <small>{{ item.total_net | toHumanPriceN }}</small>
                                                </v-list-item-action>
                                            </v-list-item>

                                            <v-divider></v-divider>
                                        </template>
                                    </v-virtual-scroll>
                                </div>
                            </v-card>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </section>

        <!-- --------------------------------------------------------------------------------------------------------------------------------------------------------
            --------------------------------------------------------------------------------------------------------------------------------------------------------
            --------------------------------------------------------------------------------------------------------------------------------------------------------
        -------------------------------------------------------------------------------------------------------------------------------------------------------- -->
        <section>
            <div class="mb-8">
                <h3>Posizioni</h3>
            </div>

            <div>
                <v-card class="mb-6">
                    <v-card-title>Slides</v-card-title>
                    <v-card-subtitle>Modifica la posizione per ordinare le slide in home page</v-card-subtitle>

                    <v-card-text class="scrollable scrollable-400">
                        <draggable
                        v-model="slides"
                        ghost-class="draggable-ghost"
                        tag="v-row"
                        class="no-gutters"
                        @change="handleChangeOrderSlides">
                            <v-col
                            v-for="item in slides"
                            :key="item.id"
                            :cols="item.col"
                            class="mb-1 pl-1 dragging">
                                <v-card
                                elevation="0"
                                color="primary darken-2"
                                dark
                                class="py-2 pr-1 pr-3">
                                    <div class="d-flex align-center">
                                        <v-icon
                                        v-if="slides.length > 1"
                                        class="mr-1">drag_indicator</v-icon>

                                        <v-avatar size="40">
                                            <v-img
                                            v-if="item.img"
                                            :src="item.img.url | toThumb" />

                                            <v-img
                                            v-else
                                            src="/img/no-photo.png" />
                                        </v-avatar>

                                        <div class="ml-2 truncated">
                                            <p class="truncated ma-0">{{ item.title.it || '--' }}</p>
                                        </div>

                                        <v-spacer></v-spacer>

                                        <span>{{ item.order }}</span>
                                    </div>
                                </v-card>
                            </v-col>
                        </draggable>
                    </v-card-text>
                </v-card>

                <v-card class="mb-6">
                    <v-card-title>Banners</v-card-title>
                    <v-card-subtitle>Modifica la posizione per ordinare i banner in home page</v-card-subtitle>

                    <v-card-text class="scrollable scrollable-400">
                        <draggable
                        v-model="banners"
                        ghost-class="draggable-ghost"
                        tag="v-row"
                        class="no-gutters"
                        @change="handleChangeOrderBanners">
                            <v-col
                            v-for="item in banners"
                            :key="item.id"
                            :cols="item.col"
                            class="mb-1 pl-1 dragging">
                                <v-card
                                elevation="0"
                                color="primary darken-2"
                                dark
                                class="py-2 pr-1 pr-3">
                                    <div class="d-flex align-center">
                                        <v-icon
                                        v-if="banners.length > 1"
                                        class="mr-1">drag_indicator</v-icon>

                                        <v-avatar size="40">
                                            <v-img
                                            v-if="item.img"
                                            :src="item.img.url | toThumb" />

                                            <v-img
                                            v-else
                                            src="/img/no-photo.png" />
                                        </v-avatar>

                                        <div class="ml-2 truncated">
                                            <p class="truncated ma-0">{{ item.title.it || '--' }}</p>
                                        </div>

                                        <v-spacer></v-spacer>

                                        <span>{{ item.order }}</span>
                                    </div>
                                </v-card>
                            </v-col>
                        </draggable>
                    </v-card-text>
                </v-card>

                <v-card class="mb-6">
                    <v-card-title>Prodotti in evidenza</v-card-title>
                    <v-card-subtitle>Modifica la posizione per ordinare i prodotti in evidenza</v-card-subtitle>

                    <v-card-text class="scrollable scrollable-400">
                        <draggable
                        v-model="highlighted_products"
                        ghost-class="draggable-ghost"
                        tag="v-row"
                        class="no-gutters"
                        @change="handleChangeOrderHighlightedProducts">
                            <v-col
                            v-for="item in highlighted_products"
                            :key="item.id"
                            cols="3"
                            class="mb-1 pl-1 dragging">
                                <v-card
                                elevation="0"
                                color="primary darken-2"
                                dark
                                class="py-2 pr-1 pr-3">
                                    <div class="d-flex align-center">
                                        <v-icon
                                        v-if="highlighted_products.length > 1"
                                        class="mr-1">drag_indicator</v-icon>

                                        <v-avatar size="40">
                                            <v-img
                                            v-if="item.imgs.length > 0"
                                            :src="item.imgs[0].url | toThumb" />

                                            <v-img
                                            v-else
                                            src="/img/no-photo.png" />
                                        </v-avatar>

                                        <div class="ml-2 truncated">
                                            <p class="truncated ma-0">{{ item.name.it }}</p>
                                            <p class="truncated ma-0">{{ item.sku }}</p>
                                        </div>

                                        <v-spacer></v-spacer>

                                        <v-btn
                                        icon
                                        :color="item.is_highlighted ? 'yellow darken-2' : null"
                                        class="ml-6"
                                        @click="$api.products.modify(item.id, { is_highlighted: !item.is_highlighted }).then(getHighlightedProducts)">
                                            <v-icon>star</v-icon>
                                        </v-btn>

                                        <span>{{ item.orders.highlighted }}</span>
                                    </div>
                                </v-card>
                            </v-col>
                        </draggable>
                    </v-card-text>
                </v-card>

                <!--  -->
                <v-card>
                    <v-card-title>Negozi</v-card-title>
                    <v-card-subtitle>Modifica la posizione per ordinare i negozi</v-card-subtitle>

                    <v-card-text class="scrollable scrollable-400">
                        <draggable
                        v-model="stores"
                        ghost-class="draggable-ghost"
                        tag="v-row"
                        class="no-gutters"
                        @change="handleChangeOrderStores">
                            <v-col
                            v-for="item in stores"
                            :key="item.id"
                            cols="3"
                            class="mb-1 pl-1 dragging">
                                <v-card
                                elevation="0"
                                color="primary darken-2"
                                dark
                                class="py-2 pr-1 pr-3">
                                    <div class="d-flex align-center">
                                        <v-icon
                                        v-if="stores.length > 1"
                                        class="mr-1">drag_indicator</v-icon>

                                        <v-avatar size="40">
                                            <v-img
                                            v-if="item.img"
                                            :src="item.img.url | toThumb" />

                                            <v-img
                                            v-else
                                            src="/img/no-photo.png" />
                                        </v-avatar>

                                        <div class="ml-2 truncated">
                                            <p class="truncated ma-0">{{ item.name }}</p>
                                        </div>

                                        <span>{{ item.order }}</span>
                                    </div>
                                </v-card>
                            </v-col>
                        </draggable>
                    </v-card-text>
                </v-card>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'home',
    data () {
        return {
            status: null,
            orders: [],
            //
            highlighted_products: [],
            banners: [],
            slides: [],
            stores: []
        }
    },
    created () {
        this.getStatus()

        this.getHighlightedProducts()
        this.getBanners()
        this.getSlides()
        this.getStores()
    },
    methods: {
        async getStatus () {
            this.status = await this.$api.getStatus()
            this.orders = await this.$api.orders.list({
                pagination: false,
                limit: 5,
                sort: { created_at: -1 }
            })
        },
        async getHighlightedProducts () {
            try {
                this.highlighted_products = await this.$api.products.list({
                    pagination: false,
                    spread: ['imgs'],
                    sort: { 'orders.highlighted': 1 },
                    filters: {
                        is_highlighted: '=|true'
                    }
                })
            } catch (err) {
                throw new Error(err.message || err.name)
            }
        },
        async getBanners () {
            try {
                this.banners = await this.$api.banners.list({
                    pagination: false,
                    spread: ['img'],
                    sort: { order: 1 },
                    filters: {
                        type: '=|banner'
                    }
                })
            } catch (err) {
                throw new Error(err.message || err.name)
            }
        },
        async getSlides () {
            try {
                this.slides = await this.$api.banners.list({
                    pagination: false,
                    spread: ['img'],
                    sort: { order: 1 },
                    filters: {
                        type: '=|slide'
                    }
                })
            } catch (err) {
                throw new Error(err.message || err.name)
            }
        },
        async getStores () {
            try {
                this.stores = await this.$api.stores.list({
                    pagination: false,
                    spread: ['img'],
                    sort: { order: 1 }
                })
            } catch (err) {
                throw new Error(err.message || err.name)
            }
        },
        //
        async downloadExcellOS1 (ids) {
            this.$store.commit('loading', true)
            let query = `?access_token=${this.$api.auth.access_token}`

            if (ids && ids.length > 0) {
                query += `&ids=${ids.join(',')}`
            }

            try {
                window.open(
                    `${this.$api.enums.origins.api}/v1/customers/os1/downloads/excell${query}`,
                    '_blank'
                )
            } catch (err) {
                throw new Error(err.message || err.name)
            } finally {
                this.$store.commit('loading', false)
            }
        },
        //
        handleChangeOrderStores () {
            this.$api.banners.modify(
                'many',
                this.stores.map((item, i) => {
                    return {
                        id: item.id,
                        order: i + 1
                    }
                })
            ).then(this.getSlides)
        },
        handleChangeOrderSlides () {
            this.$api.banners.modify(
                'many',
                this.slides.map((item, i) => {
                    return {
                        id: item.id,
                        order: i + 1
                    }
                })
            ).then(this.getSlides)
        },
        handleChangeOrderHighlightedProducts () {
            this.$api.products.modify(
                'many',
                this.highlighted_products.map((item, i) => {
                    return {
                        id: item.id,
                        'orders.highlighted': i + 1
                    }
                })
            ).then(this.getHighlightedProducts)
        },
        handleChangeOrderBanners () {
            this.$api.banners.modify(
                'many',
                this.banners.map((item, i) => {
                    return {
                        id: item.id,
                        order: i + 1
                    }
                })
            ).then(this.getBanners)
        }
    }
}
</script>
