<template>
    <div class="signin signin-reset">
        <v-row justify="center" align="center">
            <v-col
            cols="12"
            md="6"
            order="1"
            order-md="2">
                <v-card
                flat
                max-width="340"
                class="mx-auto">
                    <div class="mb-4">
                        <v-img
                        src="/img/logo.png"
                        max-width="80"
                        class="mb-4" />

                        <h3 v-if="!is_verified">Inserisci il codice di verifica che ti è stato inviato al tuo indirizzo e-mail</h3>
                        <h3 v-else>Crea una nuova password</h3>
                    </div>

                    <v-card-text class="ma-0 pa-0">
                        <v-otp-input
                        v-if="!is_verified"
                        v-model="code"
                        :disabled="otp_loading"
                        label="Codice auth"
                        length="5"
                        class="mb-2"
                        @finish="handleFinishOTP"></v-otp-input>

                        <v-text-field
                        v-else
                        v-model="password"
                        :disabled="loading"
                        label="Password"
                        dense
                        outlined
                        hide-details
                        :append-icon="showPass ? 'visibility_off' : 'visibility'"
                        :type="showPass ? 'text' : 'password'"
                        class="mb-4"
                        @click:append="showPass = !showPass" />

                        <v-alert
                        v-if="error"
                        border="left"
                        text
                        dense
                        type="error"
                        class="ma-0 my-4">
                            <small>{{ error }}</small>
                        </v-alert>
                    </v-card-text>

                    <v-card-actions class="ma-0 pa-0 mt-4">
                        <v-btn
                        v-if="is_verified"
                        :disabled="!password && !code"
                        block
                        color="primary"
                        :loading="loading"
                        @click="recovery">Invia</v-btn>
                    </v-card-actions>

                    <div class="mt-8">
                        <div>
                            <v-btn
                            text
                            x-small
                            exact
                            to="/signin"
                            class="mr-4">Accedi</v-btn>
                        </div>

                        <div>
                            <v-btn
                            text
                            x-small
                            exact
                            to="/signin/recovery">Non hai ricevuto il codice? Recupera</v-btn>
                        </div>
                    </div>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: 'signin_reset',
    data () {
        return {
            code: null,
            password: null,
            error: null,
            loading: false,
            showPass: false,
            otp_loading: false,
            is_verified: false
        }
    },
    created () {
        if (sessionStorage.getItem('last_pwd_recovery_otp')) {
            this.code = sessionStorage.getItem('last_pwd_recovery_otp')
            this.handleFinishOTP()
        }
    },
    methods: {
        async recovery () {
            this.loading = true
            this.error = null

            try {
                await this.$api.auth.passwordReset(this.code, this.password)
                this.$router.push('/signin')
                this.$toast.success('Password aggiornata con successo')
            } catch (err) {
                this.error = err.message || err.name || err
                throw new Error(this.error)
            } finally {
                this.loading = false
            }
        },
        async handleFinishOTP (code) {
            this.otp_loading = true
            this.error = null

            try {
                await this.$api.auth.verifyPasswordRecovery(code || this.code)
                this.is_verified = true
                sessionStorage.setItem('last_pwd_recovery_otp', code || this.code)
            } catch (err) {
                if (err === 'auth.recovery_password.verify') {
                    this.error = 'Inserisci il codice valido'
                } else {
                    this.error = err.message || err.name || err
                    throw new Error(this.error)
                }
            } finally {
                this.otp_loading = false
            }
        }
    },
    beforeDestroy () {
        sessionStorage.removeItem('last_pwd_recovery_otp')
    }
}
</script>

<style lang="scss">
.container-signin {
    margin: 0 !important;
    padding: 0 !important;
    max-width: 100% !important;
}
</style>

<style lang="scss" scoped>
.signin {
    padding: 0 !important;
    overflow: hidden;

    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (min-width: 992px) {
        height: 100vh;
    }

    h1 {
        font-size: 40px;
    }

    .row {
        width: 100%;
        position: relative !important;
        margin: 0 !important;
        padding: 0 !important;
        min-height: 100% !important;
        height: 100% !important;
    }

    &--col-l {
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 40px 0 !important;
        margin: 0 !important;
    }

    &--col-r {
        padding: 40px 0 !important;
        margin: 0 !important;
    }
}

.signin--figure {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    -webkit-clip-path: polygon(0 0,28% 0,100% 100%,0 100%);
    clip-path: polygon(0 0,28% 0,100% 100%,0 100%);
    background-color: var(--v-primary-base);
    z-index: 0;
    background-repeat: repeat;
}
</style>